import React, {Suspense, useState, useEffect } from 'react';

export default function Area(props){
    const [trigger, setTrigger] = useState(false);
    const area = [{ value: '北海道・東北地方', label: '北海道・東北地方', trigger: 'hokkaidoTohokuMessage' },
    { value: '関東地方', label: '関東地方', trigger: 'kantoMessage' },
    { value: '中部地方', label: '中部地方', trigger: 'chubuMessage' },
    { value: '近畿地方', label: '近畿地方', trigger: 'kinki' },
    { value: '中国・四国地方', label: '中国・四国地方', trigger: 'chugokuShikoku' },
    { value: '九州地方', label: '九州地方', trigger: 'kyushu' }];

    const triggerNext = (event) =>{ 
        props.step.value = event.target.value;
        setTrigger(true, props.triggerNextStep({trigger: 'todofuken0Message'}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }
    return(<>
        {/* <form onSubmit={handleSubmit}> */}
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper areaSelect'>
           <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
           {area?.map( e =>{return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element area-button' value={e.value}>{e.label}</button></li></>})}
                </ul></div>
                {/* </form> */}
                </>

    );
};