import React, {Suspense, useState, useEffect } from 'react';
import SetCities from './setCity';
import SetTowns from './setTowns';

export default function CitySelect2(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [prefecture, setPrefecture] = useState();
  const [cityValue, setCityValue] = useState();
  const [townSetValue, setTownSetValue] = useState();
  const [townValue, setTownValue] = useState();
  const [streetNumber, setStreetNumber] = useState();
  const [propertyName, setPropertyName] = useState();
  const [goshitsu, setGoshitsu] = useState();
  const [goshitsuFlg, setGoshitsuFlg] = useState(false);
  const [propertyNameFlg, setPropertyNameFlg] = useState(false);
  const prefectures = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];
  const { dataLayer } = window;
  const handleChange = (event) => {
    switch(event.target.name){
      case 'prefecture':
        setPrefecture(event.target.value);
      break;
      case 'cityValue':
        setCityValue(event.target.value);
        setTownSetValue(event.target.value);
        break;
      case 'townValue':
        setTownValue(event.target.value);
          break;
      default:
        break
    }
  };
  const townHandleChange = (event) => {
    setTownValue(event.target.value);
  };
  const triggerNext = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'genkyo'}));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if(prefecture){setErrorMsg(''); }else{
      setErrorMsg('※都道府県が未入力です');
      return false;
    };
    if(cityValue){setErrorMsg(''); }else{
      setErrorMsg('※市区町村が未入力です');
      return false;
    };
    if(townValue){setErrorMsg(''); }else{
      setErrorMsg('※町域が未入力です');
      return false;
    };
    if(streetNumber){setErrorMsg(''); }else{
      setErrorMsg('※住所番地が未入力です');
      return false;
    };
    var address = {bukken_address1: prefecture, bukken_address2: cityValue, bukken_address3: townValue, bukken_address4:streetNumber};
    if(propertyName){setErrorMsg(''); address['bukken_name'] = propertyName;}else if(propertyNameFlg && !propertyName){ 
      setErrorMsg('※物件名が未入力です');
      return false;}
    if(goshitsu){setErrorMsg(''); address['bukken_address5'] = goshitsu;}else if(goshitsuFlg && !goshitsu){ 
      setErrorMsg('※号室が未入力です');
      return false;}
    props.step.value = address;
    triggerNext();
  };
  const inputChange = (event) =>{
    switch(event.target.name){
      case 'streetNumber':
        setStreetNumber(event.target.value);
        dataLayer.push({'event': 'streetNumberV'});
      break;
      case 'propertyName':
        setPropertyName(event.target.value);
        dataLayer.push({'event': 'propertyNameV'});
      break;
      case 'goshitsu':
        setGoshitsu(event.target.value);
        dataLayer.push({'event': 'goshitsuV'});
      break;
      default:
        break;
    }
  }
  useEffect(() => {
    if(props.steps.todofuken0.value){
      setPrefecture(props.steps.todofuken0.value);
    }
  },[props.steps.todofuken0.value]);
  useEffect(()=>{
    if(props.steps.bukkenType.value ==='マンション'){
      
    }else{
      
    }
    var type;
    (props.steps.bukkenType.value == 'その他')?type = props.steps.other.value : type = props.steps.bukkenType.value;
    switch(type)
    {
      case 'マンション':
        setGoshitsuFlg(true);
        setPropertyNameFlg(true);
        break;
      case '戸建て':
      case '土地':
      case '農地':
      case 'その他':
        setGoshitsuFlg(false);
        setPropertyNameFlg(false);
        break;
      case '一棟アパート・一棟マンション':
      case '一棟ビル':
      case '区分所有ビル':
      case '店舗・工場・倉庫':
        setGoshitsuFlg(false);
        setPropertyNameFlg(true);
        break;
      default:
        break;

    } 
  },[props.steps.bukkenType]);
    return (
    <>
    <form onSubmit={handleSubmit} className="formCss1 citySelect back-white-wrapper" id="citySelect">
    {/* <label>都道府県
        <select value={prefecture} onChange={handleChange} name="prefecture" defaultValue="" id="todofuken">
        <option value='' disabled>都道府県を選択して下さい</option>
          {prefectures?.map( d =>{return <><option value={d}>{d}</option></>})}
        </select>
        </label><br></br> */}
      <label>市区町村：
      <select value={cityValue} onChange={handleChange} defaultValue="" name='cityValue' className="city-select">
      <option value='' disabled>市区町村を選択して下さい</option>
      <Suspense fallback={<p>Loading..</p>}>
      <SetCities property={prefecture}></SetCities>
      </Suspense>
      </select>
        </label><br></br>
        <label>
        町域：
      <select value={townValue} onChange={handleChange} defaultValue="" name='townValue' className="town-select">
      <option value='' disabled>町域を選択して下さい</option>
      <Suspense fallback={<p>Loading..</p>}>
      <SetTowns prefecture={prefecture} townProperty={townSetValue}></SetTowns>
      </Suspense>
      </select>
        </label><br></br>
        <label>住所番地：<input type="text" name="streetNumber" value={streetNumber} onChange={inputChange} /></label><br></br>
      {propertyNameFlg && <><label>物件名：<input type="text" name="propertyName" value={propertyName} onChange={inputChange}/></label><br></br></>}
      {goshitsuFlg && <><label>号室： <input type="text" name="goshitsu" value={goshitsu} onChange={inputChange} formNoValidate/></label><br></br></>}
      <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success" formNoVlidate>
        次へ
            </button>
            <br></br>
      <p className="color-grey"><span>　住所情報出典:「位置参照情報」(国土交通省)の加工情報・「HeartRails Geo API」(HeartRails Inc.)</span></p>
      </form>
      </>
    );
}