import React, { Component, Suspense, useState, useEffect } from 'react';
//import background from "../assets/img/cityview.jpeg";

export default function PropertyData2(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [nobeyukaMenseki, setNobeyukamenseki] = useState(false);
  const [tochimenseki, setTochimenseki] = useState(false);
  const [roomSize, setRoomSize] = useState('');
  const [roomSize2, setRoomSize2] = useState();
  const [tochiSize, setTochiSize] = useState();
  const [tochiSize2, setTochiSize2] = useState();
  const [floorFlg, setFloorFlg] = useState(false);
  const [landFlg, setLandFlg] = useState(false);
  const [type, setType] = useState();
  const [nobeyukaMensekiFlg, setNobeyukaMenssekiFlg] = useState();
  const [tochiMensekiFlg, setTochiMenssekiFlg] = useState();

  const oneToHundred = [{ value: '~10㎡', label: '10㎡未満' },
  { value: '10㎡〜', label: '10㎡〜' },
  { value: '20㎡〜', label: '20㎡〜' },
  { value: '30㎡〜', label: '30㎡〜' },
  { value: '40㎡〜', label: '40㎡〜' },
  { value: '50㎡〜', label: '50㎡〜' },
  { value: '60㎡〜', label: '60㎡〜' },
  { value: '70㎡〜', label: '70㎡〜' },
  { value: '80㎡〜', label: '80㎡〜' },
  { value: '90㎡〜', label: '90㎡〜' },
  { value: '100㎡以上', label: '100㎡以上' }];
  const tenToTwenty = [{ value: '10㎡', label: '10㎡' },
  { value: '11㎡', label: '11㎡' },
  { value: '12㎡', label: '12㎡' },
  { value: '13㎡', label: '13㎡' },
  { value: '14㎡', label: '14㎡' },
  { value: '15㎡', label: '15㎡' },
  { value: '16㎡', label: '16㎡' },
  { value: '17㎡', label: '17㎡' },
  { value: '18㎡', label: '18㎡' },
  { value: '19㎡', label: '19㎡' }];
  const twentyToThirty = [{ value: '20㎡', label: '20㎡' },
  { value: '21㎡', label: '21㎡' },
  { value: '22㎡', label: '22㎡' },
  { value: '23㎡', label: '23㎡' },
  { value: '24㎡', label: '24㎡' },
  { value: '25㎡', label: '25㎡' },
  { value: '26㎡', label: '26㎡' },
  { value: '27㎡', label: '27㎡' },
  { value: '28㎡', label: '28㎡' },
  { value: '29㎡', label: '29㎡' }];
  const thirtyToForty = [{ value: '30㎡', label: '30㎡' },
  { value: '31㎡', label: '31㎡' },
  { value: '32㎡', label: '32㎡' },
  { value: '33㎡', label: '33㎡' },
  { value: '34㎡', label: '34㎡' },
  { value: '35㎡', label: '35㎡' },
  { value: '36㎡', label: '36㎡' },
  { value: '37㎡', label: '37㎡' },
  { value: '38㎡', label: '38㎡' },
  { value: '39㎡', label: '39㎡' }];
  const fortyToFifty = [{ value: '40㎡', label: '40㎡' },
  { value: '41㎡', label: '41㎡' },
  { value: '42㎡', label: '42㎡' },
  { value: '43㎡', label: '43㎡' },
  { value: '44㎡', label: '44㎡' },
  { value: '45㎡', label: '45㎡' },
  { value: '46㎡', label: '46㎡' },
  { value: '47㎡', label: '47㎡' },
  { value: '48㎡', label: '48㎡' },
  { value: '49㎡', label: '49㎡' }];
  const fiftyToSixty = [{ value: '50㎡', label: '50㎡' },
  { value: '51㎡', label: '51㎡' },
  { value: '52㎡', label: '52㎡' },
  { value: '53㎡', label: '53㎡' },
  { value: '54㎡', label: '54㎡' },
  { value: '55㎡', label: '55㎡' },
  { value: '56㎡', label: '56㎡' },
  { value: '57㎡', label: '57㎡' },
  { value: '58㎡', label: '58㎡' },
  { value: '59㎡', label: '59㎡' }];
  const sixtyToSeventy = [{ value: '60㎡', label: '60㎡' },
  { value: '61㎡', label: '61㎡' },
  { value: '62㎡', label: '62㎡' },
  { value: '63㎡', label: '63㎡' },
  { value: '64㎡', label: '64㎡' },
  { value: '65㎡', label: '65㎡' },
  { value: '66㎡', label: '66㎡' },
  { value: '67㎡', label: '67㎡' },
  { value: '68㎡', label: '68㎡' },
  { value: '69㎡', label: '69㎡' }];
  const seventyToEighty = [{ value: '70㎡', label: '70㎡' },
  { value: '71㎡', label: '71㎡' },
  { value: '72㎡', label: '72㎡' },
  { value: '73㎡', label: '73㎡' },
  { value: '74㎡', label: '74㎡' },
  { value: '75㎡', label: '75㎡' },
  { value: '76㎡', label: '76㎡' },
  { value: '77㎡', label: '77㎡' },
  { value: '78㎡', label: '78㎡' },
  { value: '79㎡', label: '79㎡' }];
  const eightyToNinty = [{ value: '80㎡', label: '80㎡' },
  { value: '81㎡', label: '81㎡' },
  { value: '82㎡', label: '82㎡' },
  { value: '83㎡', label: '83㎡' },
  { value: '84㎡', label: '84㎡' },
  { value: '85㎡', label: '85㎡' },
  { value: '86㎡', label: '86㎡' },
  { value: '87㎡', label: '87㎡' },
  { value: '88㎡', label: '88㎡' },
  { value: '89㎡', label: '89㎡' }];
  const nintyToHundred = [{ value: '90㎡', label: '90㎡' },
  { value: '91㎡', label: '91㎡' },
  { value: '92㎡', label: '92㎡' },
  { value: '93㎡', label: '93㎡' },
  { value: '94㎡', label: '94㎡' },
  { value: '95㎡', label: '95㎡' },
  { value: '96㎡', label: '96㎡' },
  { value: '97㎡', label: '97㎡' },
  { value: '98㎡', label: '98㎡' },
  { value: '99㎡', label: '99㎡' }];
  const hundredToThousand = [
    { value: '100㎡〜', label: '100㎡〜' },
    { value: '200㎡〜', label: '200㎡〜' },
    { value: '300㎡〜', label: '300㎡〜' },
    { value: '400㎡〜', label: '400㎡〜' },
    { value: '500㎡〜', label: '500㎡〜' },
    { value: '600㎡〜', label: '600㎡〜' },
    { value: '700㎡〜', label: '700㎡〜' },
    { value: '800㎡〜', label: '800㎡〜' },
    { value: '900㎡〜', label: '900㎡〜' },
    { value: '1000㎡以上', label: '1000㎡以上' }];
  const hundred = [{ value: '100㎡', label: '100㎡' },
  { value: '101㎡', label: '101㎡' },
  { value: '102㎡', label: '102㎡' },
  { value: '103㎡', label: '103㎡' },
  { value: '104㎡', label: '104㎡' },
  { value: '105㎡', label: '105㎡' },
  { value: '106㎡', label: '106㎡' },
  { value: '107㎡', label: '107㎡' },
  { value: '108㎡', label: '108㎡' },
  { value: '109㎡', label: '109㎡' },
  { value: '110㎡', label: '110㎡' },
  { value: '111㎡', label: '111㎡' },
  { value: '112㎡', label: '112㎡' },
  { value: '113㎡', label: '113㎡' },
  { value: '114㎡', label: '114㎡' },
  { value: '115㎡', label: '115㎡' },
  { value: '116㎡', label: '116㎡' },
  { value: '117㎡', label: '117㎡' },
  { value: '118㎡', label: '118㎡' },
  { value: '119㎡', label: '119㎡' },
  { value: '120㎡', label: '120㎡' },
  { value: '121㎡', label: '121㎡' },
  { value: '122㎡', label: '122㎡' },
  { value: '123㎡', label: '123㎡' },
  { value: '124㎡', label: '124㎡' },
  { value: '125㎡', label: '125㎡' },
  { value: '126㎡', label: '126㎡' },
  { value: '127㎡', label: '127㎡' },
  { value: '128㎡', label: '128㎡' },
  { value: '129㎡', label: '129㎡' },
  { value: '130㎡', label: '130㎡' },
  { value: '131㎡', label: '131㎡' },
  { value: '132㎡', label: '132㎡' },
  { value: '133㎡', label: '133㎡' },
  { value: '134㎡', label: '134㎡' },
  { value: '135㎡', label: '135㎡' },
  { value: '136㎡', label: '136㎡' },
  { value: '137㎡', label: '137㎡' },
  { value: '138㎡', label: '138㎡' },
  { value: '139㎡', label: '139㎡' },
  { value: '140㎡', label: '140㎡' },
  { value: '141㎡', label: '141㎡' },
  { value: '142㎡', label: '142㎡' },
  { value: '143㎡', label: '143㎡' },
  { value: '144㎡', label: '144㎡' },
  { value: '145㎡', label: '145㎡' },
  { value: '146㎡', label: '146㎡' },
  { value: '147㎡', label: '147㎡' },
  { value: '148㎡', label: '148㎡' },
  { value: '149㎡', label: '149㎡' },
  { value: '150㎡', label: '150㎡' },
  { value: '151㎡', label: '151㎡' },
  { value: '152㎡', label: '152㎡' },
  { value: '153㎡', label: '153㎡' },
  { value: '154㎡', label: '154㎡' },
  { value: '155㎡', label: '155㎡' },
  { value: '156㎡', label: '156㎡' },
  { value: '157㎡', label: '157㎡' },
  { value: '158㎡', label: '158㎡' },
  { value: '159㎡', label: '159㎡' },
  { value: '160㎡', label: '160㎡' },
  { value: '161㎡', label: '161㎡' },
  { value: '162㎡', label: '162㎡' },
  { value: '163㎡', label: '163㎡' },
  { value: '164㎡', label: '164㎡' },
  { value: '165㎡', label: '165㎡' },
  { value: '166㎡', label: '166㎡' },
  { value: '167㎡', label: '167㎡' },
  { value: '168㎡', label: '168㎡' },
  { value: '169㎡', label: '169㎡' },
  { value: '170㎡', label: '170㎡' },
  { value: '171㎡', label: '171㎡' },
  { value: '172㎡', label: '172㎡' },
  { value: '173㎡', label: '173㎡' },
  { value: '174㎡', label: '174㎡' },
  { value: '175㎡', label: '175㎡' },
  { value: '176㎡', label: '176㎡' },
  { value: '177㎡', label: '177㎡' },
  { value: '178㎡', label: '178㎡' },
  { value: '179㎡', label: '179㎡' },
  { value: '180㎡', label: '180㎡' },
  { value: '181㎡', label: '181㎡' },
  { value: '182㎡', label: '182㎡' },
  { value: '183㎡', label: '183㎡' },
  { value: '184㎡', label: '184㎡' },
  { value: '185㎡', label: '185㎡' },
  { value: '186㎡', label: '186㎡' },
  { value: '187㎡', label: '187㎡' },
  { value: '188㎡', label: '188㎡' },
  { value: '189㎡', label: '189㎡' },
  { value: '190㎡', label: '190㎡' },
  { value: '191㎡', label: '191㎡' },
  { value: '192㎡', label: '192㎡' },
  { value: '193㎡', label: '193㎡' },
  { value: '194㎡', label: '194㎡' },
  { value: '195㎡', label: '195㎡' },
  { value: '196㎡', label: '196㎡' },
  { value: '197㎡', label: '197㎡' },
  { value: '198㎡', label: '198㎡' },
  { value: '199㎡', label: '199㎡' }];
  const twohundred = [{ value: '200㎡', label: '200㎡' },
  { value: '201㎡', label: '201㎡' },
  { value: '202㎡', label: '202㎡' },
  { value: '203㎡', label: '203㎡' },
  { value: '204㎡', label: '204㎡' },
  { value: '205㎡', label: '205㎡' },
  { value: '206㎡', label: '206㎡' },
  { value: '207㎡', label: '207㎡' },
  { value: '208㎡', label: '208㎡' },
  { value: '209㎡', label: '209㎡' },
  { value: '210㎡', label: '210㎡' },
  { value: '211㎡', label: '211㎡' },
  { value: '212㎡', label: '212㎡' },
  { value: '213㎡', label: '213㎡' },
  { value: '214㎡', label: '214㎡' },
  { value: '215㎡', label: '215㎡' },
  { value: '216㎡', label: '216㎡' },
  { value: '217㎡', label: '217㎡' },
  { value: '218㎡', label: '218㎡' },
  { value: '219㎡', label: '219㎡' },
  { value: '220㎡', label: '220㎡' },
  { value: '221㎡', label: '221㎡' },
  { value: '222㎡', label: '222㎡' },
  { value: '223㎡', label: '223㎡' },
  { value: '224㎡', label: '224㎡' },
  { value: '225㎡', label: '225㎡' },
  { value: '226㎡', label: '226㎡' },
  { value: '227㎡', label: '227㎡' },
  { value: '228㎡', label: '228㎡' },
  { value: '229㎡', label: '229㎡' },
  { value: '230㎡', label: '230㎡' },
  { value: '231㎡', label: '231㎡' },
  { value: '232㎡', label: '232㎡' },
  { value: '233㎡', label: '233㎡' },
  { value: '234㎡', label: '234㎡' },
  { value: '235㎡', label: '235㎡' },
  { value: '236㎡', label: '236㎡' },
  { value: '237㎡', label: '237㎡' },
  { value: '238㎡', label: '238㎡' },
  { value: '239㎡', label: '239㎡' },
  { value: '240㎡', label: '240㎡' },
  { value: '241㎡', label: '241㎡' },
  { value: '242㎡', label: '242㎡' },
  { value: '243㎡', label: '243㎡' },
  { value: '244㎡', label: '244㎡' },
  { value: '245㎡', label: '245㎡' },
  { value: '246㎡', label: '246㎡' },
  { value: '247㎡', label: '247㎡' },
  { value: '248㎡', label: '248㎡' },
  { value: '249㎡', label: '249㎡' },
  { value: '250㎡', label: '250㎡' },
  { value: '251㎡', label: '251㎡' },
  { value: '252㎡', label: '252㎡' },
  { value: '253㎡', label: '253㎡' },
  { value: '254㎡', label: '254㎡' },
  { value: '255㎡', label: '255㎡' },
  { value: '256㎡', label: '256㎡' },
  { value: '257㎡', label: '257㎡' },
  { value: '258㎡', label: '258㎡' },
  { value: '259㎡', label: '259㎡' },
  { value: '260㎡', label: '260㎡' },
  { value: '261㎡', label: '261㎡' },
  { value: '262㎡', label: '262㎡' },
  { value: '263㎡', label: '263㎡' },
  { value: '264㎡', label: '264㎡' },
  { value: '265㎡', label: '265㎡' },
  { value: '266㎡', label: '266㎡' },
  { value: '267㎡', label: '267㎡' },
  { value: '268㎡', label: '268㎡' },
  { value: '269㎡', label: '269㎡' },
  { value: '270㎡', label: '270㎡' },
  { value: '271㎡', label: '271㎡' },
  { value: '272㎡', label: '272㎡' },
  { value: '273㎡', label: '273㎡' },
  { value: '274㎡', label: '274㎡' },
  { value: '275㎡', label: '275㎡' },
  { value: '276㎡', label: '276㎡' },
  { value: '277㎡', label: '277㎡' },
  { value: '278㎡', label: '278㎡' },
  { value: '279㎡', label: '279㎡' },
  { value: '280㎡', label: '280㎡' },
  { value: '281㎡', label: '281㎡' },
  { value: '282㎡', label: '282㎡' },
  { value: '283㎡', label: '283㎡' },
  { value: '284㎡', label: '284㎡' },
  { value: '285㎡', label: '285㎡' },
  { value: '286㎡', label: '286㎡' },
  { value: '287㎡', label: '287㎡' },
  { value: '288㎡', label: '288㎡' },
  { value: '289㎡', label: '289㎡' },
  { value: '290㎡', label: '290㎡' },
  { value: '291㎡', label: '291㎡' },
  { value: '292㎡', label: '292㎡' },
  { value: '293㎡', label: '293㎡' },
  { value: '294㎡', label: '294㎡' },
  { value: '295㎡', label: '295㎡' },
  { value: '296㎡', label: '296㎡' },
  { value: '297㎡', label: '297㎡' },
  { value: '298㎡', label: '298㎡' },
  { value: '299㎡', label: '299㎡' }];
  const threehundred = [{ value: '300㎡', label: '300㎡' },
  { value: '301㎡', label: '301㎡' },
  { value: '302㎡', label: '302㎡' },
  { value: '303㎡', label: '303㎡' },
  { value: '304㎡', label: '304㎡' },
  { value: '305㎡', label: '305㎡' },
  { value: '306㎡', label: '306㎡' },
  { value: '307㎡', label: '307㎡' },
  { value: '308㎡', label: '308㎡' },
  { value: '309㎡', label: '309㎡' },
  { value: '310㎡', label: '310㎡' },
  { value: '311㎡', label: '311㎡' },
  { value: '312㎡', label: '312㎡' },
  { value: '313㎡', label: '313㎡' },
  { value: '314㎡', label: '314㎡' },
  { value: '315㎡', label: '315㎡' },
  { value: '316㎡', label: '316㎡' },
  { value: '317㎡', label: '317㎡' },
  { value: '318㎡', label: '318㎡' },
  { value: '319㎡', label: '319㎡' },
  { value: '320㎡', label: '320㎡' },
  { value: '321㎡', label: '321㎡' },
  { value: '322㎡', label: '322㎡' },
  { value: '323㎡', label: '323㎡' },
  { value: '324㎡', label: '324㎡' },
  { value: '325㎡', label: '325㎡' },
  { value: '326㎡', label: '326㎡' },
  { value: '327㎡', label: '327㎡' },
  { value: '328㎡', label: '328㎡' },
  { value: '329㎡', label: '329㎡' },
  { value: '330㎡', label: '330㎡' },
  { value: '331㎡', label: '331㎡' },
  { value: '332㎡', label: '332㎡' },
  { value: '333㎡', label: '333㎡' },
  { value: '334㎡', label: '334㎡' },
  { value: '335㎡', label: '335㎡' },
  { value: '336㎡', label: '336㎡' },
  { value: '337㎡', label: '337㎡' },
  { value: '338㎡', label: '338㎡' },
  { value: '339㎡', label: '339㎡' },
  { value: '340㎡', label: '340㎡' },
  { value: '341㎡', label: '341㎡' },
  { value: '342㎡', label: '342㎡' },
  { value: '343㎡', label: '343㎡' },
  { value: '344㎡', label: '344㎡' },
  { value: '345㎡', label: '345㎡' },
  { value: '346㎡', label: '346㎡' },
  { value: '347㎡', label: '347㎡' },
  { value: '348㎡', label: '348㎡' },
  { value: '349㎡', label: '349㎡' },
  { value: '350㎡', label: '350㎡' },
  { value: '351㎡', label: '351㎡' },
  { value: '352㎡', label: '352㎡' },
  { value: '353㎡', label: '353㎡' },
  { value: '354㎡', label: '354㎡' },
  { value: '355㎡', label: '355㎡' },
  { value: '356㎡', label: '356㎡' },
  { value: '357㎡', label: '357㎡' },
  { value: '358㎡', label: '358㎡' },
  { value: '359㎡', label: '359㎡' },
  { value: '360㎡', label: '360㎡' },
  { value: '361㎡', label: '361㎡' },
  { value: '362㎡', label: '362㎡' },
  { value: '363㎡', label: '363㎡' },
  { value: '364㎡', label: '364㎡' },
  { value: '365㎡', label: '365㎡' },
  { value: '366㎡', label: '366㎡' },
  { value: '367㎡', label: '367㎡' },
  { value: '368㎡', label: '368㎡' },
  { value: '369㎡', label: '369㎡' },
  { value: '370㎡', label: '370㎡' },
  { value: '371㎡', label: '371㎡' },
  { value: '372㎡', label: '372㎡' },
  { value: '373㎡', label: '373㎡' },
  { value: '374㎡', label: '374㎡' },
  { value: '375㎡', label: '375㎡' },
  { value: '376㎡', label: '376㎡' },
  { value: '377㎡', label: '377㎡' },
  { value: '378㎡', label: '378㎡' },
  { value: '379㎡', label: '379㎡' },
  { value: '380㎡', label: '380㎡' },
  { value: '381㎡', label: '381㎡' },
  { value: '382㎡', label: '382㎡' },
  { value: '383㎡', label: '383㎡' },
  { value: '384㎡', label: '384㎡' },
  { value: '385㎡', label: '385㎡' },
  { value: '386㎡', label: '386㎡' },
  { value: '387㎡', label: '387㎡' },
  { value: '388㎡', label: '388㎡' },
  { value: '389㎡', label: '389㎡' },
  { value: '390㎡', label: '390㎡' },
  { value: '391㎡', label: '391㎡' },
  { value: '392㎡', label: '392㎡' },
  { value: '393㎡', label: '393㎡' },
  { value: '394㎡', label: '394㎡' },
  { value: '395㎡', label: '395㎡' },
  { value: '396㎡', label: '396㎡' },
  { value: '397㎡', label: '397㎡' },
  { value: '398㎡', label: '398㎡' },
  { value: '399㎡', label: '399㎡' }];
  const fourhundred = [{ value: '400㎡', label: '400㎡' },
  { value: '401㎡', label: '401㎡' },
  { value: '402㎡', label: '402㎡' },
  { value: '403㎡', label: '403㎡' },
  { value: '404㎡', label: '404㎡' },
  { value: '405㎡', label: '405㎡' },
  { value: '406㎡', label: '406㎡' },
  { value: '407㎡', label: '407㎡' },
  { value: '408㎡', label: '408㎡' },
  { value: '409㎡', label: '409㎡' },
  { value: '410㎡', label: '410㎡' },
  { value: '411㎡', label: '411㎡' },
  { value: '412㎡', label: '412㎡' },
  { value: '413㎡', label: '413㎡' },
  { value: '414㎡', label: '414㎡' },
  { value: '415㎡', label: '415㎡' },
  { value: '416㎡', label: '416㎡' },
  { value: '417㎡', label: '417㎡' },
  { value: '418㎡', label: '418㎡' },
  { value: '419㎡', label: '419㎡' },
  { value: '420㎡', label: '420㎡' },
  { value: '421㎡', label: '421㎡' },
  { value: '422㎡', label: '422㎡' },
  { value: '423㎡', label: '423㎡' },
  { value: '424㎡', label: '424㎡' },
  { value: '425㎡', label: '425㎡' },
  { value: '426㎡', label: '426㎡' },
  { value: '427㎡', label: '427㎡' },
  { value: '428㎡', label: '428㎡' },
  { value: '429㎡', label: '429㎡' },
  { value: '430㎡', label: '430㎡' },
  { value: '431㎡', label: '431㎡' },
  { value: '432㎡', label: '432㎡' },
  { value: '433㎡', label: '433㎡' },
  { value: '434㎡', label: '434㎡' },
  { value: '435㎡', label: '435㎡' },
  { value: '436㎡', label: '436㎡' },
  { value: '437㎡', label: '437㎡' },
  { value: '438㎡', label: '438㎡' },
  { value: '439㎡', label: '439㎡' },
  { value: '440㎡', label: '440㎡' },
  { value: '441㎡', label: '441㎡' },
  { value: '442㎡', label: '442㎡' },
  { value: '443㎡', label: '443㎡' },
  { value: '444㎡', label: '444㎡' },
  { value: '445㎡', label: '445㎡' },
  { value: '446㎡', label: '446㎡' },
  { value: '447㎡', label: '447㎡' },
  { value: '448㎡', label: '448㎡' },
  { value: '449㎡', label: '449㎡' },
  { value: '450㎡', label: '450㎡' },
  { value: '451㎡', label: '451㎡' },
  { value: '452㎡', label: '452㎡' },
  { value: '453㎡', label: '453㎡' },
  { value: '454㎡', label: '454㎡' },
  { value: '455㎡', label: '455㎡' },
  { value: '456㎡', label: '456㎡' },
  { value: '457㎡', label: '457㎡' },
  { value: '458㎡', label: '458㎡' },
  { value: '459㎡', label: '459㎡' },
  { value: '460㎡', label: '460㎡' },
  { value: '461㎡', label: '461㎡' },
  { value: '462㎡', label: '462㎡' },
  { value: '463㎡', label: '463㎡' },
  { value: '464㎡', label: '464㎡' },
  { value: '465㎡', label: '465㎡' },
  { value: '466㎡', label: '466㎡' },
  { value: '467㎡', label: '467㎡' },
  { value: '468㎡', label: '468㎡' },
  { value: '469㎡', label: '469㎡' },
  { value: '470㎡', label: '470㎡' },
  { value: '471㎡', label: '471㎡' },
  { value: '472㎡', label: '472㎡' },
  { value: '473㎡', label: '473㎡' },
  { value: '474㎡', label: '474㎡' },
  { value: '475㎡', label: '475㎡' },
  { value: '476㎡', label: '476㎡' },
  { value: '477㎡', label: '477㎡' },
  { value: '478㎡', label: '478㎡' },
  { value: '479㎡', label: '479㎡' },
  { value: '480㎡', label: '480㎡' },
  { value: '481㎡', label: '481㎡' },
  { value: '482㎡', label: '482㎡' },
  { value: '483㎡', label: '483㎡' },
  { value: '484㎡', label: '484㎡' },
  { value: '485㎡', label: '485㎡' },
  { value: '486㎡', label: '486㎡' },
  { value: '487㎡', label: '487㎡' },
  { value: '488㎡', label: '488㎡' },
  { value: '489㎡', label: '489㎡' },
  { value: '490㎡', label: '490㎡' },
  { value: '491㎡', label: '491㎡' },
  { value: '492㎡', label: '492㎡' },
  { value: '493㎡', label: '493㎡' },
  { value: '494㎡', label: '494㎡' },
  { value: '495㎡', label: '495㎡' },
  { value: '496㎡', label: '496㎡' },
  { value: '497㎡', label: '497㎡' },
  { value: '498㎡', label: '498㎡' },
  { value: '499㎡', label: '499㎡' }];
  const fivehundred = [{ value: '500㎡', label: '500㎡' },
  { value: '501㎡', label: '501㎡' },
  { value: '502㎡', label: '502㎡' },
  { value: '503㎡', label: '503㎡' },
  { value: '504㎡', label: '504㎡' },
  { value: '505㎡', label: '505㎡' },
  { value: '506㎡', label: '506㎡' },
  { value: '507㎡', label: '507㎡' },
  { value: '508㎡', label: '508㎡' },
  { value: '509㎡', label: '509㎡' },
  { value: '510㎡', label: '510㎡' },
  { value: '511㎡', label: '511㎡' },
  { value: '512㎡', label: '512㎡' },
  { value: '513㎡', label: '513㎡' },
  { value: '514㎡', label: '514㎡' },
  { value: '515㎡', label: '515㎡' },
  { value: '516㎡', label: '516㎡' },
  { value: '517㎡', label: '517㎡' },
  { value: '518㎡', label: '518㎡' },
  { value: '519㎡', label: '519㎡' },
  { value: '520㎡', label: '520㎡' },
  { value: '521㎡', label: '521㎡' },
  { value: '522㎡', label: '522㎡' },
  { value: '523㎡', label: '523㎡' },
  { value: '524㎡', label: '524㎡' },
  { value: '525㎡', label: '525㎡' },
  { value: '526㎡', label: '526㎡' },
  { value: '527㎡', label: '527㎡' },
  { value: '528㎡', label: '528㎡' },
  { value: '529㎡', label: '529㎡' },
  { value: '530㎡', label: '530㎡' },
  { value: '531㎡', label: '531㎡' },
  { value: '532㎡', label: '532㎡' },
  { value: '533㎡', label: '533㎡' },
  { value: '534㎡', label: '534㎡' },
  { value: '535㎡', label: '535㎡' },
  { value: '536㎡', label: '536㎡' },
  { value: '537㎡', label: '537㎡' },
  { value: '538㎡', label: '538㎡' },
  { value: '539㎡', label: '539㎡' },
  { value: '540㎡', label: '540㎡' },
  { value: '541㎡', label: '541㎡' },
  { value: '542㎡', label: '542㎡' },
  { value: '543㎡', label: '543㎡' },
  { value: '544㎡', label: '544㎡' },
  { value: '545㎡', label: '545㎡' },
  { value: '546㎡', label: '546㎡' },
  { value: '547㎡', label: '547㎡' },
  { value: '548㎡', label: '548㎡' },
  { value: '549㎡', label: '549㎡' },
  { value: '550㎡', label: '550㎡' },
  { value: '551㎡', label: '551㎡' },
  { value: '552㎡', label: '552㎡' },
  { value: '553㎡', label: '553㎡' },
  { value: '554㎡', label: '554㎡' },
  { value: '555㎡', label: '555㎡' },
  { value: '556㎡', label: '556㎡' },
  { value: '557㎡', label: '557㎡' },
  { value: '558㎡', label: '558㎡' },
  { value: '559㎡', label: '559㎡' },
  { value: '560㎡', label: '560㎡' },
  { value: '561㎡', label: '561㎡' },
  { value: '562㎡', label: '562㎡' },
  { value: '563㎡', label: '563㎡' },
  { value: '564㎡', label: '564㎡' },
  { value: '565㎡', label: '565㎡' },
  { value: '566㎡', label: '566㎡' },
  { value: '567㎡', label: '567㎡' },
  { value: '568㎡', label: '568㎡' },
  { value: '569㎡', label: '569㎡' },
  { value: '570㎡', label: '570㎡' },
  { value: '571㎡', label: '571㎡' },
  { value: '572㎡', label: '572㎡' },
  { value: '573㎡', label: '573㎡' },
  { value: '574㎡', label: '574㎡' },
  { value: '575㎡', label: '575㎡' },
  { value: '576㎡', label: '576㎡' },
  { value: '577㎡', label: '577㎡' },
  { value: '578㎡', label: '578㎡' },
  { value: '579㎡', label: '579㎡' },
  { value: '580㎡', label: '580㎡' },
  { value: '581㎡', label: '581㎡' },
  { value: '582㎡', label: '582㎡' },
  { value: '583㎡', label: '583㎡' },
  { value: '584㎡', label: '584㎡' },
  { value: '585㎡', label: '585㎡' },
  { value: '586㎡', label: '586㎡' },
  { value: '587㎡', label: '587㎡' },
  { value: '588㎡', label: '588㎡' },
  { value: '589㎡', label: '589㎡' },
  { value: '590㎡', label: '590㎡' },
  { value: '591㎡', label: '591㎡' },
  { value: '592㎡', label: '592㎡' },
  { value: '593㎡', label: '593㎡' },
  { value: '594㎡', label: '594㎡' },
  { value: '595㎡', label: '595㎡' },
  { value: '596㎡', label: '596㎡' },
  { value: '597㎡', label: '597㎡' },
  { value: '598㎡', label: '598㎡' },
  { value: '599㎡', label: '599㎡' }];
  const shixhundred = [{ value: '600㎡', label: '600㎡' },
  { value: '601㎡', label: '601㎡' },
  { value: '602㎡', label: '602㎡' },
  { value: '603㎡', label: '603㎡' },
  { value: '604㎡', label: '604㎡' },
  { value: '605㎡', label: '605㎡' },
  { value: '606㎡', label: '606㎡' },
  { value: '607㎡', label: '607㎡' },
  { value: '608㎡', label: '608㎡' },
  { value: '609㎡', label: '609㎡' },
  { value: '610㎡', label: '610㎡' },
  { value: '611㎡', label: '611㎡' },
  { value: '612㎡', label: '612㎡' },
  { value: '613㎡', label: '613㎡' },
  { value: '614㎡', label: '614㎡' },
  { value: '615㎡', label: '615㎡' },
  { value: '616㎡', label: '616㎡' },
  { value: '617㎡', label: '617㎡' },
  { value: '618㎡', label: '618㎡' },
  { value: '619㎡', label: '619㎡' },
  { value: '620㎡', label: '620㎡' },
  { value: '621㎡', label: '621㎡' },
  { value: '622㎡', label: '622㎡' },
  { value: '623㎡', label: '623㎡' },
  { value: '624㎡', label: '624㎡' },
  { value: '625㎡', label: '625㎡' },
  { value: '626㎡', label: '626㎡' },
  { value: '627㎡', label: '627㎡' },
  { value: '628㎡', label: '628㎡' },
  { value: '629㎡', label: '629㎡' },
  { value: '630㎡', label: '630㎡' },
  { value: '631㎡', label: '631㎡' },
  { value: '632㎡', label: '632㎡' },
  { value: '633㎡', label: '633㎡' },
  { value: '634㎡', label: '634㎡' },
  { value: '635㎡', label: '635㎡' },
  { value: '636㎡', label: '636㎡' },
  { value: '637㎡', label: '637㎡' },
  { value: '638㎡', label: '638㎡' },
  { value: '639㎡', label: '639㎡' },
  { value: '640㎡', label: '640㎡' },
  { value: '641㎡', label: '641㎡' },
  { value: '642㎡', label: '642㎡' },
  { value: '643㎡', label: '643㎡' },
  { value: '644㎡', label: '644㎡' },
  { value: '645㎡', label: '645㎡' },
  { value: '646㎡', label: '646㎡' },
  { value: '647㎡', label: '647㎡' },
  { value: '648㎡', label: '648㎡' },
  { value: '649㎡', label: '649㎡' },
  { value: '650㎡', label: '650㎡' },
  { value: '651㎡', label: '651㎡' },
  { value: '652㎡', label: '652㎡' },
  { value: '653㎡', label: '653㎡' },
  { value: '654㎡', label: '654㎡' },
  { value: '655㎡', label: '655㎡' },
  { value: '656㎡', label: '656㎡' },
  { value: '657㎡', label: '657㎡' },
  { value: '658㎡', label: '658㎡' },
  { value: '659㎡', label: '659㎡' },
  { value: '660㎡', label: '660㎡' },
  { value: '661㎡', label: '661㎡' },
  { value: '662㎡', label: '662㎡' },
  { value: '663㎡', label: '663㎡' },
  { value: '664㎡', label: '664㎡' },
  { value: '665㎡', label: '665㎡' },
  { value: '666㎡', label: '666㎡' },
  { value: '667㎡', label: '667㎡' },
  { value: '668㎡', label: '668㎡' },
  { value: '669㎡', label: '669㎡' },
  { value: '670㎡', label: '670㎡' },
  { value: '671㎡', label: '671㎡' },
  { value: '672㎡', label: '672㎡' },
  { value: '673㎡', label: '673㎡' },
  { value: '674㎡', label: '674㎡' },
  { value: '675㎡', label: '675㎡' },
  { value: '676㎡', label: '676㎡' },
  { value: '677㎡', label: '677㎡' },
  { value: '678㎡', label: '678㎡' },
  { value: '679㎡', label: '679㎡' },
  { value: '680㎡', label: '680㎡' },
  { value: '681㎡', label: '681㎡' },
  { value: '682㎡', label: '682㎡' },
  { value: '683㎡', label: '683㎡' },
  { value: '684㎡', label: '684㎡' },
  { value: '685㎡', label: '685㎡' },
  { value: '686㎡', label: '686㎡' },
  { value: '687㎡', label: '687㎡' },
  { value: '688㎡', label: '688㎡' },
  { value: '689㎡', label: '689㎡' },
  { value: '690㎡', label: '690㎡' },
  { value: '691㎡', label: '691㎡' },
  { value: '692㎡', label: '692㎡' },
  { value: '693㎡', label: '693㎡' },
  { value: '694㎡', label: '694㎡' },
  { value: '695㎡', label: '695㎡' },
  { value: '696㎡', label: '696㎡' },
  { value: '697㎡', label: '697㎡' },
  { value: '698㎡', label: '698㎡' },
  { value: '699㎡', label: '699㎡' }];
  const sevenhundred = [{ value: '700㎡', label: '700㎡' },
  { value: '701㎡', label: '701㎡' },
  { value: '702㎡', label: '702㎡' },
  { value: '703㎡', label: '703㎡' },
  { value: '704㎡', label: '704㎡' },
  { value: '705㎡', label: '705㎡' },
  { value: '706㎡', label: '706㎡' },
  { value: '707㎡', label: '707㎡' },
  { value: '708㎡', label: '708㎡' },
  { value: '709㎡', label: '709㎡' },
  { value: '710㎡', label: '710㎡' },
  { value: '711㎡', label: '711㎡' },
  { value: '712㎡', label: '712㎡' },
  { value: '713㎡', label: '713㎡' },
  { value: '714㎡', label: '714㎡' },
  { value: '715㎡', label: '715㎡' },
  { value: '716㎡', label: '716㎡' },
  { value: '717㎡', label: '717㎡' },
  { value: '718㎡', label: '718㎡' },
  { value: '719㎡', label: '719㎡' },
  { value: '720㎡', label: '720㎡' },
  { value: '721㎡', label: '721㎡' },
  { value: '722㎡', label: '722㎡' },
  { value: '723㎡', label: '723㎡' },
  { value: '724㎡', label: '724㎡' },
  { value: '725㎡', label: '725㎡' },
  { value: '726㎡', label: '726㎡' },
  { value: '727㎡', label: '727㎡' },
  { value: '728㎡', label: '728㎡' },
  { value: '729㎡', label: '729㎡' },
  { value: '730㎡', label: '730㎡' },
  { value: '731㎡', label: '731㎡' },
  { value: '732㎡', label: '732㎡' },
  { value: '733㎡', label: '733㎡' },
  { value: '734㎡', label: '734㎡' },
  { value: '735㎡', label: '735㎡' },
  { value: '736㎡', label: '736㎡' },
  { value: '737㎡', label: '737㎡' },
  { value: '738㎡', label: '738㎡' },
  { value: '739㎡', label: '739㎡' },
  { value: '740㎡', label: '740㎡' },
  { value: '741㎡', label: '741㎡' },
  { value: '742㎡', label: '742㎡' },
  { value: '743㎡', label: '743㎡' },
  { value: '744㎡', label: '744㎡' },
  { value: '745㎡', label: '745㎡' },
  { value: '746㎡', label: '746㎡' },
  { value: '747㎡', label: '747㎡' },
  { value: '748㎡', label: '748㎡' },
  { value: '749㎡', label: '749㎡' },
  { value: '750㎡', label: '750㎡' },
  { value: '751㎡', label: '751㎡' },
  { value: '752㎡', label: '752㎡' },
  { value: '753㎡', label: '753㎡' },
  { value: '754㎡', label: '754㎡' },
  { value: '755㎡', label: '755㎡' },
  { value: '756㎡', label: '756㎡' },
  { value: '757㎡', label: '757㎡' },
  { value: '758㎡', label: '758㎡' },
  { value: '759㎡', label: '759㎡' },
  { value: '760㎡', label: '760㎡' },
  { value: '761㎡', label: '761㎡' },
  { value: '762㎡', label: '762㎡' },
  { value: '763㎡', label: '763㎡' },
  { value: '764㎡', label: '764㎡' },
  { value: '765㎡', label: '765㎡' },
  { value: '766㎡', label: '766㎡' },
  { value: '767㎡', label: '767㎡' },
  { value: '768㎡', label: '768㎡' },
  { value: '769㎡', label: '769㎡' },
  { value: '770㎡', label: '770㎡' },
  { value: '771㎡', label: '771㎡' },
  { value: '772㎡', label: '772㎡' },
  { value: '773㎡', label: '773㎡' },
  { value: '774㎡', label: '774㎡' },
  { value: '775㎡', label: '775㎡' },
  { value: '776㎡', label: '776㎡' },
  { value: '777㎡', label: '777㎡' },
  { value: '778㎡', label: '778㎡' },
  { value: '779㎡', label: '779㎡' },
  { value: '780㎡', label: '780㎡' },
  { value: '781㎡', label: '781㎡' },
  { value: '782㎡', label: '782㎡' },
  { value: '783㎡', label: '783㎡' },
  { value: '784㎡', label: '784㎡' },
  { value: '785㎡', label: '785㎡' },
  { value: '786㎡', label: '786㎡' },
  { value: '787㎡', label: '787㎡' },
  { value: '788㎡', label: '788㎡' },
  { value: '789㎡', label: '789㎡' },
  { value: '790㎡', label: '790㎡' },
  { value: '791㎡', label: '791㎡' },
  { value: '792㎡', label: '792㎡' },
  { value: '793㎡', label: '793㎡' },
  { value: '794㎡', label: '794㎡' },
  { value: '795㎡', label: '795㎡' },
  { value: '796㎡', label: '796㎡' },
  { value: '797㎡', label: '797㎡' },
  { value: '798㎡', label: '798㎡' },
  { value: '799㎡', label: '799㎡' }];
  const eighthundred = [{ value: '800㎡', label: '800㎡' },
  { value: '801㎡', label: '801㎡' },
  { value: '802㎡', label: '802㎡' },
  { value: '803㎡', label: '803㎡' },
  { value: '804㎡', label: '804㎡' },
  { value: '805㎡', label: '805㎡' },
  { value: '806㎡', label: '806㎡' },
  { value: '807㎡', label: '807㎡' },
  { value: '808㎡', label: '808㎡' },
  { value: '809㎡', label: '809㎡' },
  { value: '810㎡', label: '810㎡' },
  { value: '811㎡', label: '811㎡' },
  { value: '812㎡', label: '812㎡' },
  { value: '813㎡', label: '813㎡' },
  { value: '814㎡', label: '814㎡' },
  { value: '815㎡', label: '815㎡' },
  { value: '816㎡', label: '816㎡' },
  { value: '817㎡', label: '817㎡' },
  { value: '818㎡', label: '818㎡' },
  { value: '819㎡', label: '819㎡' },
  { value: '820㎡', label: '820㎡' },
  { value: '821㎡', label: '821㎡' },
  { value: '822㎡', label: '822㎡' },
  { value: '823㎡', label: '823㎡' },
  { value: '824㎡', label: '824㎡' },
  { value: '825㎡', label: '825㎡' },
  { value: '826㎡', label: '826㎡' },
  { value: '827㎡', label: '827㎡' },
  { value: '828㎡', label: '828㎡' },
  { value: '829㎡', label: '829㎡' },
  { value: '830㎡', label: '830㎡' },
  { value: '831㎡', label: '831㎡' },
  { value: '832㎡', label: '832㎡' },
  { value: '833㎡', label: '833㎡' },
  { value: '834㎡', label: '834㎡' },
  { value: '835㎡', label: '835㎡' },
  { value: '836㎡', label: '836㎡' },
  { value: '837㎡', label: '837㎡' },
  { value: '838㎡', label: '838㎡' },
  { value: '839㎡', label: '839㎡' },
  { value: '840㎡', label: '840㎡' },
  { value: '841㎡', label: '841㎡' },
  { value: '842㎡', label: '842㎡' },
  { value: '843㎡', label: '843㎡' },
  { value: '844㎡', label: '844㎡' },
  { value: '845㎡', label: '845㎡' },
  { value: '846㎡', label: '846㎡' },
  { value: '847㎡', label: '847㎡' },
  { value: '848㎡', label: '848㎡' },
  { value: '849㎡', label: '849㎡' },
  { value: '850㎡', label: '850㎡' },
  { value: '851㎡', label: '851㎡' },
  { value: '852㎡', label: '852㎡' },
  { value: '853㎡', label: '853㎡' },
  { value: '854㎡', label: '854㎡' },
  { value: '855㎡', label: '855㎡' },
  { value: '856㎡', label: '856㎡' },
  { value: '857㎡', label: '857㎡' },
  { value: '858㎡', label: '858㎡' },
  { value: '859㎡', label: '859㎡' },
  { value: '860㎡', label: '860㎡' },
  { value: '861㎡', label: '861㎡' },
  { value: '862㎡', label: '862㎡' },
  { value: '863㎡', label: '863㎡' },
  { value: '864㎡', label: '864㎡' },
  { value: '865㎡', label: '865㎡' },
  { value: '866㎡', label: '866㎡' },
  { value: '867㎡', label: '867㎡' },
  { value: '868㎡', label: '868㎡' },
  { value: '869㎡', label: '869㎡' },
  { value: '870㎡', label: '870㎡' },
  { value: '871㎡', label: '871㎡' },
  { value: '872㎡', label: '872㎡' },
  { value: '873㎡', label: '873㎡' },
  { value: '874㎡', label: '874㎡' },
  { value: '875㎡', label: '875㎡' },
  { value: '876㎡', label: '876㎡' },
  { value: '877㎡', label: '877㎡' },
  { value: '878㎡', label: '878㎡' },
  { value: '879㎡', label: '879㎡' },
  { value: '880㎡', label: '880㎡' },
  { value: '881㎡', label: '881㎡' },
  { value: '882㎡', label: '882㎡' },
  { value: '883㎡', label: '883㎡' },
  { value: '884㎡', label: '884㎡' },
  { value: '885㎡', label: '885㎡' },
  { value: '886㎡', label: '886㎡' },
  { value: '887㎡', label: '887㎡' },
  { value: '888㎡', label: '888㎡' },
  { value: '889㎡', label: '889㎡' },
  { value: '890㎡', label: '890㎡' },
  { value: '891㎡', label: '891㎡' },
  { value: '892㎡', label: '892㎡' },
  { value: '893㎡', label: '893㎡' },
  { value: '894㎡', label: '894㎡' },
  { value: '895㎡', label: '895㎡' },
  { value: '896㎡', label: '896㎡' },
  { value: '897㎡', label: '897㎡' },
  { value: '898㎡', label: '898㎡' },
  { value: '899㎡', label: '899㎡' }];
  const ninehundred = [{ value: '900㎡', label: '900㎡' },
  { value: '901㎡', label: '901㎡' },
  { value: '902㎡', label: '902㎡' },
  { value: '903㎡', label: '903㎡' },
  { value: '904㎡', label: '904㎡' },
  { value: '905㎡', label: '905㎡' },
  { value: '906㎡', label: '906㎡' },
  { value: '907㎡', label: '907㎡' },
  { value: '908㎡', label: '908㎡' },
  { value: '909㎡', label: '909㎡' },
  { value: '910㎡', label: '910㎡' },
  { value: '911㎡', label: '911㎡' },
  { value: '912㎡', label: '912㎡' },
  { value: '913㎡', label: '913㎡' },
  { value: '914㎡', label: '914㎡' },
  { value: '915㎡', label: '915㎡' },
  { value: '916㎡', label: '916㎡' },
  { value: '917㎡', label: '917㎡' },
  { value: '918㎡', label: '918㎡' },
  { value: '919㎡', label: '919㎡' },
  { value: '920㎡', label: '920㎡' },
  { value: '921㎡', label: '921㎡' },
  { value: '922㎡', label: '922㎡' },
  { value: '923㎡', label: '923㎡' },
  { value: '924㎡', label: '924㎡' },
  { value: '925㎡', label: '925㎡' },
  { value: '926㎡', label: '926㎡' },
  { value: '927㎡', label: '927㎡' },
  { value: '928㎡', label: '928㎡' },
  { value: '929㎡', label: '929㎡' },
  { value: '930㎡', label: '930㎡' },
  { value: '931㎡', label: '931㎡' },
  { value: '932㎡', label: '932㎡' },
  { value: '933㎡', label: '933㎡' },
  { value: '934㎡', label: '934㎡' },
  { value: '935㎡', label: '935㎡' },
  { value: '936㎡', label: '936㎡' },
  { value: '937㎡', label: '937㎡' },
  { value: '938㎡', label: '938㎡' },
  { value: '939㎡', label: '939㎡' },
  { value: '940㎡', label: '940㎡' },
  { value: '941㎡', label: '941㎡' },
  { value: '942㎡', label: '942㎡' },
  { value: '943㎡', label: '943㎡' },
  { value: '944㎡', label: '944㎡' },
  { value: '945㎡', label: '945㎡' },
  { value: '946㎡', label: '946㎡' },
  { value: '947㎡', label: '947㎡' },
  { value: '948㎡', label: '948㎡' },
  { value: '949㎡', label: '949㎡' },
  { value: '950㎡', label: '950㎡' },
  { value: '951㎡', label: '951㎡' },
  { value: '952㎡', label: '952㎡' },
  { value: '953㎡', label: '953㎡' },
  { value: '954㎡', label: '954㎡' },
  { value: '955㎡', label: '955㎡' },
  { value: '956㎡', label: '956㎡' },
  { value: '957㎡', label: '957㎡' },
  { value: '958㎡', label: '958㎡' },
  { value: '959㎡', label: '959㎡' },
  { value: '960㎡', label: '960㎡' },
  { value: '961㎡', label: '961㎡' },
  { value: '962㎡', label: '962㎡' },
  { value: '963㎡', label: '963㎡' },
  { value: '964㎡', label: '964㎡' },
  { value: '965㎡', label: '965㎡' },
  { value: '966㎡', label: '966㎡' },
  { value: '967㎡', label: '967㎡' },
  { value: '968㎡', label: '968㎡' },
  { value: '969㎡', label: '969㎡' },
  { value: '970㎡', label: '970㎡' },
  { value: '971㎡', label: '971㎡' },
  { value: '972㎡', label: '972㎡' },
  { value: '973㎡', label: '973㎡' },
  { value: '974㎡', label: '974㎡' },
  { value: '975㎡', label: '975㎡' },
  { value: '976㎡', label: '976㎡' },
  { value: '977㎡', label: '977㎡' },
  { value: '978㎡', label: '978㎡' },
  { value: '979㎡', label: '979㎡' },
  { value: '980㎡', label: '980㎡' },
  { value: '981㎡', label: '981㎡' },
  { value: '982㎡', label: '982㎡' },
  { value: '983㎡', label: '983㎡' },
  { value: '984㎡', label: '984㎡' },
  { value: '985㎡', label: '985㎡' },
  { value: '986㎡', label: '986㎡' },
  { value: '987㎡', label: '987㎡' },
  { value: '988㎡', label: '988㎡' },
  { value: '989㎡', label: '989㎡' },
  { value: '990㎡', label: '990㎡' },
  { value: '991㎡', label: '991㎡' },
  { value: '992㎡', label: '992㎡' },
  { value: '993㎡', label: '993㎡' },
  { value: '994㎡', label: '994㎡' },
  { value: '995㎡', label: '995㎡' },
  { value: '996㎡', label: '996㎡' },
  { value: '997㎡', label: '997㎡' },
  { value: '998㎡', label: '998㎡' },
  { value: '999㎡', label: '999㎡' }];

  const popupNobeyuka = (event) => {
    if (event.type === "focus") {
      setFloorFlg(true);
    } else if (event.type === "blur") {
      //setFloorFlg(false);
    }
  }
  const popupTochi = (event) => {
    if (event.type === "focus") {
      setLandFlg(true);
    } else if (event.type === "blur") {
      //setFloorFlg(false);
    }
  }
  const triggerNext = (event) => {
    switch(type){
      case '土地':
      case '農地':
      case 'その他':
        setTrigger(true, props.triggerNextStep({ trigger: 'bukkenQa2' }));
        break;
      default:
    //setTrigger(true, props.triggerNextStep({ trigger: 'bukkenQa' }))
    setTrigger(true, props.triggerNextStep({ trigger: 'propertyData3' }));
    break;
    }
  }
  const  handleSubmit = (event) => {
    event.preventDefault();
    var roomData = {};
    if (event.target.className === "nobeyukaButtons") {
      if (event.target.value.match("㎡〜")) {
        let num = event.target.value.replace("㎡〜", "");
        setNobeyukaMenssekiFlg(num);
      }
      else if (event.target.value === "100㎡以上") {
        setNobeyukaMenssekiFlg("00");
      }
      else if (event.target.value === "1000㎡以上") {
        setNobeyukaMenssekiFlg("1000");
      }
      else {
        let size = event.target.value.replace("㎡", "");
        setRoomSize(event.target.value);
        roomData["roomSize"] = size;
        roomData["roomUnit"] = "㎡";
        props.step.value = roomData;
        setFloorFlg(false);
        setNobeyukaMenssekiFlg('0');
        setTimeout(() => {
          closeNobeyuka();
        }, "500")
      }
    }
    if (event.target.className === "tochiButtons") {
      if (event.target.value.match("㎡〜")) {
        let num = event.target.value.replace("㎡〜", "");
        setTochiMenssekiFlg(num);
      }
      else if (event.target.value === "100㎡以上") {
        setTochiMenssekiFlg("00");
      }
      else if (event.target.value === "1000㎡以上") {
        setTochiMenssekiFlg("1000");
      }
      else {
        let size = event.target.value.replace("㎡", "");
        setTochiSize(event.target.value);
        roomData["tochiSize"] = size;
        roomData["tochiUnit"] = "㎡";
        if(!props.step.value){
        props.step.value = roomData;
        }else{
          props.step.value["tochiSize"] = roomData["tochiSize"];
          props.step.value["tochiUnit"] = roomData["tochiUnit"];
        }
        setLandFlg(false);
        setTochiMenssekiFlg('0');
        setTimeout(() => {
          closeTochi();
        }, "500")
      }
    }
   
  };
  const closeNobeyuka = () => {
    switch (type) {
      case 'マンション':
        triggerNext();
        break;
      case '戸建て':
      case '一棟アパート・一棟マンション':
      case '一棟ビル':
      case '区分所有ビル':
      case '店舗・工場・倉庫':
        setLandFlg(true);
        setTochiMenssekiFlg("0");
        break;
      default:
        break;
    }
  }
  const closeTochi = () => {
        triggerNext();
  }

  const inputChange = (event) => {
    //if (event.target.value.match(/^[0-9]*$/) || event.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
    switch (event.target.name) {
      case 'roomSize':
        setRoomSize(event.target.value)
        break;
      case 'tochiSize':
        setTochiSize(event.target.value)
        break;
      default:
        break;
    }
    //}
  }
  const returnPage = (event) => {
    event.preventDefault();
    if (event.target.className.match("return0p")) {
      setNobeyukaMenssekiFlg("0");
    } else if (event.target.className.match("return00p")) {
      setNobeyukaMenssekiFlg("00");
    }
  }
  const returnPage2 = (event) => {
    event.preventDefault();
    if (event.target.className.match("return0p")) {
      setTochiMenssekiFlg("0");
    } else if (event.target.className.match("return00p")) {
      setTochiMenssekiFlg("00");
    }
  }
  const inputChange2 = (event) => {
    event.preventDefault();
    if (event.target.value.match(/^[0-9]*$/) || event.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
      switch (event.target.name) {
        case 'roomSize1000':
          setRoomSize2(event.target.value);
          break;
        case 'tochiSize1000':
          setTochiSize2(event.target.value);
          break;
        default:
          break;
      }
    }
  }
  const focusIn1 = (e) => {
    e.preventDefault();
    setErrorMsg('');
  }
  const focusOut1 = (event) =>{
    event.preventDefault();
    switch(event.target.name){
      case "roomSize1000":
        if(event.target.value === ''){
          event.target.value = '';
          setErrorMsg('※半角数字で入力してください');
        }
        else if (event.target.value.match(/^[0-9]*$/) || event.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
          let size = event.target.value + "㎡";
        setRoomSize(size);
        let roomData = {};
        roomData["roomSize"] = event.target.value;
        roomData["roomUnit"] = "㎡";
        props.step.value = roomData;
        setFloorFlg(false);
        setNobeyukaMenssekiFlg('0');
        setTimeout(() => {
          closeNobeyuka();
          setRoomSize2('');
        }, "500")
        }
        else{
          event.target.value = '';
          setErrorMsg('※半角数字で入力してください');
      }
        break;
      case "tochiSize1000":
        if(event.target.value === ''){
          event.target.value = '';
          setErrorMsg('※半角数字で入力してください');
        }
        else if (event.target.value.match(/^[0-9]*$/) || event.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
          let size = event.target.value + "㎡";
          setTochiSize(size);
          let roomData = {};
          roomData["tochiSize"] = event.target.value;
          roomData["tochiUnit"] = "㎡";
          if(!props.step.value){
            props.step.value = roomData;
          }else{
            props.step.value["tochiSize"] = roomData["tochiSize"];
            props.step.value["tochiUnit"] = roomData["tochiUnit"];
               }
          setLandFlg(false);
          setTochiMenssekiFlg('0');
          setTimeout(() => {
            closeTochi();
            setTochiSize2('');
          }, "500")
        }
        else{
          event.target.value = '';
          setErrorMsg('※半角数字で入力してください');
        }
        break;
      default:
        break;
    }
    
  }
 
  useEffect(() => {
    //テスト用データ
    console.log("props.steps",props.steps);
    //テスト用データここまで
    (props.steps.bukkenType.value === 'その他') ? setType(props.steps.other.value) : setType(props.steps.bukkenType.value);
    switch (type) {
      case 'マンション':
        setNobeyukamenseki(true);
        setTochimenseki(false);
        setFloorFlg(true);
        setNobeyukaMenssekiFlg("0");
        break;
      case '戸建て':
      case '一棟アパート・一棟マンション':
      case '一棟ビル':
      case '区分所有ビル':
      case '店舗・工場・倉庫':
        setNobeyukamenseki(true);
        setTochimenseki(true);
        setFloorFlg(true);
        setNobeyukaMenssekiFlg("0");
        break;
      case '土地':
      case '農地':
      case 'その他':
        setNobeyukamenseki(false);
        setTochimenseki(true);
        setLandFlg(true);
        setTochiMenssekiFlg("0");
        break;
      default:
        break;

    }
  }, [type]);
  const modalOverlay = (e) => {
      const backgroundDiv = document.getElementsByClassName('overley');
      const bubble = document.getElementsByClassName('rsc-ts-bubble');
      const backWhite = document.getElementsByClassName('back-white-wrapper');
      const rscContent = document.getElementsByClassName('rsc-content');
      const operator = document.getElementsByClassName('rsc-ts-image');
      console.log("operator",operator);
      if(e === true){
        backgroundDiv[0].style.display = "unset";
        rscContent[0].style.overflowY = "hidden";
        Object.keys(bubble).forEach(e => { 
          bubble[e].style.opacity = "0.1";
        });
        Object.keys(operator).forEach(e => { 
          operator[e].style.opacity = "0.1";
        });
        Object.keys(backWhite).forEach(e => { 
          if(backWhite[e].className.indexOf('propertyDataForm2') !== -1){
            backWhite[e].style.backgroundColor = "transparent";
          }else{
              backWhite[e].style.opacity = "0.1";
          }
        });
      }else{
      backgroundDiv[0].style.display = "none";
        operator[0].style.opacity = "1";
        rscContent[0].style.overflowY = "scroll";
        Object.keys(bubble).forEach(e => { 
              bubble[e].style.opacity = "1";
        });
        Object.keys(operator).forEach(e => { 
          operator[e].style.opacity = "1";
        });
        Object.keys(backWhite).forEach(e => { 
          if(backWhite[e].className.indexOf('propertyDataForm2') !== -1){
            backWhite[e].style.backgroundColor = "#fff";
          }else{
              backWhite[e].style.opacity = "1";
          }
        });
      }
    }
  useEffect(() =>  {
    modalOverlay(floorFlg);
  }, [floorFlg]);
  useEffect(() =>  {
    modalOverlay(landFlg);
  }, [landFlg]);


  return (

    <form className="formCss1 propertyDataForm2 modalForm back-white-wrapper">
      {nobeyukaMenseki && <><label>専有面積/延床面積:<br></br><input onFocus={popupNobeyuka} type="text" name="roomSize" value={roomSize} onChange={inputChange} />
      </label><br></br>
      </>
      }
      {floorFlg && <>
        <div className="modal-container">
          <div className="back-orange-wrapper modal-window">
           
            {nobeyukaMensekiFlg === "0" && <><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div>
            <div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{oneToHundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "10" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{tenToTwenty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "20" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{twentyToThirty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "30" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'></div><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{thirtyToForty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "40" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fortyToFifty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "50" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fiftyToSixty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "60" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{sixtyToSeventy?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "70" && <><div><button onClick={returnPage} className="returnButtonreturn0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{seventyToEighty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "80" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{eightyToNinty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "90" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{nintyToHundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "00" && <><div><button onClick={returnPage} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{hundredToThousand?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "100" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{hundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "200" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{twohundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "300" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{threehundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "400" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fourhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "500" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fivehundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "600" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{shixhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "700" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{sevenhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "800" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{eighthundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {nobeyukaMensekiFlg === "900" && <><div><button onClick={returnPage} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>専有面積/延床面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{ninehundred?.map(e => { return <><li><button onClick={handleSubmit}className='nobeyukaButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}


            {nobeyukaMensekiFlg === "1000" && <><div><div className="modal-header"><p>専有面積/延床面積を入力してください</p></div><div className='modal-box'><input type="text" name="roomSize1000" value={roomSize2} onChange={inputChange2} placeholder="㎡" onFocus={focusIn1} onBlur={focusOut1} /></div>
      <p className='errMsg' style={{color: 'black'}}>{errorMessage}</p></div><br></br></>}
          </div>
        </div>
      </>}
      {tochimenseki && <><label>土地面積:<br></br><input onFocus={popupTochi}type="text" name="tochiSize" value={tochiSize} onChange={inputChange} /></label><br></br>
      </>
      }
      {landFlg && <>
        <div className="modal-container">
          <div className="back-orange-wrapper modal-window">
            {tochiMensekiFlg === "0" && <><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{oneToHundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "10" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{tenToTwenty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "20" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{twentyToThirty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "30" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{thirtyToForty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "40" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fortyToFifty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "50" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fiftyToSixty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "60" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{sixtyToSeventy?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "70" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{seventyToEighty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "80" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{eightyToNinty?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "90" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{nintyToHundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "00" && <><div><button onClick={returnPage2} className="returnButton return0p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{hundredToThousand?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "100" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{hundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "200" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{twohundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "300" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{threehundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "400" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fourhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "500" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{fivehundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "600" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{shixhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "700" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{sevenhundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "800" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{eighthundred?.map(e => { return <><li className='sc-htpNat GgOGn rsc-os-option'><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}
            {tochiMensekiFlg === "900" && <><div><button onClick={returnPage2} className="returnButton return00p" value="戻る">戻る</button></div><div className="modal-header"><p>土地面積を選択してください</p></div><div className='modal-box'><ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>{ninehundred?.map(e => { return <><li><button onClick={handleSubmit}className='tochiButtons' value={e.value}>{e.label}</button></li></> })}</ul></div></>}


            {tochiMensekiFlg === "1000" && <><div><div className="modal-header"><p>土地面積を入力してください</p></div><div className='modal-box'><input type="text" name="tochiSize1000" value={tochiSize2} onChange={inputChange2} placeholder="㎡" onFocus={focusIn1} onBlur={focusOut1}/></div>
      <p className='errMsg' style={{color: 'black'}}>{errorMessage}</p></div><br></br></>}
          </div>
        </div>
      </>}
    </form>
  );
}

