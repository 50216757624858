
import { createContext, useState, useContext } from 'react';

const ContextChecker = createContext();

export function useGenkyoContext() {
  return useContext(ContextChecker);
}

export function ContextProvider({ children }) {
  const [genkyoState, setgenkyoState] = useState(1);
  const [kyojyuState, setKyojyuState] = useState(1);
  const [kentoState, setKentoState] = useState(1);

  const value = {
    genkyoState,
    setgenkyoState,
    kyojyuState,
    setKyojyuState,
    kentoState,
    setKentoState,
  };

  return (
    <ContextChecker.Provider value={value}>{children}</ContextChecker.Provider>
  );
}