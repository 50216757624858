import React from 'react';
export default function Terms(){
    return (
        <>
        <div style={{border:'1px solid #ccc',height:'100px',overflow:'auto',width:'90%',borderRadius:'3px',backgroundColor:'#f7f7f7',margin:'auto',padding:'10px',fontSize: 'initial',lineHeight:'normal'}}>当社は、現代の情報通信社会における個人情報保護の重要性を認識し、下記の方針に基づき個人情報の保護に努めます。弊社に個人情報を提供するにあたっては、下記の内容についてご同意のうえ、ご提供いただきますようお願いします。

        <br></br><br></br>
<br></br>第１章　総則
<br></br>第１条 （利用規約）
<br></br>本規約は、株式会社budi tech（以下、「当社」といいます。）が管理・運営する不動産ポータル検索サイト「イエカカク」（https://iekakaku.jp/ 以下、「本サイト」といいます。）において提供されるサービス（以下、「本サービス」といいます。）を利用する際に適用されます。
<br></br><br></br>
<br></br>第２条 （本規約への同意）
<br></br>本サイト又は本サービス（以下、あわせて「本サイト等」といいます。）の利用者（会員登録の有無を問わず、本サイト等を利用するすべての者をいいます。以下、同じ。）は、本規約の内容を理解し、これに同意の上、本サイト等を利用するものとします。なお、本サイト等を利用した場合、利用者は、本規約の内容を理解し、これに同意の上、本サイト等を利用したとみなすものとします。
<br></br><br></br>
<br></br>第３条 （本規約の範囲）
<br></br>名称の如何を問わず、本サイト上に表示された本サイト等の利用にかかる一切の記載も本規約の一部を構成するものとします。
<br></br><br></br>
<br></br>第４条 （本規約の変更）
<br></br>１）当社は以下の各号のいずれかに該当する場合に、本規約を随時変更することがあります。なお、この場合には、利用者の利用条件その他本サービスの内容は、変更後の利用規約を適用するものとします。
<br></br>① 本規約の変更が利用者の一般の利益に適合するとき
<br></br>② 本規約の変更が、本サービスの目的に反するものではなく、かつ変更の必要性、変更後の内容の相当性及び合理性があるとき
<br></br>２）本規約の変更は、当社が変更後の本規約を、その変更日と共に予め本サイト上に表示し、当該変更日が到来した時点から効力が生じるものとします。
<br></br>３）変更後の本規約の効力が生じた後に、利用者が本サイト等を利用した場合、利用者は、変更後の本規約の内容を理解し、これに同意の上、本サイト等を利用したとみなすものとします。
<br></br><br></br>
<br></br>第５条 （当社からの通知）
<br></br>１）当社は、利用者に対する通知を本サイト上への表示又は当社が適当と判断する方法で行うものとします。
<br></br>２）前項の通知を本サイト上への表示の方法で行う場合は、当社が利用者に対する通知を本サイト上へ表示した時から効力が生じるものとし、電子メールによる方法で行う場合は、当社が利用者に対する電子メールを発信した時から効力が生じるものとします。
<br></br><br></br>
<br></br>第６条 （利用・会員登録）
<br></br>１）利用者は、本規約の内容を理解し、これに同意の上、本サイト等を利用するものとします。
<br></br>２）本サイト等の利用にあたり、会員登録が必要となるものについては、利用者は、本規約の内容を理解し、これに同意の上、別途当社が定める方法で、必要事項の登録及び利用の申し込みを行い、当社の承諾を得るものとします。なお、当社が利用の申し込みを承諾しないときでも、当社は、審査の内容及び理由について、開示しないものとし、利用者は、異議を述べることができないものとします。
<br></br><br></br>
<br></br>第７条 （個人情報）
<br></br>１）当社による利用者の個人情報の取扱については、別途当社プライバシーポリシー（https://budi-tech.com/privacy/）の定めによるものとし、利用者は、このプライバシーポリシーに従って当社がユーザーの個人情報を取り扱うこと及び当社が利用者の個人情報を利用者に代わって当社の紹介する不動産会社に提供することについて同意するものとします。なお、利用者が本サービスを利用したことをもって、利用者が、当社が紹介した不動産会社のプライバシーポリシーを確認したものとみなします。
<br></br>２）利用者は、本サービスの提供のために利用者への電話連絡をする際に、本サービスの質の向上を目的として、当該通話の内容を録音することについて同意するものとします。
<br></br>３）当社は、利用者が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
<br></br>４）当社がユーザーを不動産会社に紹介したところ、当該不動産会社から、以下の各号のいずれかに該当する旨の連絡を受けた場合は、その事実を確認することを目的として、当社が以下のユーザーの個人情報を当該不動産会社から取得することに同意するものとします。
<br></br>① 当該不動産会社が既にユーザーの情報を知得している場合（ユーザーが本サイト以外のWebサイト等に登録した結果当該不動産会社にユーザーが紹介された場合、又は既にユーザーと当該不動産会社との間でやり取りがあった場合を含みますがこれらに限られません）
<br></br>a. 氏名
<br></br>b. 査定の対象となる物件の住所
<br></br>c. 電話番号
<br></br>d. 電子メールアドレス
<br></br>e. 当該不動産会社がユーザー情報を知得した日時
<br></br>② ユーザーが当該不動産会社以外の不動産会社と専属専任媒介契約を締結していた場合
<br></br>a. 当該媒介契約の媒介種別、契約内容及び締結年月日又は更新年月日
<br></br>b. 査定依頼者の氏名
<br></br>c. 査定の対象となる物件の住所
<br></br>③ ユーザーが、査定対象となる物件の所有者以外の者であって、当該物件の所有者と二親等以内にある親族と法的に代理権を有する者ではない場合
<br></br>a. 査定依頼者及び物件の所有者の氏名
<br></br>b. 査定の対象となる物件の住所
<br></br>c. 当社から不動産会社への紹介日時
<br></br>５）当社は、当社のサービス品質の向上又は改善のために、利用者を紹介した不動産会社から以下の情報を取得することがありますが、利用者は予めこれに同意するものとします。なお、以下の情報は、法令により認められている場合を除き、お客様の同意を得ずに公表又は第三者への開示をすることはありません。
<br></br>① 当該不動産会社が利用者情報を管理するために付したID等の記号
<br></br>② 当該不動産会社と利用者の通電又は連絡の状況
<br></br>③ 当該不動産会社が利用者に訪問査定を行ったか否か
<br></br>④ 当該不動産会社と利用者との間で媒介契約を締結したか否か
<br></br>⑤ 当該不動産会社に対して利用者情報が送られた日時
<br></br>６）当社は、不動産会社へのコンサルティング又はサービスの提案のために、利用者が物件の売却を特定の不動産会社に委託した事実（その可能性も含みます）、委託先となる不動産会社の情報、当該売却の委託における売出し価格、売買契約の成約の有無、及びこれらの情報の入手経路を、不動産会社に提供することがあります。
<br></br>７）当社は、当社と提携している第三者（広告の掲載を委託している第三者を含みますがこれに限られません）に対して利用者が提供した査定依頼に関する情報（当該第三者が運営又は保有するWebサイト又は入力フォームに利用者が入力した一切の情報を含みますが、これに限られません）を、当該第三者から取得することがあります。
<br></br><br></br>
<br></br>第８条（提携サービスに関する紹介）
<br></br>当社は、当社と提携している企業のサービスに関する情報を、利用者が本サイト等に登録している電子メールアドレス宛に発信することがあり、利用者は予めこれに同意するものとします。
<br></br><br></br>
<br></br>第９条 （登録内容の変更）
<br></br>１）利用者は、第７条の規定に従って登録した情報に変更があった場合、別途当社が定める方法で、遅滞なく、変更の届出をするものとします。
<br></br>２）利用者が前項の規定に従って、変更の届け出を怠ったことによって、利用者に生じた損害につき、当社は、法律上、事実上問わず、一切の責任を負わないものとします。
<br></br><br></br>
<br></br>第10条 （利用者ID）
<br></br>１）当社は、本サイト等を利用のためのID及びパスワード（以下、「ID等」）といいます）が必要となるときは、利用者に対して、ID等を貸与するものとします。
<br></br>２）利用者は、ID等を第三者に知られないように厳重に管理・保管するものとし、管理・保管にかかる一切の責任を負うものとします。
<br></br>３）ID等を利用した本サイト等の利用は、ID等を提供された利用者の故意・過失にかかわらず、利用者による利用とみなすものとします。なお、当社は、ID等が第三者に利用されたことによって、当該利用者に生じた損害については、法律上、事実上問わず、一切の責任を負わないものとします。
<br></br><br></br>
<br></br>第11条 （禁止事項）
<br></br>利用者は、本サイト等の利用に際して、以下の行為を行ってはならないものとします。
<br></br>① 第三者になりすまして、本サイト等を利用する行為
<br></br>② 第三者に本サイト等を利用させる行為
<br></br>③ 本サイト等の円滑な提供を妨げる行為又は妨げるおそれのある行為
<br></br>④ 当社又は第三者の権利・利益を侵害する行為又は侵害するおそれのある行為
<br></br>⑤ 法令若しくは公序良俗に反する行為又は反するおそれのある行為
<br></br>⑥ 本サイト等を利用した営業活動その他営利を目的とする行為
<br></br>⑦ 当社又は第三者の名誉・信用を毀損する行為又は毀損するおそれのある行為
<br></br>⑧ 当社又は第三者を誹謗、中傷する行為又は誹謗、中傷するおそれのある行為
<br></br>⑨ その他、当社が不適切と判断する行為
<br></br><br></br>
<br></br>第12条 （本サービスの停止等）
<br></br>１）当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
<br></br>① 本サービスに関わるコンピューターシステムの点検又は保守作業を緊急に行う場合
<br></br>② コンピューター、通信回線等が事故により停止した場合
<br></br>③ 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
<br></br>④ その他、当社が停止又は中断が必要と判断した場合
<br></br>２）当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
<br></br><br></br>
<br></br>第13条 （権利帰属）
<br></br>本サイト等及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サイト等又は本サービスに関する当社又は当社にライセンスを許諾しているものの知的財産権の使用許諾を意味するものではありません。
<br></br><br></br>
<br></br>第14条 （本サービスの内容の変更、終了）
<br></br>１）当社は、当社の都合により、本サービスの内容を変更し、又は提供を終了することができます。当社が本サービスの提供を終了する場合、当社は第５条の規定に従って、利用者に通知するものとします。
<br></br>２）当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
<br></br><br></br>
<br></br>第15条 （免責）
<br></br>１）本サービスは、利用者と不動産査定を行う第三者に、取引の端緒を提供するに過ぎず、当社は、取引の当事者とはならないものとします。
<br></br>２）本サービスは、利用者及び不動産会社その他の第三者に対して取引を強制するものではなく、利用者は、自己の判断と責任で本サービスを利用するものとし、本サービスに関連して利用者と第三者（不動産会社を含みます）との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。
<br></br>第16条 （秘密保持）
<br></br>利用者は、本サービスに関連して利用者が取得した情報を厳に秘密として取り扱うものとし、当社の事前の書面による承諾がある場合を除き、第三者に開示しないものとします。
<br></br><br></br>
<br></br>第17条 （分離可能性）
<br></br>本規約のいずれかの条項又はその一部が、関連の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
<br></br><br></br>
<br></br>第18条 （準拠法及び管轄裁判所）
<br></br>１）本規約の準拠法は日本法とします。
<br></br>２）本規約に関する紛争に関しては、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄とします。
<br></br><br></br>
<br></br>第２章　工事会社を紹介する場合における特記事項
<br></br><br></br>
<br></br>第19条 （読み替え）
<br></br>当社がユーザーの同意を得た上でユーザーを工事会社に紹介する場合、前条までの規定に加え、本条以降の規定が適用されるものとします。この場合、本規約に定める「不動産会社」を、「工事会社」に読み替えるものとします。
<br></br><br></br>
<br></br>第20条 （工事会社に対する情報提供）
<br></br>当社は、工事会社への情報提供フォームにユーザーの代理で入力する方法によって、ユーザーから取得した情報を工事会社に提供することができるものとし、ユーザーはこれに同意するものとします。
<br></br><br></br>
<br></br>推奨環境
<br></br><br></br>
<br></br>当Webサイトを快適に閲覧・ご利用いただくためには以下のブラウザを推奨いたします。
<br></br><br></br>
<br></br>Google Chrome 最新版
<br></br><br></br>
<br></br>【2022年11月8日改定】
<br></br><br></br>
<br></br></div>
        </>
    )
}