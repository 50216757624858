import React, { Suspense, useState, useEffect, useRef } from 'react';
import SetCities from './setCity';
import SetTowns from './setTowns';
import { useKana } from 'react-use-kana';
import {telBlacklist,shimeiBlackList,kanaBlackList} from './blacklist';

const CheckBox = ({ id, value, checked, onChange }) => {
  return (
    <input
      id={id}
      type="checkbox"
      name="inputNames"
      checked={checked}
      onChange={onChange}
      value={value}
    />
  )
}

export default function Contact2e(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [tel, setTel] = useState();
  const [telV, setTelV] = useState(true);
  const [telError, setTelError] = useState();
  const [activityChanged, setActivityChanged] = useState(false);
  const [buttonFlg, setButtonFlg] = useState(false);
  const [sirName, setSirName] = useState();
  const [sirNameKana, setSirNameKana] = useState();
  const [firstName, setFirstName] = useState();
  const [firstKana, setFirstNameKana] = useState();
  const [kanaError, setKanaError] = useState();
  const [kyojyu, setKyojyu] = useState(false);
  const [prefecture, setPrefecture] = useState();
  const [cityValue, setCityValue] = useState();
  const [townSetValue, setTownSetValue] = useState();
  const [townValue, setTownValue] = useState();
  const [address, setAddress] = useState();
  const [sirNameV, setSirNameV] = useState(true);
  const [sirNameKanaV, setSirNameKanaV] = useState(true);
  const [firstNameV, setFirstNameV] = useState(true);
  const [firstKanaV, setFirstNameKanaV] = useState(true);
  const [addressV, setAddressV] = useState(true);
  const {
    kana: lastNameKana,
    setKanaSource: setLastNameKanaSource
  } = useKana();
  const {
    kana: firstNameKana,
    setKanaSource: setFirstNameKanaSource
  } = useKana();

  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const { dataLayer } = window;
  const prefectures = [null, '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

  const checkEmpty = (name, value) => {
    if (kyojyu) {
      if (((name === "sirName" && !value) || (name !== "sirName" && !sirName))
        || ((name === "sirNameKana" && !value) || (name !== "sirNameKana" && !sirNameKana))
        || ((name === "firstName" && !value) || (name !== "firstName" && !firstName))
        || ((name === "firstKana" && !value) || (name !== "firstKana" && !firstKana))
        || ((name === "prefecture" && !value) || (name !== "prefecture" && !prefecture))
        || ((name === "cityValue" && !value) || (name !== "cityValue" && !cityValue))
        || ((name === "townValue" && !value) || (name !== "townValue" && !townValue))
        || ((name === "address" && !value) || (name !== "address" && !address))
        || ((name === "tel" && !value) || (name !== "tel" && !tel))) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
    } else {
      if (((name === "sirName" && !value) || (name !== "sirName" && !sirName))
        || ((name === "sirNameKana" && !value) || (name !== "sirNameKana" && !sirNameKana))
        || ((name === "firstName" && !value) || (name !== "firstName" && !firstName))
        || ((name === "firstKana" && !value) || (name !== "firstKana" && !firstKana))
        || ((name === "tel" && !value) || (name !== "tel" && !tel))) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
    }
  }

  const handleChange = (event) => {
    let v;
    switch (event.target.name) {
      case 'tel':
        setTel(event.target.value);
        v = event.target.value;
        if (telV) {
          dataLayer.push({ 'event': "telV" });
          setTelV(false);
        }
        break;
      case 'prefecture':
        setPrefecture(event.target.value);
        v = event.target.value;
        break;
      case 'cityValue':
        setCityValue(event.target.value);
        setTownSetValue(event.target.value);
        v = event.target.value;
        break;
      case 'townValue':
        setTownValue(event.target.value);
        v = event.target.value;
        break;

      default:
        break
    }
    checkEmpty(event.target.name, v);
  };

  const checkEnterEvent = (e) => {
    if (e.nativeEvent.inputType == 'deleteCompositionText') { return true } else { return false };
  }
  const inputChange = (event) => {
    let v;

    switch (event.target.name) {
      case 'sirName':
        setSirName(event.target.value)
        v = event.target.value;
        if (!checkEnterEvent(event)) {
          setLastNameKanaSource(v);
          setSirNameKana(lastNameKana);
        }
        if (sirNameV) {
          dataLayer.push({ 'event': "sirNameV" });
          setSirNameV(false);
        }
        break;
      case 'sirNameKana':
        setSirNameKana(event.target.value)
        v = event.target.value;
        if (sirNameKanaV) {
          dataLayer.push({ 'event': "sirNameKanaV" });
          setSirNameKanaV(false);
        }
        break;
      case 'firstName':
        setFirstName(event.target.value)
        v = event.target.value;
        if (!checkEnterEvent(event)) {
          setFirstNameKanaSource(v);
          setFirstNameKana(firstNameKana);
        }
        if (firstNameV) {
          dataLayer.push({ 'event': "firstNameV" });
          setFirstNameV(false);
        }
        break;
      case 'firstKana':
        setFirstNameKana(event.target.value);
        v = event.target.value;
        if (firstKanaV) {
          dataLayer.push({ 'event': "firstKanaV" });
          setFirstNameKanaV(false);
        }
        break;
      case 'address':
        setAddress(event.target.value);
        v = event.target.value;
        if (addressV) {
          dataLayer.push({ 'event': "addressV" });
          setAddressV(false);
        }
        break;
      default:
        break;
    }
    checkEmpty(event.target.name, v);
    console.log("fire", buttonFlg);

  }


  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'finish3' }));
  }
  const telNumberCheck = (value) => {
    if (value.match(/^0[5789]0\d{8}$/)) {
      return true;
    } else if (value.match(/^0[1-9][1-9][0-9]\d{6}$/)) {
      return true;
    } else {
      return false;
    }
  }
  const onBlurCheck = (event) => {
    event.preventDefault();
    console.log("onBlurCheck");
    var data = [];

    setErrorMsg('');
    if (input4Ref.current === event.relatedTarget ||
      input5Ref.current === event.relatedTarget ||
      input6Ref.current === event.relatedTarget ||
      input7Ref.current === event.relatedTarget ||
      input13Ref.current === event.relatedTarget ) {
      dataLayer.push({ 'event': event.relatedTarget.name });
      setErrorMsg('');
    }
    else if (kyojyu && (
      input9Ref.current === event.relatedTarget ||
      input10Ref.current === event.relatedTarget ||
      input11Ref.current === event.relatedTarget ||
      input13Ref.current === event.relatedTarget ||
      input12Ref.current === event.relatedTarget)) {
      dataLayer.push({ 'event': event.relatedTarget.name });
      setErrorMsg('');
    }
    else {
      if (sirName) { setErrorMsg(''); data = data.concat({ name: 'sir-name', value: sirName }); } else {
        setErrorMsg('※名字が未入力です');
        return false;
      };
      if (firstName) {
        setErrorMsg(''); data = data.concat({ name: 'first-name', value: firstName });
      } else {
        setErrorMsg('※名前が未入力です');
        return false;
      };
      const chackName = {sirname :sirName, firstname :firstName};
      if(shimeiBlackList.some(n => n.sirname === chackName.sirname && n.firstname === chackName.firstname )){
        setErrorMsg('※名前がご利用いただけません');
        return false;
      }else{
        setErrorMsg('');
      }
      if (sirNameKana) {
        if (!sirNameKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'sir-kana', value: sirNameKana }); }
      } else {
        setErrorMsg('※名字（かな）が未入力です');
        return false;
      };
      if (firstKana) {
        if (!firstKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'first-kana', value: firstKana }); }
      } else {
        setErrorMsg('※名前（かな）が未入力です');
        return false;
      };
      const chackKana = {sirKana :sirNameKana, firstKana :firstKana};
      if(kanaBlackList.some(n => n.sirKana === chackKana.sirKana && n.firstKana === chackKana.firstKana )){
        setErrorMsg('※ふりがなに入力した名前がご利用いただけません');
        return false;
      }else{
        setErrorMsg('');
      }
      if (!tel) {
        setErrorMsg('※電話番号が未入力です');
        return false;
      } else if (telNumberCheck(tel) === false) {
        setErrorMsg('※電話番号が不正です');
        return false;
      }else if (telBlacklist.some((n) => tel.includes(n))) {
        setErrorMsg('※電話番号がご利用いただけません');
        return false;
      } else {
        setErrorMsg(''); data = data.concat({ name: 'tel', value: tel });
      };
      if (kyojyu) {
        if (prefecture) { setErrorMsg(''); } else {
          setErrorMsg('※都道府県が未入力です');
          return false;
        };
        if (cityValue) { setErrorMsg(''); } else {
          setErrorMsg('※市区町村が未入力です');
          return false;
        };
        if (townValue) { setErrorMsg(''); } else {
          setErrorMsg('※町域が未入力です');
          return false;
        };
        if (address) { setErrorMsg(''); } else {
          setErrorMsg('※字/丁目以降が未入力です');
          return false;
        };
      }

    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = [];
    if (input13Ref.current === event.relatedTarget ||
      input4Ref.current === event.relatedTarget ||
      input5Ref.current === event.relatedTarget ||
      input6Ref.current === event.relatedTarget ||
      input7Ref.current === event.relatedTarget ) {
        setErrorMsg('');
    }
    else if (kyojyu && (
      input13Ref.current === event.relatedTarget ||
      input9Ref.current === event.relatedTarget ||
      input10Ref.current === event.relatedTarget ||
      input11Ref.current === event.relatedTarget ||
      input12Ref.current === event.relatedTarget)) { setErrorMsg(''); }
    else {

      if (sirName) { setErrorMsg(''); data = data.concat({ name: 'sir-name', value: sirName }); } else {
        setErrorMsg('※名字が未入力です');
        return false;
      };
      if (firstName) {
        setErrorMsg(''); data = data.concat({ name: 'first-name', value: firstName });
      } else {
        setErrorMsg('※名前が未入力です');
        return false;
      };
      const chackName = {sirname :sirName, firstname :firstName};
      if(shimeiBlackList.some(n => n.sirname === chackName.sirname && n.firstname === chackName.firstname )){
        setErrorMsg('※名前がご利用いただけません');
        return false;
      }else{
        setErrorMsg('');
      }
      if (sirNameKana) {
        if (!sirNameKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'sir-kana', value: sirNameKana }); }
      } else {
        setErrorMsg('※名字（かな）が未入力です');
        return false;
      };
      if (firstKana) {
        if (!firstKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'first-kana', value: firstKana }); }
      } else {
        setErrorMsg('※名前（かな）が未入力です');
        return false;
      };
      const chackKana = {sirKana :sirNameKana, firstKana :firstKana};
      if(kanaBlackList.some(n => n.sirKana === chackKana.sirKana && n.firstKana === chackKana.firstKana )){
        setErrorMsg('※ふりがなに入力した名前がご利用いただけません');
        return false;
      }else{
        setErrorMsg('');
      }
      let userAddress = '';
      if (kyojyu) {
        if (prefecture) { setErrorMsg(''); } else {
          setErrorMsg('※都道府県が未入力です');
          return false;
        };
        if (cityValue) { setErrorMsg(''); } else {
          setErrorMsg('※市区町村が未入力です');
          return false;
        };
        if (townValue) { setErrorMsg(''); } else {
          setErrorMsg('※町域が未入力です');
          return false;
        };
        if (address) { setErrorMsg(''); } else {
          setErrorMsg('※字/丁目以降が未入力です');
          return false;
        };
        userAddress = prefecture + cityValue + townValue + address;
      } else {
        //t4ストデータ
        //userAddress = "東京都世田谷区池尻1-11-8"
        //t4ストデータここまで
        const list2 = props.steps.getAgents2.value;
        let prefecture2 = list2.find(a => a.name === 'bukken-address1');
        let cityValue2 = list2.find(a => a.name === 'bukken-address2');
        let townValue2 = list2.find(a => a.name === 'bukken-address3');
        let address2 = list2.find(a => a.name === 'bukken-address4');
        let name2 = list2.find(a => a.name === 'bukken-name');
        let goshitsu2 = list2.find(a => a.name === 'bukken-address5');
        if (prefecture2 !== undefined) { userAddress = userAddress.concat(prefecture2.value) }
        if (cityValue2 !== undefined) { userAddress = userAddress.concat(cityValue2.value) }
        if (townValue2 !== undefined) { userAddress = userAddress.concat(townValue2.value) }
        if (address2 !== undefined) { userAddress = userAddress.concat(address2.value) }
        if (name2 !== undefined) { userAddress = userAddress.concat(' ', name2.value) }
        if (goshitsu2 !== undefined) { userAddress = userAddress.concat(goshitsu2.value) }
      }
      if (userAddress) { data = data.concat({ name: 'address', value: userAddress }) }

      if (!tel) {
        setErrorMsg('※電話番号が未入力です');
        return false;
      } else if (telNumberCheck(tel) === false) {
        setErrorMsg('※電話番号が不正です');
        return false;
      }else if (telBlacklist.some((n) => tel.includes(n))) {
        setErrorMsg('※電話番号がご利用いただけません');
        return false;
      } else {
        setErrorMsg(''); data = data.concat({ name: 'tel', value: tel });
      };
      let jiki = props.steps.bukkenQa2.value.jiki;
      let reson = props.steps.bukkenQa2.value.reson;
      //テストデータ
      // let jiki = "いつでも";
      // let reson = "売却のため";
      //テストデータここまで
      if (jiki) { data = data.concat({ name: 'jiki', value: jiki }) };
      if (reson) { data = data.concat({ name: 'reson', value: reson }); };

      props.step.value = data;
      triggerNext();
    }
  };


  useEffect(() => {
    const list = props.steps.getAgents2.value;
    let genkyo = list.find(a => a.name === 'bukken-state');
    //テストデータここから
    // let genkyo = '居住中';
    //テストデータここまで
    if (genkyo.value !== '居住中') {
      setKyojyu(true);
    };
    dataLayer.push({ 'event': "sirNameFocus" });
  }, []);



  return (
    <form className="formCss2 contactInfo back-white-wrapper" onSubmit={handleSubmit}>
      <label>氏名：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="名字" type="text" name="sirName" value={sirName} className="sirnameInput" onChange={inputChange} onBlur={onBlurCheck} ref={input4Ref} autoFocus />
        <input placeholder="名前" type="text" name="firstName" id="firstName" className="firstnameInput" value={firstName} onChange={inputChange} onBlur={onBlurCheck} ref={input5Ref} /></label><br></br>
      <label>ふりがな:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="みょうじ" type="text" name="sirNameKana" className="sirnameInput" value={sirNameKana} onChange={inputChange} onBlur={onBlurCheck} ref={input6Ref} />
        <input placeholder="なまえ" type="text" name="firstKana" className="firstnameInput" value={firstKana} onChange={inputChange} onBlur={onBlurCheck} ref={input7Ref} /></label><br></br>
      <p className='errMsg'>{kanaError}</p>
      {kyojyu && <>
        <label>都道府県
          <select className="ownerTodofuken" value={prefecture} onChange={handleChange} name="prefecture" defaultValue="" onBlur={onBlurCheck} ref={input9Ref}>
            <option value='' disabled>都道府県を選択して下さい</option>
            {prefectures?.map(d => { return <><option value={d}>{d}</option></> })}
          </select>
        </label><br></br>
        <label>市区町村：
          <select className="ownerShikucyoson" value={cityValue} onChange={handleChange} defaultValue="" name='cityValue' onBlur={onBlurCheck} ref={input10Ref}>
            <option value='' disabled>市区町村を選択して下さい</option>
            <Suspense fallback={<p>Loading..</p>}>
              <SetCities property={prefecture}></SetCities>
            </Suspense>
          </select>
        </label><br></br>
        <label>
          町域：
          <select className="ownerChoiki" value={townValue} onChange={handleChange} defaultValue="" name='townValue' onBlur={onBlurCheck} ref={input11Ref}>
            <option value='' disabled>町域を選択して下さい</option>
            <Suspense fallback={<p>Loading..</p>}>
              <SetTowns prefecture={prefecture} townProperty={townSetValue}></SetTowns>
            </Suspense>
          </select>
        </label><br></br>
        <label>字/丁目以降：<input className="ownerAddress" type="text" name="address" value={address} onChange={inputChange} onBlur={onBlurCheck} ref={input12Ref} /></label><br></br>
      </>}


      <label>電話番号：※ハイフン無し半角数字<input className='tel' placeholder="" type="tel" name="tel" value={tel} onChange={handleChange} onBlur={onBlurCheck} ref={input13Ref} /></label>
      <br></br>
      <p className='errMsg'>{errorMessage}</p>
      {buttonFlg && <button className="btn btn-success" formNoVlidate>
        次へ
      </button>}

    </form>
  );
}