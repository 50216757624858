import React, { Suspense, useState, useEffect } from 'react';
import { useGenkyoContext } from '../context/contexts';

export default function SaiKento(props) {
    const [trigger, setTrigger] = useState(false);
    const type = [{ value: "はい", label: "はい" }, { value: "いいえ", label: "いいえ" }];
    const { kentoState, setKentoState } = useGenkyoContext();
    const [enable, setEnable] = useState(true);
    const triggerNext = (event) => {
        props.step.value = event.target.value;
        if (event.target.value == "はい") {
            props.steps.bukkenQa2.value.reason = '価格によって売却を検討したいから';
            setKentoState(2);
            if (props.steps.hasOwnProperty('finish1') !== true ) {
                setEnable(false);
                setTrigger(true, props.triggerNextStep({ trigger: 'finish1' }))
            }else{
                setEnable(false);
            }
        } else {
            setEnable(false);
            setTrigger(true, props.triggerNextStep({ trigger: 'suspendToend' }))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return (<>
        {/* <form onSubmit={handleSubmit}> */}
        {enable &&
        <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper genkyoSelect'>
            <div className="bukken-type-intro"><p className='color-white'>大変申し訳ありません。イエカカクは売却意思のある方を対象としており、査定金額の算出のみの為にご利用いただけません<br></br>
            査定結果を知って、売却を検討する可能性はありますか？
            </p></div>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options saiKentoSelect genkyoSelect'>
                {type?.map(e => {
                    return <><li className='sc-htpNat GgOGn rsc-os-option'>
                        <button onClick={handleSubmit}/*type="submit"*/ className='saikentoButton sc-bxivhb ipSaXD rsc-os-option-element' value={e.value}>{e.label}</button></li></>
                })}
            </ul></div>
}
        {/* </form> */}
    </>

    );
};
