import React, {Suspense, useState, useEffect } from 'react';

export default function Todofuken0(props){
    const [trigger, setTrigger] = useState(false);
    const [todofuken, setTodofuken] = useState();

    const hokkaidoTohoku = [{ value: '北海道', label: '北海道', trigger: 'todofukenMessage' },
    { value: '青森県', label: '青森県', trigger: 'todofukenMessage' },
    { value: '岩手県', label: '岩手県', trigger: 'todofukenMessage' },
    { value: '宮城県', label: '宮城県', trigger: 'todofukenMessage' },
    { value: '秋田県', label: '秋田県', trigger: 'todofukenMessage' },
    { value: '山形県', label: '山形県', trigger: 'todofukenMessage' },
    { value: '福島県', label: '福島県', trigger: 'todofukenMessage' },];
    const kanto = [{ value: '茨城県', label: '茨城県', trigger: 'todofukenMessage' },
    { value: '栃木県', label: '栃木県', trigger: 'todofukenMessage' },
    { value: '群馬県', label: '群馬県', trigger: 'todofukenMessage' },
    { value: '埼玉県', label: '埼玉県', trigger: 'todofukenMessage' },
    { value: '千葉県', label: '千葉県', trigger: 'todofukenMessage' },
    { value: '東京都', label: '東京都', trigger: 'todofukenMessage' },
    { value: '神奈川県', label: '神奈川県', trigger: 'todofukenMessage' },];
    const chubu = [
              { value: '新潟県', label: '新潟県', trigger: 'todofukenMessage' },
              { value: '富山県', label: '富山県', trigger: 'todofukenMessage' },
              { value: '石川県', label: '石川県', trigger: 'todofukenMessage' },
              { value: '福井県', label: '福井県', trigger: 'todofukenMessage' },
              { value: '山梨県', label: '山梨県', trigger: 'todofukenMessage' },
              { value: '長野県', label: '長野県', trigger: 'todofukenMessage' },
              { value: '岐阜県', label: '岐阜県', trigger: 'todofukenMessage' },
              { value: '静岡県', label: '静岡県', trigger: 'todofukenMessage' },
              { value: '愛知県', label: '愛知県', trigger: 'todofukenMessage' },
            ];
    const kinki = [
      { value: '三重県', label: '三重県', trigger: 'todofukenMessage' },
      { value: '滋賀県', label: '滋賀県', trigger: 'todofukenMessage' },
      { value: '京都府', label: '京都府', trigger: 'todofukenMessage' },
      { value: '大阪府', label: '大阪府', trigger: 'todofukenMessage' },
      { value: '兵庫県', label: '兵庫県', trigger: 'todofukenMessage' },
      { value: '奈良県', label: '奈良県', trigger: 'todofukenMessage' },
      { value: '和歌山県', label: '和歌山県', trigger: 'todofukenMessage' },
    ];
    const chugokuShikoku = [
      { value: '鳥取県', label: '鳥取県', trigger: 'todofukenMessage' },
      { value: '島根県', label: '島根県', trigger: 'todofukenMessage' },
      { value: '岡山県', label: '岡山県', trigger: 'todofukenMessage' },
      { value: '広島県', label: '広島県', trigger: 'todofukenMessage' },
      { value: '山口県', label: '山口県', trigger: 'todofukenMessage' },
      { value: '徳島県', label: '徳島県', trigger: 'todofukenMessage' },
      { value: '香川県', label: '香川県', trigger: 'todofukenMessage' },
      { value: '愛媛県', label: '愛媛県', trigger: 'todofukenMessage' },
      { value: '高知県', label: '高知県', trigger: 'todofukenMessage' },
    ];
    const kyushu = [
      { value: '福岡県', label: '福岡県', trigger: 'todofukenMessage' },
      { value: '佐賀県', label: '佐賀県', trigger: 'todofukenMessage' },
      { value: '長崎県', label: '長崎県', trigger: 'todofukenMessage' },
      { value: '熊本県', label: '熊本県', trigger: 'todofukenMessage' },
      { value: '大分県', label: '大分県', trigger: 'todofukenMessage' },
      { value: '宮崎県', label: '宮崎県', trigger: 'todofukenMessage' },
      { value: '鹿児島県', label: '鹿児島県', trigger: 'todofukenMessage' },
      { value: '沖縄県', label: '沖縄県', trigger: 'todofukenMessage' },
    ];
    const triggerNext = (event) =>{ 
        props.step.value = event.target.value;
        setTrigger(true, props.triggerNextStep({trigger: 'todofukenMessage'}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    useEffect(() => {
        const area = props.steps.area.value;
        if(area !== ''){
            switch(area){
                case '北海道・東北地方':
                    setTodofuken(hokkaidoTohoku);
                    break;
                case '関東地方':
                    setTodofuken(kanto);
                    break;
                case '中部地方':
                    setTodofuken(chubu);
                    break;
                case '近畿地方':
                    setTodofuken(kinki);
                    break;
                case '中国・四国地方':
                    setTodofuken(chugokuShikoku);
                    break;
                case '九州地方':
                    setTodofuken(kyushu);
                    break;
                default:
                    break;
            }
        }
    },[props.steps.area.value]);
    return(<>
        {/* <form onSubmit={handleSubmit}> */}
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper todofukenSelect'>
           <ul className='sc-ifAKCX gkhNlr rsc-os-options todofukenSelect'>
           {todofuken?.map( e =>{return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element todofuken-button' value={e.value}>{e.label}</button></li></>})}
                </ul></div>
                {/* </form> */}
                </>

    );
};