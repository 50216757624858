import React, {Suspense, useState, useEffect } from 'react';
import {mailBlacklist} from './blacklist';
// const checkLists = [
//   "いつでも",
//   "平日",
//   "祝日",
//   "月曜日",
//   "火曜日",
//   "水曜日",
//   "木曜日",
//   "金曜日",
//   "土曜日",
//   "日曜日",
// ];
// const CheckBox = ({id, value, checked, onChange}) => {
//   return (
//     <input
//       id={id}
//       type="checkbox"
//       name="inputNames"
//       checked={checked}
//       onChange={onChange}
//       value={value}
//     />
//   )
// }
// const times = ['いつでも','10時〜12時','12時〜13時','13時〜15時','15時〜18時','18時〜21時'];
export default function Contact1(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [mail, setMail] = useState();
  //const [tel, setTel] = useState();
  const [mailError, setMailError] = useState();
  // const [telError, setTelError] = useState();
  // const [checkedItems, setCheckedItems] = useState({});
  // const [time, setTime] = useState(checkLists[0]);
  const [activityChanged, setActivityChanged] = useState(false); 
  const { dataLayer } = window;
  const handleChange = (event) => {
    dataLayer.push({'event': event.target.name});
  switch(event.target.name){
    case 'mail':
    setMail(event.target.value);
    break;
    // case 'tel':
    // setTel(event.target.value);
    //   break;
    // case 'time':
    //   setTime(event.target.value);
    //     break;
    default:
      break
  }
  };
  const triggerNext = (event) =>{
    //setTrigger(props.triggerNextStep())
    setTrigger(true, props.triggerNextStep({trigger: 'contactInfo'}));
  }
  // const telNumberCheck = (value) =>{
  //   if (value.match(/^0[5789]0\d{8}$/)){
  //     return true;
  //   }else if(value.match(/^0[1-9][1-9][0-9]\d{6}$/)){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }
  const onBlurFunc = (e) => {
    if(e.target.name ===  'mail'){
      if(!e.target.value.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
        setMailError('※メールアドレスが不正です');
      }else if (mailBlacklist.some((email) => mail.includes(email))) {
        setMailError('※メールアドレスがご利用いただけません');
        return false;
      }else{
        setMailError('');
      }
    // }else if(e.target.name === 'tel'){
    //   if(telNumberCheck(e.target.value) === false){
    //     setTelError('※電話番号が不正です');
    //   }else{
    //     setTelError('');
    //   }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = [];
    if(!mail){
      setErrorMsg('※メールアドレスが未入力です');
      return false; 
    }else if(!mail.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
      setErrorMsg('※メールアドレスが不正です');
      return false;
    }else if (mailBlacklist.some((email) => mail.includes(email))) {
      setMailError('※メールアドレスがご利用いただけません');
      return false;
    }else{
      setErrorMsg(''); data = mail;
      const domain = mail.split('@')[1];
    console.log('domain',domain);
    var jsonData = JSON.stringify({'domain' : domain});
    console.log('jsonData',jsonData);
    fetch("/checkmx",{
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: jsonData
    })
    .then(res => res.json()) 
    .then((res) => {
      if(res.hasOwnProperty('error')){
      console.log('送信失敗1',res);
      setErrorMsg('※送信できないメールアドレスです');
      return false;
      }else{
        console.log('成功！',res);
        setErrorMsg('');
        props.step.value = data;

        console.log('props.step.value',props.step.value);
        triggerNext();
        return true;
      };
    })
    .catch((err) => {
      console.log('エラー',err);
    });
    };
    // props.step.value = data;
    // triggerNext();
    
  };
  return (
    <form onSubmit={handleSubmit} className="formCss2 mailInfo back-white-wrapper">
        <label>メールアドレス：<input type="mail" name="mail" value={mail} onChange={handleChange} onBlur={onBlurFunc}/>
        </label><br></br>
        <p className='errMsg'>{mailError}</p>
       
      <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success" disabled ={(activityChanged)?true:false}>
            次へ
            </button>
      </form>
    );
  }