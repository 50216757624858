import React, { Component, Suspense, useState, useEffect } from 'react';

export default function BukkenQa2(props) {
  const [trigger, setTrigger] = useState(false);
  const [madoriFlg, setMadoriFlg] = useState(false);
  const [chikunenFlg, setChikuneFlg] = useState(false);
  const [madoriSize, setMadoriSize] = useState();
  const [chikuYear, setChikuYear] = useState();

  const [genkyo, setGenkyo] = useState();
  const [relation, setRelation] = useState();
  const [jiki, setJiki] = useState();
  const [reason, setReason] = useState();
  const [genkyoFlg, setGenkyoFlg] = useState(false);
  const [genkyoListFlg, setGenkyoListFlg] = useState(false);
  const [relationListFlg, setRelationListFlg] = useState(false);
  const [jikiListFlg, setJikiListFlg] = useState(false);
  const [reasonListFlg, setReasonListFlg] = useState(false);
  const [datas, setDatas] = useState({});

 
  const genkyoList = [{value:'居住中', label:'居住中'},{value:'賃貸中', label:'賃貸中'},{value:'空室', label:'空室'},{value:'その他', label:'その他'}];
const relationLists = [{value:'所有者本人', label:'所有者本人'},{value:'共有名義で所有', label:'共有名義で所有'},{value:'家族・親族が所有', label:'家族・親族が所有'},{value:'所有者の了承を得た代理人', label: '所有者の了承を得た代理人'}];
const jikiLists = [{value:'できるだけ早く', label:'できるだけ早く'},{value:'3ヶ月以内', label:'3ヶ月以内'},{value:'6ヶ月以内', label:'6ヶ月以内'},{value:'１年以内', label: '１年以内'},{value:'１年以上', label:'１年以上'}];
const reasonList = [{value:'売却のため', label:'売却のため'},{value:'価格によって売却を検討したいから', label:'価格によって売却を検討したいから'},{value:'住み替えのため', label:'住み替えのため'},{value:'転勤のため', label:'転勤のため'},{value:'資産整理のため', label:'資産整理のため'},{value:'離婚のため', label:'離婚のため'},{value:'住宅ローンで困ってる', label:'住宅ローンで困ってる'},{value:'相続のため', label:'相続のため'},{value:'税金（固定資産税・相続税など）算出のため', label:'税金（固定資産税・相続税など）算出のため'},{value:'その他', label:'その他'}];

  const popupModal = (event) => {
    switch(event.target.name){
      case"genkyoInput":
       setGenkyoListFlg(true);
      break;
      case"relationInput":
      setRelationListFlg(true);
      break;
      case"jikiInput":
      setJikiListFlg(true);
      break;
      case"reasonInput":
      setReasonListFlg(true);
      break;
      default:
        break;
    }
  }
  const triggerNext = (event) => {
    if(event === '税金（固定資産税・相続税など）算出のため'){
      setTrigger(true, props.triggerNextStep({trigger: 'kento'}))}
      else if(reason === 'その他'){
        setTrigger(true, props.triggerNextStep({trigger: 'kento'}))}
       //setTrigger(true, props.triggerNextStep({trigger: 'getAgents'}))}//test
     else{
   setTrigger(true, props.triggerNextStep({trigger: 'finish1'}))
   }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    switch (event.target.className) {
      case "genkyoButtons":
        setGenkyo(event.target.value)
        if(datas.hasOwnProperty("genkyo")){datas["genkyo"] = event.target.value}else{
        setDatas({...datas, 
        "genkyo" : event.target.value})}
        setGenkyoListFlg(false);
        setTimeout(() => {
          setRelationListFlg(true);
        }, "500")
        break;
        case "relationButtons":
          setRelation(event.target.value)
          if(datas.hasOwnProperty("relation")){datas["relation"] = event.target.value}else{
          setDatas({...datas, 
            "relation" : event.target.value})}
          setRelationListFlg(false);
          setTimeout(() => {
            setJikiListFlg(true);
          }, "500")
          break;
          case "jikiButtons":
            setJiki(event.target.value)
            if(datas.hasOwnProperty("jiki")){datas["jiki"] = event.target.value}else{
            setDatas({...datas, 
              "jiki" : event.target.value})}
            setJikiListFlg(false);
            setTimeout(() => {
              setReasonListFlg(true);
            }, "500")
            break;
      case "reasonButtons":
        setReason(event.target.value);
        if(datas.hasOwnProperty("reason")){datas["reason"] = event.target.value;
        const data = datas;
        props.step.value = data;
        setReasonListFlg(false);
        triggerNext(event.target.value);
          }else{
          const data = {...datas, "reason": event.target.value};
        setDatas(data);
        props.step.value = data;
        setReasonListFlg(false);
        triggerNext(event.target.value);
        }
        
        break;
      default:
        break;
    }
    //
  };
  const inputChange = (event) => {
      switch (event.target.name) {
        case 'genkyo':
          setGenkyo(event.target.value);
        break;
        case 'relation':
          setRelation(event.target.value);
        break;
        case 'jiki':
          setJiki(event.target.value);
          break;
        case 'reason':
          setReason(event.target.value);
            break;
        default:
          break;
      }
  }
  useEffect(() => {
    if(props.steps.genkyoSelect.value !== '住んでいます'){
      setGenkyoFlg(true);
      setGenkyoListFlg(true);
    }else{
      setRelationListFlg(true);
    }
  },[]);

  const modalOverlay = (e) => {
    const backgroundDiv = document.getElementsByClassName('overley');
    const bubble = document.getElementsByClassName('rsc-ts-bubble');
    const backWhite = document.getElementsByClassName('back-white-wrapper');
    const rscContent = document.getElementsByClassName('rsc-content');
    const operator = document.getElementsByClassName('rsc-ts-image');
    console.log("operator",operator);
    if(e === true){
      backgroundDiv[0].style.display = "unset";
      rscContent[0].style.overflowY = "hidden";
      Object.keys(bubble).forEach(e => { 
        bubble[e].style.opacity = "0.1";
      });
      Object.keys(operator).forEach(e => { 
        operator[e].style.opacity = "0.1";
      });
      Object.keys(backWhite).forEach(e => { 
        if(backWhite[e].className.indexOf('bukkenQa2') !== -1){
          backWhite[e].style.backgroundColor = "transparent";
        }else{
            backWhite[e].style.opacity = "0.1";
        }
      });
    }else{
    backgroundDiv[0].style.display = "none";
      operator[0].style.opacity = "1";
      rscContent[0].style.overflowY = "scroll";
      Object.keys(bubble).forEach(e => { 
            bubble[e].style.opacity = "1";
      });
      Object.keys(operator).forEach(e => { 
        operator[e].style.opacity = "1";
      });
      Object.keys(backWhite).forEach(e => { 
        if(backWhite[e].className.indexOf('bukkenQa2') !== -1){
          backWhite[e].style.backgroundColor = "#fff";
        }else{
            backWhite[e].style.opacity = "1";
        }
      });
    }
  }
useEffect(() =>  {
  modalOverlay(genkyoListFlg);
}, [genkyoListFlg]);
useEffect(() =>  {
  modalOverlay(relationListFlg);
}, [relationListFlg]);
useEffect(() =>  {
  modalOverlay(jikiListFlg);
}, [jikiListFlg]);
useEffect(() =>  {
  modalOverlay(reasonListFlg);
}, [reasonListFlg]);


  return (

    <form className="formCss1 bukkenQa2 modalForm back-white-wrapper">
      
      {genkyoFlg && <><label>物件の状況：<input onFocus={popupModal} type="text" name="genkyoInput" value={genkyo} onChange={inputChange} />
      </label><br></br>
      </>
      }
      {genkyoListFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
            <div className="modal-header"><p>現在の状況を選択してください</p></div>
            <div className='modal-box'>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
              {genkyoList?.map(e => {
                return <><li className='sc-htpNat GgOGn rsc-os-option'>
                  <button onClick={handleSubmit} className='genkyoButtons' value={e.value}>{e.label}</button></li></>
              })}
            </ul>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>}
      <label>物件との関係:<input onFocus={popupModal} type="text" name="relationInput" value={relation} onChange={inputChange} />
      </label><br></br>
      {relationListFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
            <div className="modal-header"><p>物件との関係を選択してください</p></div>
            <div className='modal-box'>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
              {relationLists?.map(e => {
                return <><li className='sc-htpNat GgOGn rsc-os-option whide-li'>
                  <button onClick={handleSubmit} className='relationButtons' value={e.value}>{e.label}</button></li></>
              })}
            </ul>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>}
      <label>売却希望時期:<input onFocus={popupModal} type="text" name="jikiInput" value={jiki} onChange={inputChange} />
      </label><br></br>
      {jikiListFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
          <div className="modal-header"><p>売却希望時期を選択してください</p></div>
            <div className='modal-box'>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
              {jikiLists?.map(e => {
                return <><li className='sc-htpNat GgOGn rsc-os-option'>
                  <button onClick={handleSubmit} className='jikiButtons' value={e.value}>{e.label}</button></li></>
              })}
            </ul>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>}
      <label>査定依頼の理由:<input onFocus={popupModal} type="text" name="reasonInput" value={reason} onChange={inputChange} />
      </label><br></br>
      {reasonListFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
          <div className="modal-header"><p>査定依頼の理由を選択してください</p></div>
            <div className='modal-box'>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
              {reasonList?.map(e => {
                return <><li className='sc-htpNat GgOGn rsc-os-option whide-li'>
                  <button onClick={handleSubmit} className='reasonButtons' value={e.value}>{e.label}</button></li></>
              })}
            </ul>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>}
    </form>
  );
}

