import React from 'react';
import './App.css';
//import { useState,useEffect } from 'react';
import { Route, BrowserRouter, Routes,  } from 'react-router-dom';
import { Home } from './components/home';
import { Lp1n1 } from './components/lp1_1';
import { Partner } from './components/partner';
import ChatFormTest from './components/chatform_test';
import ChatForm0 from './components/chatform0';
import ChatForm0Lp from './components/chatform_0lp';
import ChatFormLp1n1 from './components/chatform_lp1_1';
import ChatForm0Y from './components/chatform_0y';
import ChatForm0Ylp from './components/chatform_0_ylp';
import ChatForm0Ln from './components/chatform_0ln';
import ChatForm0LnLp from './components/chatform_0_lnlp';
import ChatForm from './components/chatform1';
import ChatFormA from './components/chatform_a';
import ChatFormF from './components/chatform_f';
import ChatFormMt001 from './components/chatform_mt001';
import ChatFormJm001 from './components/chatform_jm001';
import ChatFormIm from './components/chatform_im';
import ChatFormRn from './components/chatform_rn';
import ChatFormRn2 from './components/chatform_rn2';
import ChatFormCol1 from './components/chatform_col1';
// import { ContextProvider } from './components/context/contexts';


function App() {
  // const [message, setMessage] = useState('');
  // useEffect(() =>{
  // fetch('/api')
  //     .then((res) => res.json())
  //     .then((data) => setMessage(data.message));
  // },[])
  return (
    <div>
      {/* <ContextProvider> */}
      <BrowserRouter>
        <Routes>
        <Route exact path='/' element={<Home />}/>
        <Route exact path='/lp1_1' element={<Lp1n1 />}/>
        <Route exact path='/partner' element={<Partner />}/>
        <Route exact path='/test' element={<ChatFormTest />}/>{/* /テスト用パス */}
        <Route exact path='/chat0' element={<ChatForm0 />}/>{/* /org */}
        <Route exact path='/chat_lp1_1' element={<ChatFormLp1n1 />}/>{/* /iekakaku.jp/lp1_1から流入のchatform */}
        <Route exact path='/chat_0lp' element={<ChatForm0Lp />}/>{/* /org */}
        <Route exact path='/chat_0y' element={<ChatForm0Y />}/>{/* /org */}
        <Route exact path='/chat_0_ylp' element={<ChatForm0Ylp />}/>{/* /org */}
        <Route exact path='/chat_0ln' element={<ChatForm0Ln />}/>{/* /org */}
        <Route exact path='/chat_0_lnlp' element={<ChatForm0LnLp />}/>{/* /org */}
        <Route exact path='/chat1' element={<ChatForm />}/>{/* フェルマ */}
        <Route exact path='/chat_a' element={<ChatFormA />}/>{/* フェルマLPから */}
        <Route exact path='/chat_f' element={<ChatFormF />}/>{/* フェルマxねこ 新フォーム20231214 */}
        <Route exact path='/chat_mt001' element={<ChatFormMt001 />}/>{/* フェルマxねこ Meta広告フォーム20241104 */}
        <Route exact path='/chat_jm001' element={<ChatFormJm001 />}/>{/* フェルマジモティー広告フォーム20241213 */}
        <Route exact path='/chat_im' element={<ChatFormIm />}/>{/* フェルマxIm */}
        <Route exact path='/chat_rn1' element={<ChatFormRn />}/>{/* レントラックス */}
        <Route exact path='/chat_rn2' element={<ChatFormRn2 />}/>{/* レントラックスLPから */}
        <Route exact path='/chat_col1' element={<ChatFormCol1 />}/>{/* コラムからから1 */}
        </Routes>
        </BrowserRouter>
        {/* </ContextProvider> */}
    </div>
  );
}

//export default BrowserRouter(App);
//export default wituRouter(App);
export default App;