import React, {Suspense, useState, useEffect } from 'react';

export default function Kento(props){
    const [trigger, setTrigger] = useState(false);
    const type = [{value:"はい", label:"はい"},{value:"いいえ", label:"いいえ"}];
    const triggerNext = (event) =>{
         props.step.value = event.target.value;
        if(event.target.value == "はい"){
            setTrigger(true, props.triggerNextStep({trigger: 'finish1'}))
        }else{
            setTrigger(true, props.triggerNextStep({trigger: 'suspend'}))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return(<>
        {/* <form onSubmit={handleSubmit}> */}
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper genkyoSelect'>
           <ul className='sc-ifAKCX gkhNlr rsc-os-options kentoSelect genkyoSelect'>
           {type?.map( e =>{return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit}/*type="submit"*/ className='kentoButton sc-bxivhb ipSaXD rsc-os-option-element' value={e.value}>{e.label}</button></li></>})}
                </ul></div>
                {/* </form> */}
                </>

    );
};