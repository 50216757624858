import React, { Suspense, useState, useEffect } from 'react';
export default function BukkenType(props) {
    const [trigger, setTrigger] = useState(false);
    const [typeFlg, setTypeFlg] = useState(true);
    const [modalFlg, setModalFlg] = useState();
    const bgColor = typeFlg ? "enabled" : "disabled"
    const [color, setColor] = useState("borderColor: #434343, borderWidth: 2px");
    const triggerNext = (event) => {
        props.step.value = event.target.name;
        if (event.target.name == "その他") {
            setTrigger(true, props.triggerNextStep({ trigger: 'other' }))
        } else {
            setTrigger(true, props.triggerNextStep({ trigger: 'bukkenTypeAnswer' }))
        }
        setTypeFlg(false);
    }
    const enterEvent = (event) => {
        console.log("enterEvent", event.target.style);
        event.target.style.borderColor = "blue";
        event.target.style.borderWidth = "2px";
    }
    const leaveEvent = (event) => {
        console.log("leaveEvent", event.target.style);
        event.target.style.borderColor = "#434343";
        event.target.style.borderWidth = "1px";
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return (<>{typeFlg &&
        <div className="back-orange-wrapper">
            {/* <form onSubmit={handleSubmit}> */}

            <div className="bukken-type-intro"><p>査定価格を知りたい物件の<br></br>種類を選択してください</p></div>
            <div className='sc-EHOje jvzENE rsc-os bukken-type-box'>
                <ul className='sc-ifAKCX gkhNlr rsc-os-options bukken-type'>
                    <li className='sc-htpNat GgOGn rsc-os-option'>
                        <button onClick={handleSubmit} className='sc-bxivhb ipSaXD rsc-os-option-element bukken-type-mansion bukken-type-button' name='マンション' value='マンション'><img src='./assets/img/mansion.png' name='マンション' alt='マンション' />マンション</button>
                    </li>
                    <li className='sc-htpNat GgOGn rsc-os-option'>
                        <button onClick={handleSubmit} className='sc-bxivhb ipSaXD rsc-os-option-element bukken-type-button' name='戸建て'><img src="./assets/img/kodate.png" name="戸建て" alt='戸建て' />戸建</button>
                    </li>
                    <li className='sc-htpNat GgOGn rsc-os-option'>
                        <button onClick={handleSubmit} className='sc-bxivhb ipSaXD rsc-os-option-element bukken-type-button' name='土地'><img src="./assets/img/tochi.png" name="土地" alt='土地' />土地</button>
                    </li>
                </ul></div>

            <button onClick={handleSubmit} className='sc-bxivhb ipSaXD rsc-os-option-element others-button bukken-type-button' name='その他'>その他 ▶</button>
            {/* </form> */}
        </div>
    }
    </>

    );
};