import React, { Suspense, useState, useEffect, useRef } from 'react';
import SetCities from './setCity';
import SetTowns from './setTowns';

export default function AddressDetail(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [prefecture, setPrefecture] = useState();
  const [cityValue, setCityValue] = useState();
  const [townValue, setTownValue] = useState();
  const [streetNumber, setStreetNumber] = useState();
  const [propertyName, setPropertyName] = useState();
  const [goshitsu, setGoshitsu] = useState();
  const [goshitsuFlg, setGoshitsuFlg] = useState(false);
  const [propertyNameFlg, setPropertyNameFlg] = useState(false);
  const [buttonFlg, setButtonFlg] = useState(false);
  const [formNumber, setFormNUmber] = useState(0);
  const [formCheck, setFormCheck] = useState(0);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const [streetNumberV, setStreetNumberV] = useState(true);
  const [propertyNameV, setPropertyNameV] = useState(true);
  const [goshitsuV, setGoshitsuV] = useState(true);
  const { dataLayer } = window;
  const [disabled, setDisabled] = useState(false);
  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'genkyo' }));
    setDisabled(true);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!streetNumber || streetNumber.match(/^[ 　\r\n\t]*$/)) {
      setErrorMsg('※住所番地が未入力です');
      return false;
    } else { setErrorMsg(''); };
    var address = { 'bukken_address4' : streetNumber };
    if (propertyNameFlg && (!propertyName || propertyName.match(/^[ 　\r\n\t]*$/))) {
      setErrorMsg('※物件名が未入力です');
      return false;
    }
    else {
      setErrorMsg(''); address['bukken_name'] = propertyName;
    }
    if (goshitsu) { setErrorMsg(''); address['bukken_address5'] = goshitsu; } else if (goshitsuFlg && !goshitsu) {
      setErrorMsg('※号室が未入力です');
      return false;
    }
    props.step.value = address;
    if (disabled === false) {
      triggerNext();
    }
  };

  const inputChange = (event) => {
    switch (event.target.name) {
      case 'streetNumber':
        if (!event.target.value || event.target.value.match(/^[ 　\r\n\t]*$/)) { } else { }
        setStreetNumber(event.target.value);
        const st = event.target.value;
        if ((st !== '' && st !== void 0) && (!propertyNameFlg || (propertyName !== '' && propertyName !== void 0)) && (!goshitsuFlg || (goshitsu !== '' && goshitsu !== void 0))) {
          setButtonFlg(true);
          setErrorMsg('');
        } else {
          setButtonFlg(false);
        }
        if (streetNumberV) {
          dataLayer.push({ 'event': "streetNumberV" });
          setStreetNumberV(false);
        }
        break;
      case 'propertyName':
        setPropertyName(event.target.value)
        const pr = event.target.value;
        if ((streetNumber !== '' && streetNumber !== void 0) && (!propertyNameFlg || (pr !== '' && pr !== void 0)) && (!goshitsuFlg || (goshitsu !== '' && goshitsu !== void 0))) {
          setButtonFlg(true);
          setErrorMsg('');
        } else {
          setButtonFlg(false);
        }
        if (propertyNameV) {
          dataLayer.push({ 'event': "propertyNameV" });
          setPropertyNameV(false);
        }
        break;
      case 'goshitsu':
        setGoshitsu(event.target.value)
        const go = event.target.value;
        if ((streetNumber !== '' && streetNumber !== void 0) && (!propertyNameFlg || (propertyName !== '' && propertyName !== void 0)) && (!goshitsuFlg || (go !== '' && go !== void 0))) {
          setButtonFlg(true);
          setErrorMsg('');
        } else {
          setButtonFlg(false);
        }
        if (goshitsuV) {
          dataLayer.push({ 'event': "goshitsuV" });
          setGoshitsuV(false);
        }
        break;
      default:
        break;
    }
  }
  const onFocusCheck = (event) =>{
    event.preventDefault();
    dataLayer.push({ 'event': event.target.name });
  }
  const onBlurCheck = (event) => {
    event.preventDefault();
    if (disabled === true && event.target.value === '') {
      console.log("event", event);
      console.log("event.target.name", event.target.name);
      switch (event.target.name) {
        case 'streetNumber':
          console.log("streetNumber", event.target.name);
          alert('住所番地が入力されていません');
          input1Ref.current.focus();
        //   setTimeout(() => {
        //   input1Ref.current.focus();
        // }, "100")
          break;
        case 'propertyName':
          alert('物件名が入力されていません');
          input2Ref.current.focus();
        //   setTimeout(() => {
        //   input2Ref.current.focus();
        // }, "100")
          break;
        case 'goshitsu':
          alert('号室が入力されていません');
          input3Ref.current.focus();
        //   setTimeout(() => {
        //     input3Ref.current.focus();
        // }, "100")
          break;
        default:
          break;
      }
    }else if (disabled === true && event.target.value !== '') {
      switch (event.target.name) {
        case 'streetNumber':
          props.steps.addressDetail.value.bukken_address4 = event.target.value;
          break;
        case 'propertyName':
          props.steps.addressDetail.value.bukken_name = event.target.value;
          break;
        case 'goshitsu':
          props.steps.addressDetail.value.bukken_address5 = event.target.value;
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    console.log('addressDetail_usseeffect');
    //テスト用データ
    // props.steps["bukkenType"] = {value : "マンション"};
    // props.steps["todofuken0"] = {value : "東京都"};
    // props.steps["citySelect3"] = {value : "世田谷区"};
    // props.steps["townSelect"] = {value : "池尻"};
    //テスト用データここまで
    var type;
    (props.steps.bukkenType.value === 'その他') ? type = props.steps.other.value : type = props.steps.bukkenType.value;
    
    switch (type) {
      case 'マンション':
        setGoshitsuFlg(true);
        setPropertyNameFlg(true);
        break;
      case '戸建て':
      case '土地':
      case '農地':
      case 'その他':
        setGoshitsuFlg(false);
        setPropertyNameFlg(false);
        break;
      case '一棟アパート・一棟マンション':
      case '一棟ビル':
      case '区分所有ビル':
      case '店舗・工場・倉庫':
        setGoshitsuFlg(false);
        setPropertyNameFlg(true);
        break;
      default:
        break;

    };
    //dataLayer.push({ 'event': "streetNumberFocus" });
  }, [props]);
  //  },[props.steps.bukkenType]);
  return (
    <>
      <form onSubmit={handleSubmit} className="formCss1 addressDetail back-white-wrapper" id="addressDetail">

        <label>住所番地：<input type="text" name="streetNumber" className="streetNumber" value={streetNumber} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input1Ref} autoFocus />
        </label><br></br>
        {propertyNameFlg && <><label>物件名：<input type="text" name="propertyName" className="propertyName" value={propertyName} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input2Ref} /></label><br></br></>}
        {goshitsuFlg && <><label>号室： <input type="text" name="goshitsu" className="goshitsu" value={goshitsu} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input3Ref} formNoValidate /></label><br></br></>}
        <p className='errMsg'>{errorMessage}</p>
        {buttonFlg && <button className="btn btn-success" disabled={disabled} formNoVlidate>
          次へ
        </button>}
        <br></br><div><p className='color-red'>※入力された情報が、お客様の許可なしに一般に公開されることは一切ありません。</p></div>
      </form>
    </>
  );
}
