import React, {Suspense, useState, useEffect } from 'react';

export default function Genkyo(props){
    const [trigger, setTrigger] = useState(false);
    const type = [{value:"住んでいます", label:"はい"},{value:"住んでいません", label:"いいえ"}];
    const triggerNext = (event) =>{
        props.step.value = event.target.value;
        setTrigger(true, props.triggerNextStep({trigger: 'propertyInfo'}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return(<>
        {/* <form onSubmit={handleSubmit}> */}
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper genkyoSelect'>
           <ul className='sc-ifAKCX gkhNlr rsc-os-options genkyoSelect'>
           {type?.map( e =>{return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element genkyo-button' value={e.value}>{e.label}</button></li></>})}
                </ul></div>
                {/* </form> */}
                </>

    );
};