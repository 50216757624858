import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import './lp1/assets/css/style_lp1_1.css';
import $ from 'jquery';



export const Lp1n1 = () => {
	useEffect(() => {
		$(function () {
			var showFlag = false;
			var topBtn = $('#sticky-1');
			topBtn.css('bottom', '-200px');
			var windowHeight = $(window).height();
			//スクロールが100に達したらボタン表示
			$(window).scroll(function () {
				if ($(this).scrollTop() > windowHeight) {
					if (showFlag == false) {
						showFlag = true;
						topBtn.stop().animate({ 'bottom': '20px' }, 200);
					}
				} else {
					if (showFlag) {
						showFlag = false;
						topBtn.stop().animate({ 'bottom': '-200px' }, 200);
					}
				}
			});
			//スクロールしてトップ
			// topBtn.click(function () {
			// 	$('body,html').animate({
			// 		scrollTop: 0
			// 	}, 500);
			// 	return false;
			// });
		});
	})
	return (
		<>
			<Helmet>
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{/* <!-- Google Tag Manager --> */}
				<script>{(function (w, d, s, l, i) {
					w[l] = w[l] || []; w[l].push({
						'gtm.start':
							new Date().getTime(), event: 'gtm.js'
					}); var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
							'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-TC8ZBW4')}</script>
				{/* <!-- End Google Tag Manager --> */}
				<link href="assets/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossOrigin="anonymous" />
				{/* <link rel="stylesheet" href="assets/css/style.css?20220427-2"/> */}
				<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" />
				<script src="//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
				<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js"></script>
				{/* <script type="text/javascript" src="assets/js/app.js"></script> */}

				<title>不動産一括査定イエカカク</title>
				<meta name="description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！" />

				<meta property="og:site_name" content="イエカカク" />
				<meta property="og:title" content="イエカカク" />
				<meta property="og:description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！" />
				<meta property="og:image" content="https://iekakaku.jp/assets/img/iekakaku_1200x630.png" />
				<meta property="og:image:alt" content="画像の説明文" />
				<meta property="og:locale" content="ja_JP" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://iekakaku.jp/" />
				<meta name="twitter:card" content="summary_large_image" />
				<link rel="shortcut icon" href="/assets/img/favicon.ico" />
				<script type="text/javascript">
					{(function(c,l,a,r,i,t,y){
						c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
					t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
					y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "mnmoctunu0")}
				</script>

			</Helmet>
			<div>
				{/* <!-- Google Tag Manager (noscript) --> */}
				<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TC8ZBW4"
					height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
				{/* <!-- End Google Tag Manager (noscript) --> */}
				<header class="header">
					<h1><img src="/assets/img/logo.png" alt="イエカカク" /></h1>
				</header>

				<section class="mv">
					<div class="mv_inner">
						<picture>
							<source srcset="assets/img/mv1_1sp.png" media="(max-width: 560px)" />
							<img src="assets/img/mv1_1.png" alt="最大6社を比較、適正価格でおうちを売却。一括査定はイエカカク！/マンション・戸建て、あらゆる物件の売却に対応/査定の手数料は完全無料!/1,000万以上高く売れた実績あり" />
						</picture>
					</div>
				</section>
				<section class="sticky-2" >
					<div class="c-content">
						<a href="/chat_lp1_1" class="shiny-btn1"><div><span class="cta-subtxt">あなたのお家の値段はいくら？</span><br></br>今すぐチェック！無料査定を開始</div></a>
					</div>
				</section>

				{/* <section class="company-list">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">査定依頼のできる<br />不動産会社一覧</span></h2>
		   <div class="content">
			   <img src="assets/img/company_logo.jpg" alt="不動産会社一覧" />
		   </div>
	   </div>
   </section> */}


				<section class="trouble">
					<div class="container">
						<h2 class="c-heading"><span class="marker">こんなお悩みは<br />ありませんか？</span></h2>
						<div class="content c-content">
							<ul class="list">
								<li class="text">自宅の価格は知りたいが、不動産会社に行くのはおっくうだ</li>
								<li class="text">どの不動産会社に相談していいのかわからない</li>
								<li class="text">できるだけできるだけ高く売りたい</li>
								<li class="text">ローンに充てたいなど事情があり今すぐ現金が必要</li>
							</ul>
						</div>
						<div class="layer layer-bg">
							<div class="layer-txt">
								イエカカクにお任せください！
							</div>
						</div>
					</div>
				</section>

				<section class="point">
					<div class="container">
						<div class="logo">
							<img src="assets/img/logo.png" alt="イエカカク" />
						</div>
						<h2 class="c-heading"><span class="marker">イエカカクの特徴</span></h2>
						<div class="content">
							<div class="c-block">
								<div class="thumb">
									<img src="assets/img/point_thumb01.png" alt="ネット上で査定依頼が可能" />
								</div>
								<p class="text"><span class="org">インターネット上</span>で査定依頼が可能。最短60秒で簡単に申し込みができます。</p>
							</div>
							<div class="c-block">
								<div class="thumb">
									<img src="assets/img/point_thumb02.png" alt="査定依頼のご利用は完全無料" />
								</div>
								<p class="text">査定依頼のご利用は<span class="org">完全無料</span>。イエカカクを利用する費用は一切かかりません。</p>
							</div>
							<div class="c-block">
								<div class="thumb">
									<img src="assets/img/point_thumb03.png" alt="最大6社に査定査定結果の比較で高く売れる" />
								</div>
								<p class="text"><span class="org">最大6社に査定</span>を依頼。値段やサービスを比較して、あなたに一番合った不動産会社とお取引することができます。</p>
							</div>
							<div class="c-block">
								<div class="thumb">
									<img src="assets/img/point_thumb04.png" alt="お客様からのクレームの多い企業は､イエカカクが契約を解除｡優良な不動産会社が集まっています｡" />
								</div>
								<p class="text">お客様からのクレームの多い企業は､イエカカクが契約を解除｡<span class="org">優良な不動産会社</span>が集まっています｡</p>
							</div>
						</div>
					</div>
				</section>


				<section class="flow">
					<div class="container">
						<h2 class="c-heading"><span class="marker">イエカカクご利用の流れ</span></h2>
						<div class="content">
							<div class="c-block">
								<div class="step">
									<img src="assets/img/flow_step01.png" alt="step1" />
								</div>
								<div class="pic">
									<img src="assets/img/flow_thumb01.png" alt="簡単60秒入力" />
								</div>
								<h3>簡単60秒入力</h3>
								<p>査定したい不動産情報と連絡先を入力。入力時間は最短60秒、空き時間で簡単に査定依頼をすることができます。</p>
							</div>
							<div class="c-block">
								<div class="step">
									<img src="assets/img/flow_step02.png" alt="step2" />
								</div>
								<div class="pic">
									<img src="assets/img/flow_thumb02.png" alt="一括査定依頼" />
								</div>
								<h3>一括査定依頼</h3>
								<p>入力された条件を元に、お客様の不動産にマッチした複数の不動産会社が自動で検索されてきます。最大６社まで、一括で査定を依頼することが可能です。</p>
							</div>
							<div class="c-block">
								<div class="step">
									<img src="assets/img/flow_step03.png" alt="step3" />
								</div>
								<div class="pic">
									<img src="assets/img/flow_thumb03.png" alt="査定結果を比較" />
								</div>
								<h3>査定結果を比較</h3>
								<p>最短即日〜数日以内にメール、電話で査定結果をお届けします。査定額や担当者の対応を比較して、売り出す場合は媒介契約を進めます。</p>
							</div>
						</div>
					</div>
				</section>


				<section class="voice">
					<div class="container">
						<h2 class="c-heading"><span class="marker">イエカカクを<br />ご利用した方の声</span></h2>
						<div class="content">
							<div class="c-block">
								<div class="block-top">
									<div class="pic">
										<img src="assets/img/voice01.jpg" alt="イエカカクをご利用した方の声" />
									</div>
									<div class="heading">
										<h3>査定金額を150万円上回る金額で売却に成功</h3>
										<p><small>東京都町田市 / 60代 男性</small></p>
									</div>
								</div>
								<p class="text">当初は友人の紹介で街の不動産屋さんに相談したんですが、想定よりもかなり低い金額を提示されました。相場感を知りたく、インターネットで見たイエカカクを利用してみました。
									査定依頼をしてから1〜2日ですべての業者さんから連絡が来ました。査定金額の最低金額と最高金額の差は300万円くらいあったと思います。結果的に想定よりも150万円も高い金額で売却することができました。自分の要望以上の金額となって大満足です。ありがとうございます。</p>
							</div>

							<div class="c-block">
								<div class="block-top">
									<div class="pic">
										<img src="assets/img/voice03.jpg" alt="イエカカクをご利用した方の声" />
									</div>
									<div class="heading">
										<h3>相続した物件の売却。メール中心に丁寧に対応いただきました。</h3>
										<p><small>神奈川県横浜市 / 50代 女性</small></p>
									</div>
								</div>
								<p class="text">数年前に他界した両親から相続した一戸建てをこの度売却しました。地元の不動産会社に相談するのも良いかなと考えたのですが、物件は地方にあり、行くとせっかくの休日が潰れてしまうのも嫌だったので、友人から聞いたことのある一括査定サービスは試してみました。
									仕事の都合であまり電話で話はできなかったのですがメール中心に対応していただき、複数査定いただいた中でも一番対応の良かった不動産会社で売却することができました。不動産会社さんもそれぞれ異なった情報を保有していたり、サービスも違ったので、比較しながら自分にあった所を探せてよかったです。</p>
							</div>

							<div class="c-block">
								<div class="block-top">
									<div class="pic">
										<img src="assets/img/voice02.jpg" alt="イエカカクをご利用した方の声" />
									</div>
									<div class="heading">
										<h3>最適な不動産会社と出会え、購入時よりも高い金額で売却!</h3>
										<p><small>埼玉県さいたま市 / 30代 女性</small></p>
									</div>
								</div>
								<p class="text">まだ夫婦2人だったときに2LDKの中古マンションを購入したのですが、子どもが2人産まれ、将来を見越して買い替えを検討することになりました。不動産の売却は初めてだったのですが、数社に見積もってもらったおかげでだいたいの相場がわかり、ここ数年で不動産の価値が上がっていることもあり、結果的に購入時よりも高い金額で物件を売却することができました。イエカカクを使って出会えた不動産会社はとても親切で、随時進捗状況を連絡してくれました。イエカカクの一括査定は時間が短縮できて、効率良く自分にマッチした会社を探しやすい、良いサービスですね。</p>
							</div>

							<div class="c-block">
								<div class="block-top">
									<div class="pic">
										<img src="assets/img/voice04.jpg" alt="イエカカクをご利用した方の声" />
									</div>
									<div class="heading">
										<h3>査定依頼から2ヶ月で売却できました！</h3>
										<p><small>千葉県船橋市 / 40代 男性</small></p>
									</div>
								</div>
								<p class="text">以前は某大手の不動産業者で専任媒介契約をして売却活動を行っていたのですがうまくいかず、半年以上経っても買い手が見つからなかったので、ネットで見つけた「イエカカク」で一括査定を試してみました。レスポンスが非常に早く、その日のうちに査定依頼した6社から次々に連絡がありました。以前の経験から、1社との専任媒介ではなく、連絡をくれた中で印象の良かった複数の業者さんと一般媒介契約を結んだところ、「イエカカク」を利用してから2ヶ月ほどで物件を売却することができました。複数社にお願いすることで、金額やサービスを客観的に比較しながら進めることができて良かったです。</p>
							</div>
						</div>
					</div>
				</section>

				<section class="message">
					<div class="container">
						<div class="content">
							<p>
								イエカカクはマンション・一戸建て・土地などお客様がお持ちの不動産を、<br />
								完全無料・オンラインで不動産会社に一括査定できるサービスです。<br />
								中古不動産流通の活性化が進んでいる昨今では、「家は一生の買い物」というのは昔の話。<br />
								ライフスタイルに合わせて家を住み替えたり、勤め先での転勤、相続、離婚、家族構成の変化など、<br />
								様々な理由で不動産売却のニーズが高まっています。
							</p><p>
								よりよい条件で不動産を売るには、物件の魅力を最大限に引き出す売却力のある企業に出会うことが重要です。<br />
								不動産会社によって広告手法や、得意な地域、物件種別、<br />
								物件の将来的な収益力を考慮する企業など様々な基準で査定を行います。<br />
								不動産会社と物件の組み合わせによっては数百〜1000万円の違いがでることも珍しくありません。<br />
								また、「怖い、騙されそう」そんなイメージを不動産業界に持っている方も中にはいると思います。<br />
								同時に複数の不動産会社から査定を受けて比較することで、<br />
								透明性を持って、より良い条件で不動産売却を行うことができます。
							</p><p>
								その他にも、売却を希望する時期や、希望の連絡方法、連絡を希望する時間帯など、<br />
								任意によってお客様の細かな要望にお答えします。<br />
								イエカカクは、ユーザーがより良い不動産会社を選択でき、<br />
								よりよい取引ができるよう、お客様に寄り添ったサービスを提供します。
							</p>
						</div>
					</div>
				</section>
				<section class="faq">
					<div class="container">
						<h2 class="c-heading"><span class="marker">よくあるご質問</span></h2>
						<div class="content c-content">
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-1" />
								<label class="accordion__head q" for="accordion-1"><span class="icon">Q</span><span class="text">査定の依頼には何の情報が必要ですか？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">お客様のご連絡先情報と、物件の、住所、築年、面積などの概要情報が必要です。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-2" />
								<label class="accordion__head q" for="accordion-2"><span class="icon">Q</span><span class="text">どれくらいの期間で売却ができますか？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">媒介契約を締結してから３ヶ月〜６ヶ月が平均ですが、１か月〜１か月半で売却完了した事例もございます。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-3" />
								<label class="accordion__head q" for="accordion-3"><span class="icon">Q</span><span class="text">サービスの提供エリアは？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">エリアは全国が対象です。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-4" />
								<label class="accordion__head q" for="accordion-4"><span class="icon">Q</span><span class="text">自分の所有していない物件を査定してもいいですか？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">ご自身、ご親族でご所有の物件、物件所有者の法定代理人の方のみご利用いただけます。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-5" />
								<label class="accordion__head q" for="accordion-5"><span class="icon">Q</span><span class="text">現在売却活動中なのですが利用できますか？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">はい。ご利用いただけます。売却活動中のセカンドオピニオンとしてご活用ください。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-6" />
								<label class="accordion__head q" for="accordion-6"><span class="icon">Q</span><span class="text">家族やご近所に分からないようにできますか？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">可能です。お申し込みフォームの要望欄に、査定を知られたくない旨とご希望の連絡時間帯などをご記入ください。</span>
								</div>
							</div>
							<div class="c-block">
								<input type="checkbox" class="accordion__input" id="accordion-7" />
								<label class="accordion__head q" for="accordion-7"><span class="icon">Q</span><span class="text">ローンの残債が多い。買い替えはムリ？</span></label>
								<div class="accordion__body a align-items-center-pc">
									<span class="icon">A</span><span class="text">ローンを完済し抵当権を抹消できれば買い替え可能です。まずは一度査定をしてみて現在の売却価格を確認してみてください。</span>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="about">
					<div class="container">
						<h2 class="c-heading"><span class="marker">会社概要</span></h2>
						<div class="content c-content">
							<div class="c-block">
								<div class="th">会社名</div>
								<div class="td">株式会社 budi tech （ブディーテック）</div>
							</div>
							<div class="c-block">
								<div class="th">設立日</div>
								<div class="td">2017年4月27日</div>
							</div>
							<div class="c-block">
								<div class="th">代表者</div>
								<div class="td">代表取締役社長 山方 一也</div>
							</div>
							<div class="c-block">
								<div class="th">所在地</div>
								<div class="td">〒153-0042 目黒区青葉台3-6-16　HF青葉台レジデンス704</div>
							</div>
							<div class="c-block">
								<div class="th">URL</div>
								<div class="td">https://budi-tech.com/</div>
							</div>
						</div>
					</div>
				</section>
				<section id="sticky-1" class="sticky-1" >
					<div class="c-content">
						<a href="/chat_lp1_1" class="shiny-btn1">
							<div><span class="cta-subtxt">あなたのお家の値段はいくら？</span><br></br>今すぐチェック！無料査定を開始</div>
						</a>
					</div>
				</section>
				<footer class="footer">
					<div class="container">
						<div class="text">不動産の査定なら</div>
						<div class="logo">
							<a href="#"><img src="assets/img/logo.png" alt="" /></a>
						</div>
						<p><small>© 2024 budi tech</small></p>
					</div>
				</footer>


			</div>




			<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js" integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW" crossOrigin="anonymous"></script>
		</>
	);
};