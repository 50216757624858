import React, {Suspense, useState, useEffect } from 'react';

export default function Other(props){
    const [trigger, setTrigger] = useState(false);
    const type = [{value:"一棟アパート・一棟マンション", label:"一棟アパート・一棟マンション"},{value:"一棟ビル", label:"一棟ビル"},{value:"区分所有ビル", label:"区分所有ビル"},{value:"店舗・工場・倉庫", label:"店舗・工場・倉庫"},{value:"農地", label:"農地"},{value:"その他", label:"その他"}];
    const triggerNext = (event) =>{ 
        props.step.value = event.target.value;
        setTrigger(props.triggerNextStep())
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return(<>
        {/* <form onSubmit={handleSubmit}> */}
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper'>
           <ul className='sc-ifAKCX gkhNlr rsc-os-options other'>
           {type?.map( e =>{return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element other-button' value={e.value}>{e.label}</button></li></>})}
                </ul></div>
                {/* </form> */}
                </>

    );
};