import React, {Suspense, useState, useEffect } from 'react';
const genkyoList = [{value:'', label:'現在の状況を選択して下さい'},{value:'居住中', label:'居住中'},{value:'賃貸中', label:'賃貸中'},{value:'空室', label:'空室'},{value:'その他', label:'その他'}];
const rerationLists = [{value:'', label:'物件との関係を選択して下さい'},{value:'所有者本人', label:'所有者本人'},{value:'共有名義で所有', label:'共有名義で所有'},{value:'家族・親族が所有', label:'家族・親族が所有'},{value:'所有者の了承を得た代理人', label: '所有者の了承を得た代理人'}];
const jikiLists = [{value:'', label:'売却希望時期を選択して下さい'},{value:'できるだけ早く', label:'できるだけ早く'},{value:'3ヶ月以内', label:'3ヶ月以内'},{value:'6ヶ月以内', label:'6ヶ月以内'},{value:'１年以内', label: '１年以内'},{value:'１年以上', label:'１年以上'}];
const reasonList = [{value:'', label:'査定希望理由を選択して下さい'},{value:'売却のため', label:'売却のため'},{value:'価格によって売却を検討したいから', label:'価格によって売却を検討したいから'},{value:'住み替えのため', label:'住み替えのため'},{value:'転勤のため', label:'転勤のため'},{value:'資産整理のため', label:'資産整理のため'},{value:'離婚のため', label:'離婚のため'},{value:'住宅ローンで困ってる', label:'住宅ローンで困ってる'},{value:'相続のため', label:'相続のため'},{value:'税金（固定資産税・相続税など）算出のため', label:'税金（固定資産税・相続税など）算出のため'},{value:'その他', label:'その他'}];

export default function BukkenQa(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [relation, setReration] = useState();
  const [jiki, setJiki] = useState();
  const [reason, setReason] = useState();
  const [genkyo, setGenkyo] = useState();
  const [genkyoFlg, setGenkyoFlg] = useState();
  const { dataLayer } = window;
  const handleChange = (event) => {
    dataLayer.push({'event': event.target.name});
  switch(event.target.name){
    case 'genkyo':
      setGenkyo(event.target.value);
    break;
    case 'relation':
      setReration(event.target.value);
    break;
    case 'jiki':
      setJiki(event.target.value);
      break;
    case 'reason':
      setReason(event.target.value);
        break;
    default:
      break
  }
  };
  const triggerNext = (event) =>{
    //setTrigger(props.triggerNextStep());

    //参考
    if(reason === '税金（固定資産税・相続税など）算出のため'){
      setTrigger(true, props.triggerNextStep({trigger: 'kento'}))}
     //setTrigger(true, props.triggerNextStep({trigger: 'getAgents'}))}//test
   else if(reason === 'その他'){
    setTrigger(true, props.triggerNextStep({trigger: 'kento'}))}
   //setTrigger(true, props.triggerNextStep({trigger: 'getAgents'}))}//test
 else{
   setTrigger(true, props.triggerNextStep({trigger: 'finish1'}))
   }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = {};
    if(genkyo){setErrorMsg(''); data["genkyo"] = genkyo; }else if(!genkyo && (genkyoFlg)){
      setErrorMsg('物件の状況が未選択です');
      return false;
    };
    if(relation){setErrorMsg(''); data["relation"] = relation; }else{
      setErrorMsg('物件との関係が未選択です');
      return false;
    };
    if(jiki){setErrorMsg(''); data["jiki"] = jiki; }else{
      setErrorMsg('売却時期が未選択です');
      return false;
    };
    if(reason){setErrorMsg(''); data["reason"] = reason; }else{
      setErrorMsg('査定依頼の理由が未選択です');
      return false;
    };
    props.step.value = data;
    triggerNext();
  };
  useEffect(() => {
    if(props.steps.genkyoSelect.value !== '住んでいます'){
      setGenkyoFlg(true);
    }else{
      setGenkyoFlg(false);
    }
  },[props.steps.genkyoSelect.value]);
  return (
    <form onSubmit={handleSubmit} className="formCss2 bukkenQa back-white-wrapper">
      {genkyoFlg && <>
        <label>物件の状況：
      <select value={genkyo} onChange={handleChange} name="genkyo">
          {genkyoList?.map( d =>{return <><option value={d.value}>{d.label}</option></>})}
        </select>
      </label><br></br>
      </>}
      <label>物件との関係：
      <select value={relation} onChange={handleChange} name="relation">
          {rerationLists?.map( d =>{return <><option value={d.value}>{d.label}</option></>})}
        </select>
      </label><br></br>
      <label>売却時期：
      <select value={jiki} onChange={handleChange} name="jiki">
          {jikiLists?.map( d =>{return <><option value={d.value}>{d.label}</option></>})}
        </select>
      </label><br></br>
      <label>査定依頼の理由:
      <select value={reason} onChange={handleChange} name="reason">
          {reasonList?.map( d =>{return <><option value={d.value}>{d.label}</option></>})}
        </select>
      </label><br></br>
      <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success" >
            次へ
            </button>
      </form>
    );
  }