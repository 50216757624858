import React, { useState, useEffect } from 'react';

const SetTowns = (property) => {
  const [townData, setTownData] = useState();
  useEffect(() =>{
    const prefecture = property.prefecture;
    const URL = 'https://geoapi.heartrails.com/api/json?method=getTowns&city='+ property.townProperty;
    switch(property.townProperty){
      case "府中市":
      case "伊達市":
          fetch(URL)
          .then(response => response.json())
          .then(json => json.response.location)
          .then(data  => {return data.filter(value => { return value.prefecture === prefecture})})
          .then(ddd => setTownData(ddd))
          .catch(e => console.log(e))
        break;
      default:
      fetch(URL)
      .then(response => response.json())
       .then(d => setTownData(d.response.location))
      .catch(e => console.log(e))
      break;
    }
    }, [property.townProperty]);
      return(<>
      {townData?.map( d => { return <><option key={d.town_kana} value={d.town} >{d.town}</option></>})}
      </>)
  }

  export default SetTowns;