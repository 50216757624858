import React, { Suspense, useState, useEffect, useRef } from 'react';


export default function UserInfo3(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [ageVal, setAgeVal] = useState();
  const [buttonFlg, setButtonFlg] = useState(false);
  const [activityChanged, setActivityChanged] = useState(false);
  const age = ["20歳未満", "20代", "30代", "40代", "50代", "60代", "70歳以上"];
  const input8Ref = useRef(null);


  const { dataLayer } = window;
  const checkEmpty = (name, value) => {
    console.log("checkEmpty", name, value);
    if ((name === "ageVal" && !value) || (name !== "ageVal" && !ageVal))///
    { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }

  }

  const handleChange = (event) => {
    let v;
    switch (event.target.name) {
      case 'ageVal':
        setAgeVal(event.target.value);
        v = event.target.value;
        break;

      default:
        break
    }
    checkEmpty(event.target.name, v);
  };



  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'contact1c' }))
  }
  const onBlurCheck = (event) => {
    event.preventDefault();
    let data = [];
    if (
      input8Ref.current === event.relatedTarget) {
      dataLayer.push({ 'event': event.relatedTarget.name });
      setErrorMsg('');
    }
    else {

      if (ageVal) { setErrorMsg(''); data = data.concat({ name: 'age', value: ageVal }); } else {
        setErrorMsg('※年代が未入力です');
        return false;
      };

    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = [];
    if (input8Ref.current === event.relatedTarget) {
      setErrorMsg('');
    }
    else {

      if (ageVal) { setErrorMsg(''); data = data.concat({ name: 'age', value: ageVal }); } else {
        setErrorMsg('※年代が未入力です');
        return false;
      };


      console.log("data", data);
      props.step.value = data;
      triggerNext();
    }
  };

  useEffect(() => {
  }, []);
  return (
    <form className="formCss2 userInfo back-white-wrapper" onSubmit={handleSubmit}>

      <label>年齢：
        <select className="year" value={ageVal} onChange={handleChange} name="ageVal" defaultValue='' onBlur={onBlurCheck} ref={input8Ref}>
          <option value='' disabled>年代を選んでください</option>
          {age?.map(d => { return <><option value={d}>{d}</option></> })}
        </select>
      </label><br></br>

      <p className='errMsg'>{errorMessage}</p>
      {buttonFlg && <button className="btn btn-success" formNoVlidate>
        次へ
      </button>}

    </form>
  );
}