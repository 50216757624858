import React, { Suspense, useState, useEffect } from 'react';

export default function Other(props) {
    const [trigger, setTrigger] = useState(false);
    const [typeFlg, setTypeFlg] = useState(true);
    const type = [{ value: "一棟アパート・一棟マンション", label: "一棟アパート・一棟マンション" }, { value: "一棟ビル", label: "一棟ビル" }, { value: "区分所有ビル", label: "区分所有ビル" }, { value: "店舗・工場・倉庫", label: "店舗・工場・倉庫" }, { value: "農地", label: "農地" }, { value: "その他", label: "その他" }];
    const triggerNext = (event) => {
        props.step.value = event.target.value;
        setTrigger(props.triggerNextStep())
        setTypeFlg(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }
    const returnPage = (event) => {
        event.preventDefault();
        if (event.target.className.match("returnToBType")) {
            setTrigger(true, props.triggerNextStep({ trigger: 'bukkenType' }));
            setTypeFlg(false);
        } 
      }
    return (<>
        {/* <form onSubmit={handleSubmit}> */}
        {typeFlg &&
            
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper'>
                <div className="bukken-type-intro"><p>査定価格を知りたい物件の<br></br>種類を選択してください</p></div>
                <ul className='sc-ifAKCX gkhNlr rsc-os-options other'>
                    {type?.map(e => {
                        return <><li className='sc-htpNat GgOGn rsc-os-option'>
                            <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element other-button' value={e.value}>{e.label}</button></li></>
                    })}
                </ul>
                <button onClick={returnPage} className='sc-bxivhb ipSaXD rsc-os-option-element others-button bukken-type-button returnToBType' name='戻る'>◀ 戻る</button>
                </div>
               
        }
        {/* </form> */}
    </>

    );
};