import React, { Component, Suspense, useState, useEffect } from 'react';

export default function PropertyData3(props) {
  const [trigger, setTrigger] = useState(false);
  const [madoriFlg, setMadoriFlg] = useState(false);
  const [chikunenFlg, setChikuneFlg] = useState(false);
  const [madori, setMadori] = useState();
  const [chiku, setChiku] = useState();
  const [madoriSize, setMadoriSize] = useState();
  const [chikuYear, setChikuYear] = useState();
  const [type, setType] = useState();
  const [roomData, setRoomDatas] = useState({});
  const [firstTime, setFirstTime]  = useState(true);
  const madoriValue =
    [
      {
        value: '1R',
        label: '1R'
      },
      {
        value: '1K',
        label: '1K'
      },
      {
        value: '1DK',
        label: '1DK'
      },
      {
        value: '1LDK',
        label: '1LDK'
      },
      {
        value: '2DK',
        label: '2DK'
      },
      {
        value: '2LDK',
        label: '2LDK'
      },
      {
        value: '3DK',
        label: '3DK'
      },
      {
        value: '3LDK',
        label: '3LDK'
      },
      {
        value: '4DK',
        label: '4DK'
      },
      {
        value: '4LDK',
        label: '4LDK'
      },
      {
        value: '5DK',
        label: '5DK'
      },
      {
        value: '5LDK',
        label: '5LDK'
      },
      {
        value: '6DK以上',
        label: '6DK以上'
      }
    ];
  const chikunen = [
    { value: "2023年", label: "2023年（令和5年）" },
    { value: "2022年", label: "2022年（令和4年）" },
    { value: "2021年", label: "2021年（令和3年）" },
    { value: "2020年", label: "2020年（令和2年）" },
    { value: "2019年", label: "2019年（令和元年）" },
    { value: "2018年", label: "2018年（平成30年）" },
    { value: "2017年", label: "2017年（平成29年）" },
    { value: "2016年", label: "2016年（平成28年）" },
    { value: "2015年", label: "2015年（平成27年）" },
    { value: "2014年", label: "2014年（平成26年）" },
    { value: "2013年", label: "2013年（平成25年）" },
    { value: "2012年", label: "2012年（平成24年）" },
    { value: "2011年", label: "2011年（平成23年）" },
    { value: "2010年", label: "2010年（平成22年）" },
    { value: "2009年", label: "2009年（平成21年）" },
    { value: "2008年", label: "2008年（平成20年）" },
    { value: "2007年", label: "2007年（平成19年）" },
    { value: "2006年", label: "2006年（平成18年）" },
    { value: "2005年", label: "2005年（平成17年）" },
    { value: "2004年", label: "2004年（平成16年）" },
    { value: "2003年", label: "2003年（平成15年）" },
    { value: "2002年", label: "2002年（平成14年）" },
    { value: "2001年", label: "2001年（平成13年）" },
    { value: "2000年", label: "2000年（平成12年）" },
    { value: "1999年", label: "1999年（平成11年）" },
    { value: "1998年", label: "1998年（平成10年）" },
    { value: "1997年", label: "1997年（平成9年）" },
    { value: "1996年", label: "1996年（平成8年）" },
    { value: "1995年", label: "1995年（平成7年）" },
    { value: "1994年", label: "1994年（平成6年）" },
    { value: "1993年", label: "1993年（平成5年）" },
    { value: "1992年", label: "1992年（平成4年）" },
    { value: "1991年", label: "1991年（平成3年）" },
    { value: "1990年", label: "1990年（平成2年）" },
    { value: "1989年", label: "1989年（平成元年）" },
    { value: "1988年", label: "1988年（昭和63年）" },
    { value: "1987年", label: "1987年（昭和62年）" },
    { value: "1986年", label: "1986年（昭和61年）" },
    { value: "1985年", label: "1985年（昭和60年）" },
    { value: "1984年", label: "1984年（昭和59年）" },
    { value: "1983年", label: "1983年（昭和58年）" },
    { value: "1982年", label: "1982年（昭和57年）" },
    { value: "1981年", label: "1981年（昭和56年）" },
    { value: "1980年", label: "1980年（昭和55年）" },
    { value: "1979年", label: "1979年（昭和54年）" },
    { value: "1978年", label: "1978年（昭和53年）" },
    { value: "1977年", label: "1977年（昭和52年）" },
    { value: "1976年", label: "1976年（昭和51年）" },
    { value: "1975年", label: "1975年（昭和50年）" },
    { value: "1974年", label: "1974年（昭和49年）" },
    { value: "1973年", label: "1973年（昭和48年）" },
    { value: "1972年", label: "1972年（昭和47年）" },
    { value: "1971年", label: "1971年（昭和46年）" },
    { value: "1970年", label: "1970年（昭和45年）" },
    { value: "1969年", label: "1969年（昭和44年）" },
    { value: "1968年", label: "1968年（昭和43年）" },
    { value: "1967年", label: "1967年（昭和42年）" },
    { value: "1966年", label: "1966年（昭和41年）" },
    { value: "1965年", label: "1965年（昭和40年）" },
    { value: "1964年", label: "1964年（昭和39年）" },
    { value: "1963年", label: "1963年（昭和38年）" },
    { value: "1962年", label: "1962年（昭和37年）" },
    { value: "1961年", label: "1961年（昭和36年）" },
    { value: "1960年", label: "1960年（昭和35年）" },
    { value: "1959年", label: "1959年（昭和34年）" },
    { value: "1958年", label: "1958年（昭和33年）" },
    { value: "1957年", label: "1957年（昭和32年）" },
    { value: "1956年", label: "1956年（昭和31年）" },
    { value: "1955年", label: "1955年（昭和30年）" },
    { value: "1954年", label: "1954年（昭和29年）" },
    { value: "1953年", label: "1953年（昭和28年）" },
    { value: "1952年", label: "1952年（昭和27年）" },
    { value: "1951年", label: "1951年（昭和26年）" },
    { value: "1950年", label: "1950年（昭和25年）" },
    { value: "1949年", label: "1949年（昭和24年）" },
    { value: "1948年", label: "1948年（昭和23年）" },
    { value: "1947年", label: "1947年（昭和22年）" },
    { value: "1946年", label: "1946年（昭和21年）" },
    { value: "1945年", label: "1945年（昭和20年）" },
    { value: "1944年", label: "1944年（昭和19年）" },
    { value: "1943年", label: "1943年（昭和18年）" },
    { value: "1942年", label: "1942年（昭和17年）" },
    { value: "1941年", label: "1941年（昭和16年）" },
    { value: "1940年", label: "1940年（昭和15年）" },
    { value: "1939年", label: "1939年（昭和14年）" },
    { value: "1938年", label: "1938年（昭和13年）" },
    { value: "1937年", label: "1937年（昭和12年）" },
    { value: "1936年", label: "1936年（昭和11年）" },
    { value: "1935年", label: "1935年（昭和10年）" },
    { value: "1934年", label: "1934年（昭和9年）" },
    { value: "1933年", label: "1933年（昭和8年）" },
    { value: "1932年", label: "1932年（昭和7年）" },
    { value: "1931年", label: "1931年（昭和6年）" },
    { value: "1930年", label: "1930年（昭和5年）" },
  ];
  const popupModal = (event) => {
    console.log(event.type);
    if (event.target.name === "madoriInput") {
      setMadoriFlg(true);
    } else if (event.target.name === "chikuInput") {
      setChikuneFlg(true);
    }
  }
  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'bukkenQa2' }))
    setFirstTime(false);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("event", event.target.className);
    console.log("event", event.target.value);
    switch (event.target.className) {
      case "madoriButtons":
        setMadoriSize(event.target.value)
        console.log("madori", event.target.value);
        if (roomData.hasOwnProperty("madori")) { roomData["madori"] = event.target.value } else {
          setRoomDatas({
            ...roomData,
            "madori": event.target.value
          })
        };
        console.log("roomData1", roomData);
        setMadoriFlg(false);
        setTimeout(() => {
          if(firstTime === true){
          setChikuneFlg(true);
          }else{
            props.steps.propertyData3.value.madori = event.target.value;
          }
        }, "500")

        break;
      case "chikuButtons":
        setChikuYear(event.target.value)
        if (roomData.hasOwnProperty("chiku")) {
          roomData["chiku"] = event.target.value
          const data = roomData;
          props.step.value = data;
          setChikuneFlg(false);
          if(firstTime === true){
          triggerNext();
          }else{
            props.steps.propertyData3.value.chiku = event.target.value;
          }
        } else {
          const data = {
            ...roomData,
            "chiku": event.target.value
          }
          setRoomDatas(data);
          props.step.value = data;
          setChikuneFlg(false);
          if(firstTime === true){
          triggerNext();
          }else{
            props.steps.propertyData3.value.chiku = event.target.value;
          }
        };
        break;
      default:
        break;
    }
    //
  };
  const inputChange = (event) => {
    switch (event.target.name) {
      case 'madoriInput':
        setMadoriSize(event.target.value)
        break;
      case 'chikuInput':
        setChikuYear(event.target.value)
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    //テスト用データ
    props.steps["bukkenType"] = { value: "マンション" };
    //テスト用データここまで
    (props.steps.bukkenType.value === 'その他') ? setType(props.steps.other.value) : setType(props.steps.bukkenType.value);
    switch (type) {
      case '戸建て':
      case 'マンション':
        setMadori(true);
        setMadoriFlg(true);
        setChiku(true);
        break;
      case '一棟アパート・一棟マンション':
      case '一棟ビル':
      case '区分所有ビル':
      case '店舗・工場・倉庫':
        setChiku(true);
        setChikuneFlg(true);
        break;
      default:
        break;

    }
  }, [type]);

  const modalOverlay = (e) => {
    const backgroundDiv = document.getElementsByClassName('overley');
    const bubble = document.getElementsByClassName('rsc-ts-bubble');
    const backWhite = document.getElementsByClassName('back-white-wrapper');
    const rscContent = document.getElementsByClassName('rsc-content');
    const operator = document.getElementsByClassName('rsc-ts-image');
    console.log("operator", operator);
    if (e === true) {
      backgroundDiv[0].style.display = "unset";
      rscContent[0].style.overflowY = "hidden";
      Object.keys(bubble).forEach(e => {
        bubble[e].style.opacity = "0.1";
      });
      Object.keys(operator).forEach(e => {
        operator[e].style.opacity = "0.1";
      });
      Object.keys(backWhite).forEach(e => {
        if (backWhite[e].className.indexOf('propertyDataForm3') !== -1) {
          backWhite[e].style.backgroundColor = "transparent";
        } else {
          backWhite[e].style.opacity = "0.1";
        }
      });
    } else {
      backgroundDiv[0].style.display = "none";
      operator[0].style.opacity = "1";
      rscContent[0].style.overflowY = "scroll";
      Object.keys(bubble).forEach(e => {
        bubble[e].style.opacity = "1";
      });
      Object.keys(operator).forEach(e => {
        operator[e].style.opacity = "1";
      });
      Object.keys(backWhite).forEach(e => {
        if (backWhite[e].className.indexOf('propertyDataForm3') !== -1) {
          backWhite[e].style.backgroundColor = "#fff";
        } else {
          backWhite[e].style.opacity = "1";
        }
      });
    }
  }
  useEffect(() => {
    modalOverlay(madoriFlg);
  }, [madoriFlg]);
  useEffect(() => {
    modalOverlay(chikunenFlg);
  }, [chikunenFlg]);

  return (

    <form className="formCss1 propertyDataForm3 modalForm back-white-wrapper">
      {madori && <><label>間取り：<input onFocus={popupModal} type="text" name="madoriInput" value={madoriSize} onChange={inputChange} />
      </label><br></br>
      </>
      }
      {madoriFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
            <div className="modal-header"><p>間取りを選択してください</p></div>
            <div className='modal-box'>
              <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
                {madoriValue?.map(e => {
                  return <><li className='sc-htpNat GgOGn rsc-os-option'>
                    <button onClick={handleSubmit} className='madoriButtons' value={e.value}>{e.label}</button></li></>
                })}
              </ul>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>}
      {chiku && <><label>築年:<input onFocus={popupModal} type="text" name="chikuInput" value={chikuYear} onChange={inputChange} />
      </label><br></br>
      </>
      }
      {chikunenFlg && <>
        <div class="modal-container">
          <div class="back-orange-wrapper modal-window">
            <div className="modal-header"><p>築年数を選択してください</p></div>
            <div className='modal-box'>
              <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
                {chikunen?.map(e => {
                  return <><li className='sc-htpNat GgOGn rsc-os-option whide-li'>
                    <button onClick={handleSubmit} className='chikuButtons' value={e.value}>{e.label}</button></li></>
                })}
              </ul>
            </div>
          </div>
        </div>
      </>}
    </form>
  );
}

