import React, { useState, useEffect } from 'react';

export default function GetAgents2(props){
  const [trigger, setTrigger] = useState(false);
  const [idData, setIdData] = useState(formatDate());
  const triggerNext = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'sateiStart0c'}))
  }
  const triggerNext2 = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'noAgent'}))
  }
  const triggerNext3 = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'failData'}))
  }
  function formatDate() {
    var dt = new Date();
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    var tim = y + m + d;
    var rnd =  ("000000" + Math.floor(Math.random() * 1000000)).slice(-6);
    return (tim + rnd);
    }
  
  useEffect(()=>{
    var type;
    var reason;
    console.log("props.steps",props.steps);
  (props.steps.bukkenType.value == 'その他')?type = props.steps.other.value : type = props.steps.bukkenType.value;
  // (props.steps.bukkenQa2.value.reason == '税金（固定資産税・相続税など）算出のため')?reason = "価格によって売却を検討したいから" : reason = props.steps.bukkenQa2.value.reason;
    var data = [{name: "id",value: idData },
    {name:"bukken-type" ,value: type},
    {name:"bukken-address1" ,value: props.steps.area.value.bukken_address1},
    {name: "bukken-address2"  ,value: props.steps.area.value.bukken_address2},
    {name: "bukken-address3"  ,value: props.steps.area.value.bukken_address3},
    {name: "bukken-address4"  ,value: props.steps.addressDetail.value.bukken_address4},
    {name: "bukken-relation"  ,value: props.steps.bukkenQa2.value.relation},
    {name: "reason1"  ,value: props.steps.bukkenQa2.value.reason}
  ];
     //テスト用データ
  //   var data = [];
  //   data = data.concat([{name: "id",value: "20220917987329" },{name:"bukken-type" ,value: "戸建て"},{name:"bukken-address1" ,value: '東京都'},{name: "bukken-address2"  ,value: '千代田区'},{name: "bukken-address3"  ,value: '一橋'},{name: "bukken-address4"  ,value: '1'},{name: "bukken-relation"  ,value: '所有者本人'},
  //   {name: "bukken-size"  ,value:  1.11 + '㎡'},
  //   {name: 'bukken-name' ,value: '1'},
  //   {name: 'bukken-address5' ,value: '1'},
  //   {name: 'bukken-year' ,value: '2012年'},
  //   {name: 'layout' ,value: '1DK'},
  //   {name: "bukken-state" ,value: "居住中"}
  // ]);
  //テスト用データここまで
  
  var size = '';
  var size2 = '';
  
  switch(type){
      case "マンション":
        size = props.steps.propertyData2.value.roomSize;
        data = data.concat({name: "bukken-size"  ,value:  size + '㎡'});
        data = data.concat({name: 'bukken-name' ,value: props.steps.addressDetail.value.bukken_name});
        data = data.concat({name: 'bukken-address5' ,value: props.steps.addressDetail.value.bukken_address5});
        data = data.concat({name: 'bukken-year' ,value: props.steps.propertyData3.value.chiku});
        data = data.concat({name: 'layout' ,value: props.steps.propertyData3.value.madori});
        break;
        case "戸建て":
            size = props.steps.propertyData2.value.roomSize;
            size2 = props.steps.propertyData2.value.tochiSize;
          
          data = data.concat({name: 'bukken-size' ,value: size + '㎡'});
          
          data = data.concat({name: 'tochi-size' ,value: size2 + '㎡'});
          data = data.concat({name: 'bukken-year' ,value: props.steps.propertyData3.value.chiku});
          data = data.concat({name: 'layout' ,value: props.steps.propertyData3.value.madori});
        break;
        case "土地":
        case '農地':
        case 'その他':
          size = props.steps.propertyData2.value.tochiSize;
          
          data = data.concat({name: 'tochi-size' ,value: size + '㎡'});
        break;
        case '一棟アパート・一棟マンション':
        case '一棟ビル':
        case '区分所有ビル':
        case '店舗・工場・倉庫':
          size = props.steps.propertyData2.value.roomSize;
          size2 = props.steps.propertyData2.value.tochiSize;
          
          data = data.concat({name: 'bukken-size' ,value: size + '㎡'});
          
          data = data.concat({name: 'tochi-size' ,value: size2 + '㎡'});
          data = data.concat({name: 'bukken-name' ,value: props.steps.addressDetail.value.bukken_name});
          data = data.concat({name: 'bukken-year' ,value: props.steps.propertyData3.value.chiku});
        break;
        default:
        break;
    };
    
    if(props.steps.genkyoSelect.value === '住んでいます'){
      data = data.concat({name: "bukken-state" ,value: "居住中"});
    }else{
      data = data.concat({name: "bukken-state" ,value: props.steps.bukkenQa2.value.genkyo});
    }
    
		var jsonData = JSON.stringify(data);
    fetch('/satei',{
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: jsonData})
    .then((response) => {
      if(response.ok){
      return response.json();
      }
      else{
        return false;
      }
    })
    .then(json =>{
      if(Object.keys(json).length === 0){
        throw new Error("no json data");
      }else{
      return data.concat({name:"agents", value: json});
      }
    })
    .then(res =>{
      props.step.value = res;
      triggerNext();
    })
    .catch((err) =>{
      console.log("err",err);
      if(err.message === "no json data"){
        alert('対応できる不動産会社を見つけられませんでした。');
        triggerNext2();
      }else{
      alert('不動産会社データ取得に失敗しました');
      triggerNext3();
      }
      return false;
      //ここで最初に戻るための処理を入れる?いれない？
    });
  },[]);
    return (
      // <div className='marginrightsp getAgents back-white-wrapper'><p>お待ちの間に査定結果の送信先の入力をお願いいたします。</p></div>
      <></>
    );
}