import React, { Suspense, useState, useEffect } from 'react';

export default function Area(props) {
    const [trigger, setTrigger] = useState(false);
    const areas = [{ value: '北海道・東北地方', label: '北海道・東北地方', trigger: 'hokkaidoTohokuMessage' },
    { value: '関東地方', label: '関東地方', trigger: 'kantoMessage' },
    { value: '中部地方', label: '中部地方', trigger: 'chubuMessage' },
    { value: '近畿地方', label: '近畿地方', trigger: 'kinki' },
    { value: '中国・四国地方', label: '中国・四国地方', trigger: 'chugokuShikoku' },
    { value: '九州地方', label: '九州地方', trigger: 'kyushu' }];
    const [todofuken, setTodofuken] = useState();
    const [todofukens, setTodofukens] = useState();
    const [city, setCity] = useState();
    const [cities, setCities] = useState();
    const [town, setTown] = useState();
    const [towns, setTowns] = useState();
    const [area, setArea] = useState()
    const [todofukenFlg, setTodofukenFlg] = useState();
    const [areaFlg, setAreaFlg] = useState();
    const [cityFlg, setCityFlg] = useState();
    const [townFlg, setTownFlg] = useState();
    const [flgData, setFlgData] = useState();
    const [flgData2, setFlgData2] = useState();
    const [datas, setDatas] = useState({});
    const [firstTime, setFirstTime] = useState(true);
    
    const hokkaidoTohoku = [
        { value: '北海道', label: '北海道', trigger: 'todofukenMessage' },
        { value: '青森県', label: '青森県', trigger: 'todofukenMessage' },
        { value: '岩手県', label: '岩手県', trigger: 'todofukenMessage' },
        { value: '宮城県', label: '宮城県', trigger: 'todofukenMessage' },
        { value: '秋田県', label: '秋田県', trigger: 'todofukenMessage' },
        { value: '山形県', label: '山形県', trigger: 'todofukenMessage' },
        { value: '福島県', label: '福島県', trigger: 'todofukenMessage' },
    ];
    const kanto = [
        { value: '茨城県', label: '茨城県', trigger: 'todofukenMessage' },
        { value: '栃木県', label: '栃木県', trigger: 'todofukenMessage' },
        { value: '群馬県', label: '群馬県', trigger: 'todofukenMessage' },
        { value: '埼玉県', label: '埼玉県', trigger: 'todofukenMessage' },
        { value: '千葉県', label: '千葉県', trigger: 'todofukenMessage' },
        { value: '東京都', label: '東京都', trigger: 'todofukenMessage' },
        { value: '神奈川県', label: '神奈川県', trigger: 'todofukenMessage' },
    ];
    const chubu = [
        { value: '新潟県', label: '新潟県', trigger: 'todofukenMessage' },
        { value: '富山県', label: '富山県', trigger: 'todofukenMessage' },
        { value: '石川県', label: '石川県', trigger: 'todofukenMessage' },
        { value: '福井県', label: '福井県', trigger: 'todofukenMessage' },
        { value: '山梨県', label: '山梨県', trigger: 'todofukenMessage' },
        { value: '長野県', label: '長野県', trigger: 'todofukenMessage' },
        { value: '岐阜県', label: '岐阜県', trigger: 'todofukenMessage' },
        { value: '静岡県', label: '静岡県', trigger: 'todofukenMessage' },
        { value: '愛知県', label: '愛知県', trigger: 'todofukenMessage' },
    ];
    const kinki = [
        { value: '三重県', label: '三重県', trigger: 'todofukenMessage' },
        { value: '滋賀県', label: '滋賀県', trigger: 'todofukenMessage' },
        { value: '京都府', label: '京都府', trigger: 'todofukenMessage' },
        { value: '大阪府', label: '大阪府', trigger: 'todofukenMessage' },
        { value: '兵庫県', label: '兵庫県', trigger: 'todofukenMessage' },
        { value: '奈良県', label: '奈良県', trigger: 'todofukenMessage' },
        { value: '和歌山県', label: '和歌山県', trigger: 'todofukenMessage' },
    ];
    const chugokuShikoku = [
        { value: '鳥取県', label: '鳥取県', trigger: 'todofukenMessage' },
        { value: '島根県', label: '島根県', trigger: 'todofukenMessage' },
        { value: '岡山県', label: '岡山県', trigger: 'todofukenMessage' },
        { value: '広島県', label: '広島県', trigger: 'todofukenMessage' },
        { value: '山口県', label: '山口県', trigger: 'todofukenMessage' },
        { value: '徳島県', label: '徳島県', trigger: 'todofukenMessage' },
        { value: '香川県', label: '香川県', trigger: 'todofukenMessage' },
        { value: '愛媛県', label: '愛媛県', trigger: 'todofukenMessage' },
        { value: '高知県', label: '高知県', trigger: 'todofukenMessage' },
    ];
    const kyushu = [
        { value: '福岡県', label: '福岡県', trigger: 'todofukenMessage' },
        { value: '佐賀県', label: '佐賀県', trigger: 'todofukenMessage' },
        { value: '長崎県', label: '長崎県', trigger: 'todofukenMessage' },
        { value: '熊本県', label: '熊本県', trigger: 'todofukenMessage' },
        { value: '大分県', label: '大分県', trigger: 'todofukenMessage' },
        { value: '宮崎県', label: '宮崎県', trigger: 'todofukenMessage' },
        { value: '鹿児島県', label: '鹿児島県', trigger: 'todofukenMessage' },
        { value: '沖縄県', label: '沖縄県', trigger: 'todofukenMessage' },
    ];
    useEffect(() => {
        setAreaFlg(true);
    }, []);
    const triggerNext = (event) => {
        console.log("a_after", event);
        setTrigger(true, props.triggerNextStep({ trigger: 'addressDetail' }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }
    const popupModal = (event) => {
        console.log(event);
        console.log(event.type);
        if (event.target.name === "todoFukenInput") {
            setArea();
            setTodofuken();
            setCity();
            setTown();
            setAreaFlg(true);
            setTodofukens();
            setCities();
            setTowns();
        } else if (event.target.name === "cityInput") {
            setCityFlg(true);
            setCity();
            setTowns();
        } else if (event.target.name === "townInput") {
            setTownFlg(true);
            setTown();
        }
    }
    useEffect(() => {
        if (area !== '') {
            switch (area) {
                case '北海道・東北地方':
                    setTodofukens(hokkaidoTohoku);
                    break;
                case '関東地方':
                    setTodofukens(kanto);
                    break;
                case '中部地方':
                    setTodofukens(chubu);
                    break;
                case '近畿地方':
                    setTodofukens(kinki);
                    break;
                case '中国・四国地方':
                    setTodofukens(chugokuShikoku);
                    break;
                case '九州地方':
                    setTodofukens(kyushu);
                    break;
                default:
                    break;
            }
        }
    }, [area]);
    useEffect(() => {
        console.log("useEffect todofuken");
        if (todofuken !== '') {
            const jsonData = require('../../components/assets/js/prefJson/47todofuken.json');
            var cityData = jsonData.response.prefecture.find((v) => v.city == todofuken);
            if (cityData) {
                let townData = require('../../components/assets/js/prefJson/' + cityData.city_eng + '.json');
                if (townData) {
                    let towns = townData.response.location;
                    towns.sort(function (a, b) {
                        if (a.city_kana > b.city_kana) {
                            return 1;
                        }
                        if (a.city_kana < b.city_kana) {
                            return -1;
                        }
                        return 0;
                    });
                    const akasatanaFlg = [
                        { gyo: "a", kana: "あ", disabled: true }, { gyo: "ka", kana: "か", disabled: true }, { gyo: "sa", kana: "さ", disabled: true }, { gyo: "ta", kana: "た", disabled: true }, { gyo: "na", kana: "な", disabled: true }, { gyo: "ha", kana: "は", disabled: true }, { gyo: "ma", kana: "ま", disabled: true }, { gyo: "ya", kana: "や", disabled: true }, { gyo: "ra", kana: "ら", disabled: true }, { gyo: "wa", kana: "わ", disabled: true }
                    ];
                    const akasatana = [
                        { gyo: "a", list: [], display: "none" }, { gyo: "ka", list: [], display: "none" }, { gyo: "sa", list: [], display: "none" }, { gyo: "ta", list: [], display: "none" }, { gyo: "na", list: [], display: "none" }, { gyo: "ha", list: [], display: "none" }, { gyo: "ma", list: [], display: "none" }, { gyo: "ya", list: [], display: "none" }, { gyo: "ra", list: [], display: "none" }, { gyo: "wa", list: [], display: "none" }
                    ];

                    let displayFlg = true;
                    towns.forEach(element => {
                        switch (true) {
                            case /^[ぁ-お]/.test(element["city_kana"]):
                                akasatana[0].list.push(element);
                                if (displayFlg) {
                                    akasatana[0].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[0].disabled = false;
                                break;
                            case /^[か-ご]/.test(element["city_kana"]):
                                akasatana[1].list.push(element);
                                if (displayFlg) {
                                    akasatana[1].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[1].disabled = false;
                                break;
                            case /^[さ-ぞ]/.test(element["city_kana"]):
                                akasatana[2].list.push(element);
                                if (displayFlg) {
                                    akasatana[2].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[2].disabled = false;
                                break;
                            case /^[た-ど]/.test(element["city_kana"]):
                                akasatana[3].list.push(element);
                                if (displayFlg) {
                                    akasatana[3].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[3].disabled = false;
                                break;
                            case /^[な-の]/.test(element["city_kana"]):
                                akasatana[4].list.push(element);
                                if (displayFlg) {
                                    akasatana[4].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[4].disabled = false;
                                break;
                            case /^[は-ぽ]/.test(element["city_kana"]):
                                akasatana[5].list.push(element);
                                if (displayFlg) {
                                    akasatana[5].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[5].disabled = false;
                                break;
                            case /^[ま-も]/.test(element["city_kana"]):
                                akasatana[6].list.push(element);
                                if (displayFlg) {
                                    akasatana[6].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[6].disabled = false;
                                break;
                            case /^[ゃ-よ]/.test(element["city_kana"]):
                                akasatana[7].list.push(element);
                                if (displayFlg) {
                                    akasatana[7].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[7].disabled = false;
                                break;
                            case /^[ら-ろ]/.test(element["city_kana"]):
                                akasatana[8].list.push(element);
                                if (displayFlg) {
                                    akasatana[8].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[8].disabled = false;
                                break;
                            case /^[ゎ-ん]/.test(element["city_kana"]):
                                akasatana[9].list.push(element);
                                if (displayFlg) {
                                    akasatana[9].display = "block";
                                    displayFlg = false;
                                }
                                akasatanaFlg[9].disabled = false;
                                break;
                            default:
                                break;
                        }
                    });
                    setFlgData(akasatanaFlg);
                    setCities(akasatana);
                }
            }
        }
    }, [todofuken]);
    useEffect(() => {
        //  if(props.steps.citySelect3.value){
        //テストデータ
        console.log("useEffect city");
        if (city !== '') {
            //   let prefacture = "東京都";
            // let cityName = "渋谷区";
            let prefacture = todofuken;
            let cityName = city;
            const jsonData = require('../../components/assets/js/prefJson/47todofuken.json');
            var cityData = jsonData.response.prefecture.find((v) => v.city == prefacture);
            if (cityData) {
                // let townData = require('../../components/assets/js/prefJson/' + cityData.city_eng + 'Town.json');
                // console.log("townData",townData);
                //テストデータここまで
                const URL = 'https://geoapi.heartrails.com/api/json?method=getTowns&city=' + cityName;
                //テストデータ
                // const testData = 
                // let towns = testData.response.location;;
                //テストデータここまで
                fetch(URL)
                    .then(res => { let rere = res.json(); return rere; })
                    .then(d => {
                        console.log("d", d.response.location);
                        let towns;
                        switch (cityName) {
                            case "府中市":
                            case "伊達市":
                                towns = d.response.location.filter(value => { return value.prefecture === prefacture });
                                break;
                            default:
                                towns = d.response.location;
                                break;
                        }
                        return towns;
                    })
                    .then(t => {
                        if (t) {
                            //ここから処理入れる
                            let towns = t;
                            towns.sort(function (a, b) {
                                if (a.town_kana > b.town_kana) {
                                    return 1;
                                }
                                if (a.town_kana < b.town_kana) {
                                    return -1;
                                }
                                return 0;
                            });
                            const akasatanaFlg = [
                                { gyo: "a", kana: "あ", disabled: true }, { gyo: "ka", kana: "か", disabled: true }, { gyo: "sa", kana: "さ", disabled: true }, { gyo: "ta", kana: "た", disabled: true }, { gyo: "na", kana: "な", disabled: true }, { gyo: "ha", kana: "は", disabled: true }, { gyo: "ma", kana: "ま", disabled: true }, { gyo: "ya", kana: "や", disabled: true }, { gyo: "ra", kana: "ら", disabled: true }, { gyo: "wa", kana: "わ", disabled: true }
                            ];
                            const akasatana = [
                                { gyo: "a", list: [], display: "none" }, { gyo: "ka", list: [], display: "none" }, { gyo: "sa", list: [], display: "none" }, { gyo: "ta", list: [], display: "none" }, { gyo: "na", list: [], display: "none" }, { gyo: "ha", list: [], display: "none" }, { gyo: "ma", list: [], display: "none" }, { gyo: "ya", list: [], display: "none" }, { gyo: "ra", list: [], display: "none" }, { gyo: "wa", list: [], display: "none" }
                            ];

                            let displayFlg = true;
                            towns.forEach(element => {
                                switch (true) {
                                    case /^[ぁ-お]/.test(element["town_kana"]):
                                        akasatana[0].list.push(element);
                                        if (displayFlg) {
                                            akasatana[0].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[0].disabled = false;
                                        break;
                                    case /^[か-ご]/.test(element["town_kana"]):
                                        akasatana[1].list.push(element);
                                        if (displayFlg) {
                                            akasatana[1].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[1].disabled = false;
                                        break;
                                    case /^[さ-ぞ]/.test(element["town_kana"]):
                                        akasatana[2].list.push(element);
                                        if (displayFlg) {
                                            akasatana[2].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[2].disabled = false;
                                        break;
                                    case /^[た-ど]/.test(element["town_kana"]):
                                        akasatana[3].list.push(element);
                                        if (displayFlg) {
                                            akasatana[3].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[3].disabled = false;
                                        break;
                                    case /^[な-の]/.test(element["town_kana"]):
                                        akasatana[4].list.push(element);
                                        if (displayFlg) {
                                            akasatana[4].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[4].disabled = false;
                                        break;
                                    case /^[は-ぽ]/.test(element["town_kana"]):
                                        akasatana[5].list.push(element);
                                        if (displayFlg) {
                                            akasatana[5].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[5].disabled = false;
                                        break;
                                    case /^[ま-も]/.test(element["town_kana"]):
                                        akasatana[6].list.push(element);
                                        if (displayFlg) {
                                            akasatana[6].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[6].disabled = false;
                                        break;
                                    case /^[ゃ-よ]/.test(element["town_kana"]):
                                        akasatana[7].list.push(element);
                                        if (displayFlg) {
                                            akasatana[7].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[7].disabled = false;
                                        break;
                                    case /^[ら-ろ]/.test(element["town_kana"]):
                                        akasatana[8].list.push(element);
                                        if (displayFlg) {
                                            akasatana[8].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[8].disabled = false;
                                        break;
                                    case /^[ゎ-ん]/.test(element["town_kana"]):
                                        akasatana[9].list.push(element);
                                        if (displayFlg) {
                                            akasatana[9].display = "block";
                                            displayFlg = false;
                                        }
                                        akasatanaFlg[9].disabled = false;
                                        break;
                                    default:
                                        break;
                                }
                            });
                            setFlgData2(akasatanaFlg);
                            setTowns(akasatana);
                        }
                    })
                    .catch(e => console.log(e))
            }

        }
        //ここまで
    }, [city]);
    const displayChange = (event) => {
        event.preventDefault();
        let clsName = event.target.className;
        let gyo = clsName.split('-');
        let spcfGyo = "list-" + gyo[2];
        let parent = document.querySelector('.shikucyouson');
        let els = parent.children;
        for (const el of Array.from(els)) {
            if (el.className.match(spcfGyo)) {
                el.style.display = "block";
            } else {
                el.style.display = "none";
            }
        }
    }
    const displayChange2 = (event) => {
        event.preventDefault();
        let clsName = event.target.className;
        console.log("clsName", clsName);
        let gyo = clsName.split('-');
        console.log("gyo", gyo);
        let spcfGyo = "list-" + gyo[2];
        console.log("spcfGyo", spcfGyo);
        let parent = document.querySelector('.cyoiki');
        let els = parent.children;
        console.log("els", els);
        for (const el of Array.from(els)) {
            if (el.className.match(spcfGyo)) {
                el.style.display = "block";
            } else {
                el.style.display = "none";
            }
        }
    }
    const inputChange = (event) => {
        switch (event.target.name) {
        }
    }
    const modalSubmit = (event) => {
        event.preventDefault();
        console.log(modalSubmit);
        switch (event.target.name) {
            case "areaButtons":
                console.log("areaButtons",event.target.value);
                setArea(event.target.value)
                if (datas.hasOwnProperty("area")) { datas["area"] = event.target.value } else {
                    setDatas({
                        ...datas,
                        "area": event.target.value
                    })
                }
                setAreaFlg(false);
                setTimeout(() => {
                    setTodofukenFlg(true);
                }, "500")
                break;
            case "todofukenButtons":
                console.log("todofukenButtons",event.target.value);
                setTodofuken(event.target.value)
                if (datas.hasOwnProperty("bukken_address1")) { datas["bukken_address1"] = event.target.value } else {
                    setDatas({
                        ...datas,
                        "bukken_address1": event.target.value
                    })
                }
                setTodofukenFlg(false);
                setTimeout(() => {
                    setCityFlg(true);
                }, "500")
                break;
            case "cityButtons":
                setCity(event.target.value);
                console.log("cityButtons",event.target.value);
                if (datas.hasOwnProperty("bukken_address2")) { datas["bukken_address2"] = event.target.value } else {
                    setDatas({
                        ...datas,
                        "bukken_address2": event.target.value
                    })
                }
                setCityFlg(false);
                setTimeout(() => {
                    setTownFlg(true);
                }, "500")
                break;
            case "townButtons":
                console.log("townButtons",event.target.value);
                setTown(event.target.value);
                if (datas.hasOwnProperty("bukken_address3")) {
                    datas["bukken_address3"] = event.target.value;
                    const data = datas;
                    props.step.value = data;
                } else {
                    const data = { ...datas, "bukken_address3": event.target.value };
                    setDatas(data);
                    props.step.value = data;
                }
                setTownFlg(false);
                if(firstTime){
                    triggerNext(event.target.value);
                    setFirstTime(false);
                }else{
                    props.steps.area = props.step.value;
                    console.log(props.steps);
                    console.log(event.target.value);
                }
                break;
            default:
                break;
        }

    }
    const modalOverlay = (e) => {
        const backgroundDiv = document.getElementsByClassName('overley');
        const bubble = document.getElementsByClassName('rsc-ts-bubble');
        const backWhite = document.getElementsByClassName('back-white-wrapper');
        const rscContent = document.getElementsByClassName('rsc-content');
        const operator = document.getElementsByClassName('rsc-ts-image');
        console.log("operator", operator);
        if (e === true) {
            backgroundDiv[0].style.display = "unset";
            rscContent[0].style.overflowY = "hidden";
            Object.keys(bubble).forEach(e => {
                bubble[e].style.opacity = "0.1";
            });
            Object.keys(operator).forEach(e => {
                operator[e].style.opacity = "0.1";
            });
            Object.keys(backWhite).forEach(e => {
                if (backWhite[e].className.indexOf('areaAndTodofuken') !== -1) {
                    backWhite[e].style.backgroundColor = "transparent";
                } else {
                    backWhite[e].style.opacity = "0.1";
                }
            });
        } else {
            backgroundDiv[0].style.display = "none";
            operator[0].style.opacity = "1";
            rscContent[0].style.overflowY = "scroll";
            Object.keys(bubble).forEach(e => {
                bubble[e].style.opacity = "1";
            });
            Object.keys(operator).forEach(e => {
                operator[e].style.opacity = "1";
            });
            Object.keys(backWhite).forEach(e => {
                if (backWhite[e].className.indexOf('areaAndTodofuken') !== -1) {
                    backWhite[e].style.backgroundColor = "#fff";
                } else {
                    backWhite[e].style.opacity = "1";
                }
            });
        }
    }
    useEffect(() => {
        modalOverlay(areaFlg);
    }, [areaFlg]);
    useEffect(() => {
        modalOverlay(todofukenFlg);
    }, [todofukenFlg]);
    useEffect(() => {
        modalOverlay(cityFlg);
    }, [cityFlg]);
    useEffect(() => {
        modalOverlay(townFlg);
    }, [townFlg]);

    return (<>
        {/* <form onSubmit={handleSubmit}> */}
        <form className="formCss1 areaAndTodofuken modalForm back-white-wrapper">
            {/* <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper areaSelect'> */}
            <label>都道府県：<input onFocus={popupModal} className='todofuken-button' type="text" name="todoFukenInput" value={todofuken} onChange={inputChange} /></label><br></br>
            <label>市区町村：<input onFocus={popupModal} type="text" name="cityInput" value={city} onChange={inputChange} /></label><br></br>
            <label>町域：<input onFocus={popupModal} type="text" name="townInput" value={town} onChange={inputChange} /></label><br></br>
            {areaFlg && <>
                <div class="modal-container">
                    <div class="back-orange-wrapper modal-window">
                        <div className="modal-header"><p>物件住所のエリアを選択して下さい</p></div>
                        <div className='modal-box'>
                            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
                                {areas?.map(e => {
                                    return <><li className='sc-htpNat GgOGn rsc-os-option'>
                                        <button onClick={modalSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element area-button' value={e.value} name='areaButtons'>{e.label}</button></li></>
                                })}
                            </ul></div>
                    </div>
                </div>
            </>
            }
            {todofukenFlg && <>
                <div class="modal-container">
                    <div class="back-orange-wrapper modal-window">
                        <div className="modal-header"><p>都道府県を選択して下さい</p></div>
                        <div className='modal-box'>
                            <ul className='sc-ifAKCX gkhNlr rsc-os-options areaSelect'>
                                {todofukens?.map(e => {
                                    return <><li className='sc-htpNat GgOGn rsc-os-option'>
                                        <button onClick={modalSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element area-button todofuken-button' value={e.value} name='todofukenButtons'>{e.label}</button></li></>
                                })}
                            </ul></div>
                    </div>
                </div>
            </>
            }
            {cityFlg && <>
                <Suspense fallback={<p>Loading..</p>}>
                    <div class="modal-container">
                        {/* <div class="back-orange-wrapper modal-window"> */}
                            <div className='list-header'>
                                <p>市区町村を選択して下さい</p>
                                <div className="akasatanaButton">
                                    {flgData?.map(f => { return <><button className={'city-button1 button-' + f.gyo} disabled={f.disabled} onClick={displayChange}>{f.kana}</button></> })}</div></div>
                            <div className="shikucyouson shikucyouson-f">{cities?.map(d => { return <><div className={"list-button list-" + d.gyo} style={{ display: d.display }}>{d.list?.map(c => { return <><button className={'city-button2'} key={c.city_kana} value={c.city} onClick={modalSubmit} name='cityButtons'>{c.city}</button></> })}</div></> })}</div>
                        {/* </div> */}
                    </div>
                </Suspense>
            </>}
            {townFlg && <>
                <Suspense fallback={<p>Loading..</p>}>
                    <div class="modal-container">
                        {/* <div class="back-orange-wrapper modal-window"> */}
                            <div className='list-header'>
                                <p>町域を選択して下さい</p>
                                <div className="akasatanaButton">
                                    {flgData2?.map(f => { return <><button className={'town-button1 button-' + f.gyo} disabled={f.disabled} onClick={displayChange2}>{f.kana}</button></> })}</div>
                            </div>
                            <div className="cyoiki cyoiki-f">{towns?.map(d => { return <><div className={"list-button list-" + d.gyo} style={{ display: d.display }}>{d.list?.map(c => { return <><button className={'town-button2'} key={c.town_kana} value={c.town} onClick={modalSubmit}name='townButtons'>{c.town}</button></> })}</div></> })}</div>
                        {/* </div> */}
                    </div>
                </Suspense>
            </>}
            <p className="color-grey syutten"><span>住所情報出典:「位置参照情報」(国土交通省)の加工情報・「HeartRails Geo API」(HeartRails Inc.)</span></p>

        </form>
    </>

    );

};