import React, { Suspense, useState, useEffect } from 'react';
import { useGenkyoContext } from '../context/contexts';

export default function Genkyo(props) {
    const [trigger, setTrigger] = useState(false);
    const type = [{ value: "住んでいます", label: "はい" }, { value: "住んでいません", label: "いいえ" }];
    const triggerNext = (event) => {
        props.step.value = event.target.value;
        setTrigger(true, props.triggerNextStep({ trigger: 'propertyInfo' }));
        if( event.target.value === "住んでいます"){
            setgenkyoState(1);
        }else if ( event.target.value === "住んでいません"){
            setgenkyoState(2);
        }
    }
    const [firstTime, setFirstTime] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const {genkyoState, setgenkyoState} = useGenkyoContext();
   useEffect(()=>{
        console.log("genkyoState_genkyo2",genkyoState);
    },[]);
    const handleSubmit = (event) => {
        event.preventDefault();
        if (disabled) {
            setDisabled(false);
            if (!firstTime) {
                let formElement = document.querySelector('.genkyoSelectParent');
                if(formElement)
                // Object.keys(formElement).forEach(e => {
                //     if (e === "style") 
                {
                        formElement.style.opacity = 1;;
                    }
                // })
            }
        } else {
            let ulElement = document.getElementsByClassName('genkyo-button');
            for (const el of Array.from(ulElement)) {
                if (el.value !== event.target.value) {
                    el.style.backgroundColor = "rgb(200, 193, 193)";
                }
                else if (el.value === event.target.value) {
                    el.style.backgroundColor = "rgb(255,255,255)";
                }
            }

            setTimeout(() => {
                let formElement2 = document.querySelector('.genkyoSelectParent');
                if(formElement2)
                // Object.keys(formElement2).forEach(e => {
                //     if (e === "style") 
                    {formElement2.style.opacity = 0.7;}
                // })
                setDisabled(true);
            }, "500")

            if (firstTime) {
                setFirstTime(false);
                triggerNext(event);
            } else {
                if (props.steps.genkyoSelect.value === event.target.value) {
                    //do nothing
                } else if(props.steps.genkyoSelect.value !== event.target.value){
                    const v = event.target.value;
                    props.steps.genkyoSelect.value =  v;
                    const propsObject = props.steps;
                    console.log("propsObject", propsObject);
                    Object.keys(propsObject).forEach(e => {
                        if (e === "bukkenQa2") {
                            console.log("e", propsObject["bukkenQa2"]);
                            if(v === "住んでいます"){
                                setgenkyoState(1);
                                if(props.steps.bukkenQa2.value.hasOwnProperty('genkyo')){
                                    delete props.steps.bukkenQa2.value.genkyo;
                                }
                                console.log("e_if", propsObject["bukkenQa2"]);
                            }else if (v === "住んでいません"){
                                setgenkyoState(2);
                                let formElement3 = document.querySelector('.bukkenQa2');
                                if(formElement3){
                                formElement3.scrollIntoView({  
                                    behavior: 'smooth'  
                                  });
                                }
                            }
                        }
                    });
                    
                    
                }
            }

        };
    }

    return (<>
        {/* <form onSubmit={handleSubmit}> */}
        <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper genkyoSelect genkyoSelectParent' >
            <ul className='sc-ifAKCX gkhNlr rsc-os-options genkyoSelect'>
                {type?.map(e => {
                    return <><li className='sc-htpNat GgOGn rsc-os-option'>
                        <button onClick={handleSubmit}/*type="submit"*/ className='sc-bxivhb ipSaXD rsc-os-option-element genkyo-button' value={e.value}>{e.label}</button></li></>
                })}
            </ul></div>
        {/* </form> */}
    </>

    );
};