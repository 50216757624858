import React, { Suspense, useState, useEffect } from 'react';

export default function CitySelect3(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [property, setProperty] = useState();
  const [flgData, setFlgData] = useState();
  const [data, setData] = useState();


  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'townSelect' }));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.value) { setErrorMsg(''); } else {
      setErrorMsg('※市区町村が未入力です');
      return false;
    };
    props.step.value = event.target.value;
    triggerNext();
  };

  useEffect(() => {
    if (props.steps.todofuken0.value) {
      setProperty(props.steps.todofuken0.value);
    }
    //テストデータ
    // if (true) {
    //   setProperty("東京都");
    // }
    //テストデータここまで
    console.log("property",property);
    const jsonData = require('../../components/assets/js/prefJson/47todofuken.json');
    var cityData = jsonData.response.prefecture.find((v) => v.city == property);
    if (cityData) {
      let townData = require('../../components/assets/js/prefJson/' + cityData.city_eng + '.json');
      if (townData) {
        let towns = townData.response.location;
        towns.sort(function (a, b) {
          if (a.city_kana > b.city_kana) {
            return 1;
          }
          if (a.city_kana < b.city_kana) {
            return -1;
          }
          return 0;
        });
        const akasatanaFlg = [
          { gyo: "a", kana: "あ", disabled: true }, { gyo: "ka", kana: "か", disabled: true }, { gyo: "sa", kana: "さ", disabled: true }, { gyo: "ta", kana: "た", disabled: true }, { gyo: "na", kana: "な", disabled: true }, { gyo: "ha", kana: "は", disabled: true }, { gyo: "ma", kana: "ま", disabled: true }, { gyo: "ya", kana: "や", disabled: true }, { gyo: "ra", kana: "ら", disabled: true }, { gyo: "wa", kana: "わ", disabled: true }
        ];
        const akasatana = [
          { gyo: "a", list: [], display: "none" }, { gyo: "ka", list: [], display: "none" }, { gyo: "sa", list: [], display: "none" }, { gyo: "ta", list: [], display: "none" }, { gyo: "na", list: [], display: "none" }, { gyo: "ha", list: [], display: "none" }, { gyo: "ma", list: [], display: "none" }, { gyo: "ya", list: [], display: "none" }, { gyo: "ra", list: [], display: "none" }, { gyo: "wa", list: [], display: "none" }
        ];

        let displayFlg = true;
        towns.forEach(element => {
          switch (true) {
            case /^[ぁ-お]/.test(element["city_kana"]):
              akasatana[0].list.push(element);
              if (displayFlg) {
                akasatana[0].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[0].disabled = false;
              break;
            case /^[か-ご]/.test(element["city_kana"]):
              akasatana[1].list.push(element);
              if (displayFlg) {
                akasatana[1].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[1].disabled = false;
              break;
            case /^[さ-ぞ]/.test(element["city_kana"]):
              akasatana[2].list.push(element);
              if (displayFlg) {
                akasatana[2].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[2].disabled = false;
              break;
            case /^[た-ど]/.test(element["city_kana"]):
              akasatana[3].list.push(element);
              if (displayFlg) {
                akasatana[3].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[3].disabled = false;
              break;
            case /^[な-の]/.test(element["city_kana"]):
              akasatana[4].list.push(element);
              if (displayFlg) {
                akasatana[4].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[4].disabled = false;
              break;
            case /^[は-ぽ]/.test(element["city_kana"]):
              akasatana[5].list.push(element);
              if (displayFlg) {
                akasatana[5].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[5].disabled = false;
              break;
            case /^[ま-も]/.test(element["city_kana"]):
              akasatana[6].list.push(element);
              if (displayFlg) {
                akasatana[6].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[6].disabled = false;
              break;
            case /^[ゃ-よ]/.test(element["city_kana"]):
              akasatana[7].list.push(element);
              if (displayFlg) {
                akasatana[7].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[7].disabled = false;
              break;
            case /^[ら-ろ]/.test(element["city_kana"]):
              akasatana[8].list.push(element);
              if (displayFlg) {
                akasatana[8].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[8].disabled = false;
              break;
            case /^[ゎ-ん]/.test(element["city_kana"]):
              akasatana[9].list.push(element);
              if (displayFlg) {
                akasatana[9].display = "block";
                displayFlg = false;
              }
              akasatanaFlg[9].disabled = false;
              break;
            default:
              break;
          }
        });
        setFlgData(akasatanaFlg);
        setData(akasatana);
      }
    }
  }, [property]);

  const displayChange = (event) => {
    event.preventDefault();
    let clsName = event.target.className;
    let gyo = clsName.split('-');
    let spcfGyo = "list-" + gyo[2];
    let parent = document.querySelector('.shikucyouson');
    let els = parent.children;
    for (const el of Array.from(els)) {
      if (el.className.match(spcfGyo)) {
        el.style.display = "block";
      } else {
        el.style.display = "none";
      }
    }
  }
  
  return (
    <>
      <form onSubmit={handleSubmit} className="formCss1 citySelect back-white-wrapper list-form" id="citySelect">
        <Suspense fallback={<p>Loading..</p>}>
          <div className='list-header'>
        <p>市区町村を選択して下さい</p>
          <div className="akasatanaButton">
            {flgData?.map(f => { return <><button className={'city-button1 button-' + f.gyo } disabled={f.disabled} onClick={displayChange}>{f.kana}</button></> })}</div></div>
          <div className="shikucyouson">{data?.map(d => { return <><div className={"list-button list-" + d.gyo} style={{ display: d.display }}>{d.list?.map(c => { return <><button className={'city-button2'} key={c.city_kana} value={c.city} onClick={handleSubmit}>{c.city}</button></> })}</div></> })}</div>
        </Suspense>
        <p className='errMsg'>{errorMessage}</p>
      </form>
    </>
  );
}