import React, { Suspense, useState, useEffect } from 'react';
import { useGenkyoContext } from '../context/contexts';

export default function Kento(props) {
    const [trigger, setTrigger] = useState(false);
    const type = [{ value: "はい", label: "はい" }, { value: "いいえ", label: "いいえ" }];
    const [enable, setEnable] = useState(true);
    const { kentoState, setKentoState } = useGenkyoContext();
    const triggerNext = (event) => {
        props.step.value = event.target.value;
        if (event.target.value === "はい") {
            console.log(props.steps);
            props.steps.bukkenQa2.value.reason = '価格によって売却を検討したいから';
            setKentoState(2);
            if (props.steps.hasOwnProperty('finish1') !== true ){
                setEnable(false);
                setTrigger(true, props.triggerNextStep({ trigger: 'finish1' }));
            }else{
                setEnable(false);
            }
        } else {
            setEnable(false);
            setTrigger(true, props.triggerNextStep({ trigger: 'saiKentoSelect' }));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        triggerNext(event);
    }

    return (<>
        {/* <form onSubmit={handleSubmit}> */}
        {enable &&
            <div className='sc-EHOje jvzENE rsc-os back-orange-wrapper genkyoSelect'>
                <div className="bukken-type-intro"><p className='color-white'>イエカカクは売却を検討する方向けのサービスです。<br></br>査定結果を知って、売却を検討する可能性はありますか？</p></div>
                <ul className='sc-ifAKCX gkhNlr rsc-os-options kentoSelect genkyoSelect'>
                    {type?.map(e => {
                        return <><li className='sc-htpNat GgOGn rsc-os-option'>
                            <button onClick={handleSubmit}/*type="submit"*/ className='kentoButton sc-bxivhb ipSaXD rsc-os-option-element' value={e.value}>{e.label}</button></li></>
                    })}
                </ul></div>
        }
        {/* </form> */}

    </>

    );
};