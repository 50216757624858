import React, { Suspense, useState, useEffect } from 'react';
import { useGenkyoContext } from '../context/contexts';

export default function EndChat(props) {
    // const [trigger, setTrigger] = useState(false);
    // const type = [{ value: "はい", label: "はい" }, { value: "いいえ", label: "いいえ" }];
    // const { kentoState, setKentoState } = useGenkyoContext();
    // const [enable, setEnable] = useState(true);
    // const triggerNext = (event) => {
    //     props.step.value = event.target.value;
    //     if (event.target.value == "はい") {
    //         props.steps.bukkenQa2.value.reason = '価格によって売却を検討したいから';
    //         setKentoState(2);
    //         if (props.steps.hasOwnProperty('finish1') !== true ) {
    //             setEnable(false);
    //             setTrigger(true, props.triggerNextStep({ trigger: 'finish1' }))
    //         }else{
    //             setEnable(false);
    //         }
    //     } else {
    //         setEnable(false);
    //         setTrigger(true, props.triggerNextStep({ trigger: 'suspendToend' }))
    //     }
    // }

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     triggerNext(event);
    // }

    return (<>
      
       
        <div className='sc-EHOje jvzENE rsc-os back-white-wrapper endChat'>
            <div className="bukken-type-intro"><p style={{color:'black',textAlign:'left'}}>ご利用ありがとうございました。具体的に売却を検討されるタイミングになりましたら、再度ご利用いただけますようお願い申し上げます。またのご利用をお待ちしております。
            </p></div>
       </div>
    </>

    );
};
