import React, {Suspense, useState, useEffect } from 'react';
import {telBlacklist} from './blacklist';
const checkLists = [
  "いつでも",
  "平日",
  "祝日",
  "月曜日",
  "火曜日",
  "水曜日",
  "木曜日",
  "金曜日",
  "土曜日",
  "日曜日",
];
const CheckBox = ({id, value, checked, onChange}) => {
  return (
    <input
      id={id}
      type="checkbox"
      name="inputNames"
      checked={checked}
      onChange={onChange}
      value={value}
    />
  )
}
const times = ['いつでも','10時〜12時','12時〜13時','13時〜15時','15時〜18時','18時〜21時'];
export default function Contact2(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [tel, setTel] = useState();
  const [telError, setTelError] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const [time, setTime] = useState(checkLists[0]);
  const [activityChanged, setActivityChanged] = useState(false); 
  const [mail, setMail] = useState();
  const { dataLayer } = window;
  const handleChange = (event) => {
    dataLayer.push({'event': event.target.name});
  switch(event.target.name){
    case 'tel':
    setTel(event.target.value);
      break;
    case 'time':
      setTime(event.target.value);
        break;
    default:
      break
  }
  };
  const triggerNext = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'finish3'}));
  }
  const telNumberCheck = (value) =>{
    if (value.match(/^0[5789]0\d{8}$/)){
      return true;
    }else if(value.match(/^0[1-9][1-9][0-9]\d{6}$/)){
      return true;
    }else{
      return false;
    }
  }
  const onBlurFunc = (e) => {
    if(e.target.name === 'tel'){
      if(telNumberCheck(e.target.value) === false){
        setTelError('※電話番号が不正です');
      }else if (telBlacklist.some((n) => e.target.value.includes(n))) {
        setTelError('※電話番号がご利用いただけません');
        return false;
      }else{
        setTelError('');
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = [];
    if(!mail){
      setErrorMsg('※メールアドレスが未入力です');
      return false; 
    }else if(!mail.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
      setErrorMsg('※メールアドレスが不正です');
      return false;
    }else{
      setErrorMsg(''); data = data.concat({name: 'mail', value: mail});
    };

    if(!tel){
      setErrorMsg('※電話番号が未入力です');
      return false; 
    }else if(telNumberCheck(tel)  === false){
      setErrorMsg('※電話番号が不正です');
      return false; 
    }else if (telBlacklist.some((n) => tel.includes(n))) {
      setErrorMsg('※電話番号がご利用いただけません');
      return false;
    }else{
        setErrorMsg(''); data = data.concat({name: 'tel', value: tel});
    };
    if(Object.keys(checkedItems).length !== 0){ 
      Object.entries(checkedItems).forEach(([key,value], index) => {data = data.concat({name: 'day-of-week'+key, 'value': value})});
    setErrorMsg('');}else{
      setErrorMsg('※連絡希望曜日を選択して下さい');
      return false;
    };
    if(time){setErrorMsg(''); data = data.concat({name: 'time', value: time}); }else{
      setErrorMsg('※連絡希望時間帯が未入力です');
      return false;
    };
    //テストデータ
    // data = data.concat({name: 'tel', value: '09099990000'});
    // data = data.concat({name: 'time', value: 'いつでも'});
    // data = data.concat({name: 'day-of-week0', value: 'いつでも'});
    
    //ここまで
    props.step.value = data;
    triggerNext();
  };
  const checkChange = e => {
    dataLayer.push({'event': "checkChange"});
        setCheckedItems({
          ...checkedItems,
          [e.target.id]: e.target.value
        })
  }
  useEffect(()=>{
    setMail(props.steps.contact1.value);
  },[props.steps.contact1]);

  return (
    <form onSubmit={handleSubmit} className="formCss2 contactInfo back-white-wrapper">
        <label>電話番号：<input placeholder="" type="tel" name="tel" value={tel} onChange={handleChange} onBlur={onBlurFunc}/></label>
        <br></br>
        <p>※ハイフン無しの半角数字で入力をお願いいたします</p>
        <p className='errMsg'>{telError}</p>
      <label>連絡希望曜日： <div className="form-check">
      {checkLists.map((item, index) => {
          index = index + 1;//この次のkeyでエラー出てる
          return (
            <>
            <label htmlFor={`id_${index}`} key={{index}}>
              <CheckBox
                id={index}
                value={item}
                onChange={checkChange}
                checked={checkedItems[item.id]}
              />
              {item}
            </label><br className={`checkBoxBr${index}`}></br></> )
        })}
          </div>
          </label><br></br>
      <label>連絡希望時間帯:
      <select value={time} onChange={handleChange} name="time">
          {times?.map( d =>{return <><option value={d}>{d}</option></>})}
        </select>
      </label><br></br>
      <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success" disabled ={(activityChanged)?true:false}>
            無料査定へ
            </button>
      </form>
    );
  }