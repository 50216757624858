import React, { useState, useEffect } from 'react';

const SetCities = (property) => {
  const [data, setData] = useState();
  // const URL = 'https://geoapi.heartrails.com/api/json?method=getCities&prefecture='+ property.property;
  // useEffect(() =>{
  //     fetch(URL)
  //     .then(response => response.json())
  //     .then(json => setData(json.response.location))
  //     .catch(e => console.log(e))
  //   }, [property]);
useEffect(() =>{
  const jsonData= require('../../components/assets/js/prefJson/47todofuken.json');
  var cityData = jsonData.response.prefecture.find((v) => v.city == property.property);
  if(cityData){
  let townData = require('../../components/assets/js/prefJson/'+ cityData.city_eng + '.json');
  if(townData){
    setData(townData.response.location);
  }
  }
},[property]);
   
       
      return(<>
      {data?.map( d => { return <><option key={d.city_kana} value={d.city} >{d.city}</option></>})}
      </>)
  }

  export default SetCities;