import React, { Suspense, useState, useEffect, useRef } from 'react';


export default function UserInfo3(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [ageVal, setAgeVal] = useState();
  const [buttonFlg, setButtonFlg] = useState(false);
  const [activityChanged, setActivityChanged] = useState(false);
  const age = ["20歳未満", "20代", "30代", "40代", "50代", "60代", "70歳以上"];
  const input8Ref = useRef(null);
  const [firstTime, setFirstTime] = useState(true);


  const { dataLayer } = window;
  const checkEmpty = (event, value) => {
    console.log("checkEmpty", event.target.name, value);
    if ((event.target.name === "ageVal" && !value) || (event.target.name !== "ageVal" && !ageVal))///
    {
      setButtonFlg(false)
    }
    else {
      //setButtonFlg(true); 
      //handleSubmit(event);
      console.log("event.currentTarget", event.currentTarget);
      input8Ref.current.blur();
      setErrorMsg('');
    }

  }

  const handleChange = (event) => {
    let v;
    console.log("handleChange", event.target.name);
    switch (event.target.name) {
      case 'ageVal':
        setAgeVal(event.target.value);
        v = event.target.value;
        break;

      default:
        break
    }
    checkEmpty(event, v);
  };

  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'contact1c' }));
    setFirstTime(false);
  }

  const onBlurCheck = (event) => {
    event.preventDefault();
    console.log("onBlurCheck", event);
    console.log("onBlurCheck_name", event.target.name);
    console.log("onBlurCheck_value", event.target.value);
    console.log({ 'event': event.relatedTarget});
    console.log({ 'current': input8Ref.current});
    console.log({ 'ageVal': ageVal});
    let data = [];
    if (input8Ref.current === event.relatedTarget) {
      //handleSubmit(event);
      setErrorMsg('');
      console.log("onBlurCheck_if", event);
      console.log("data", data);
      props.step.value = data;
      if (firstTime === true) {
        dataLayer.push({ 'event': event.target.name });
      triggerNext();
    }else{
      console.log("onBlurCheck-working", event.target.value);
      console.log("props.steps.userInfo2.value[0].value 1", props.steps.userInfo2.value[0].value);
      props.steps.userInfo2.value[0].value = event.target.value; 
      props.step.value[0].value = event.target.value;
    }
    }
    else {
      if (ageVal) {
        setErrorMsg(''); data = data.concat({ name: 'age', value: ageVal });
        //handleSubmit(event);
        console.log("onBlurCheck_else_if_if", event);
        console.log("data", data);
        props.step.value = data;
      if (firstTime === true) {
        triggerNext();
      }else{
        console.log("onBlurCheck-working", event.target.value);
        console.log("props.steps.userInfo2.value[0].value 2", props.steps.userInfo2.value[0].value);
        props.steps.userInfo2.value[0].value = event.target.value;
        props.step.value[0].value = event.target.value;
        console.log("props.steps.userInfo2.value[0].value 2_after", props.steps.userInfo2.value);
      }
      }
      else if (event.target.name == "ageVal" && event.target.value) {
        setErrorMsg(''); data = data.concat({ name: 'age', value: event.target.value });
        //handleSubmit(event);
        console.log("onBlurCheck_else_if_else_if", event.target.value);
        console.log("data", data);
        props.step.value = data;
      if (firstTime === true) {
        triggerNext();
      }else{
        console.log("onBlurCheck-working", event.target.value);
        console.log("props.steps.userInfo2.value[0].value 3", props.steps.userInfo2.value[0].value);
        props.steps.userInfo2.value[0].value = event.target.value; 
        props.step.value[0].value = event.target.value;
      }
      }
      else {
        setErrorMsg('※年代が未入力です');
        console.log("onBlurCheck_else_else", event);
        return false;
      };

    }
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   let data = [];
  //   if (input8Ref.current === event.relatedTarget) {
  //     setErrorMsg('');
  //   }
  //   else {

  //     if (ageVal) { setErrorMsg(''); data = data.concat({ name: 'age', value: ageVal }); } else {
  //       setErrorMsg('※年代が未入力です');
  //       return false;
  //     };


  //     console.log("data", data);
  //     props.step.value = data;
  //     triggerNext();
  //   }
  // };

  useEffect(() => {
  }, []);
  return (
    // <form className="formCss2 userInfo back-white-wrapper" onSubmit={handleSubmit}>
    <form className="formCss2 userInfo back-white-wrapper" >

      <label>年齢：
        <select className="year" value={ageVal} onChange={handleChange} name="ageVal" defaultValue='' onBlur={onBlurCheck} ref={input8Ref}>
          <option value='' disabled>年代を選んでください</option>
          {age?.map(d => { return <><option value={d}>{d}</option></> })}
        </select>
      </label><br></br>

      <p className='errMsg'>{errorMessage}</p>
      {buttonFlg && <button className="btn btn-success" formNoVlidate>
        次へ
      </button>}

    </form>
  );
}