import * as React from "react";
import { Helmet } from "react-helmet";
import './lp1/assets/css/style.css';



export const Partner = () => {
    return (
        <>
         <Helmet>
             <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
	{/* <!-- Google Tag Manager --> */}
	<script>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','GTM-TC8ZBW4')}</script>
	{/* <!-- End Google Tag Manager --> */}
    <link href="assets/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossorigin="anonymous" />
<link rel="stylesheet" href="assets/css/style.css?20220427-2"/>
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"/>
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js"></script>
<script type="text/javascript" src="assets/js/app.js"></script>
    <title>イエカカク/不動産会社様へ</title>
    
   <meta name="description" content="ページの説明文"/>
  <meta property="og:title" content="ページのタイトル"/>
  <meta property="og:description" content="ページの説明文"/>
  <meta property="og:image" content="https://mywebsite.com/image.jpg"/>
  <meta property="og:image:alt" content="画像の説明文"/>
  <meta property="og:locale" content="ja_JP"/>
  <meta property="og:type" content="website"/>
  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="og:url" content="https://mywebsite.com/page"/>
  <link rel="canonical" href="https://mywebsite.com/page"/>
  <link rel="shortcut icon" href="/assets/img/favicon.ico"/>   
        </Helmet>
        <body>
			{/* <!-- Google Tag Manager (noscript) --> */}
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TC8ZBW4"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  {/* <!-- End Google Tag Manager (noscript) --> */}
	  <header class="header">
	  	 <h1><img src="/assets/img/logo.png" alt="イエカカク"/></h1>
	  </header>
   <section class="mv mv_blue">
	   <div class="mv_inner">
		<picture>
		    <source srcset="assets/img/head_sp.png" media="(max-width: 768px)"/>
		    <img src="assets/img/head_pc.png" alt="集客で課題を抱えている不動産会社様、イエカカクなら質の高い反響をお届けします！" />
		</picture> 		   
	   </div>
   </section>
   
   {/* <section class="company-list">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">査定依頼のできる<br />不動産会社一覧</span></h2>
		   <div class="content">
			   <img src="assets/img/company_logo.jpg" alt="不動産会社一覧" />
		   </div>
	   </div>
   </section> */}
   
   <section class="body mv mv_white">
   <div class="body_inner mv_inner">
		<picture>
		    <source srcset="assets/img/body_sp.png" media="(max-width: 768px)"/>
		    <img src="assets/img/body_pc.png" alt="イエカカクの一括査定サービスとは?" />
		</picture> 		   
	   </div>
   </section>
   
   <section class="about blue">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">会社概要</span></h2>
		   <div class="content c-content">
			   <div class="c-block">
				   <div class="th">会社名</div>
				   <div class="td">株式会社 budi tech （ブディーテック）</div>
			   </div>
			   <div class="c-block">
				   <div class="th">設立日</div>
				   <div class="td">2017年4月27日</div>
			   </div>
			   <div class="c-block">
				   <div class="th">代表者</div>
				   <div class="td">代表取締役社長 山方 一也</div>
			   </div>
			   <div class="c-block">
				   <div class="th">所在地</div>
				   <div class="td">〒153-0042 目黒区青葉台3-6-16　HF青葉台レジデンス704</div>
			   </div>
			   <div class="c-block">
				   <div class="th">URL</div>
				   <div class="td">https://budi-tech.com/</div>
			   </div>			   			   			   			   			   			   
		   </div>
	   </div>
   </section>
   
   <footer class="footer">
	   <div class="container">
		   <div class="text">不動産の査定なら</div>
		   <div class="logo">
			   <a href="#"><img src="assets/img/logo.png" alt=""/></a>
		   </div>
		   <p><small>© 2022 budi tech</small></p>
	   </div>
   </footer>
   
   <div class="sticky blue">
	   <div class="c-content">
		   <a href="https://meeting.eeasy.jp/iekakaku/meet">無料相談予約</a>
	   </div>
   </div>

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js" integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW" crossorigin="anonymous"></script>

  </body> 
        </>
    );
};